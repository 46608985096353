define("lightway-connect/controllers/sites/site/data-kinds/data-kind/items/item/edit", ["exports", "lightway-connect/validations/data-item-form"], function (_exports, _dataItemForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteDataKindsDataKindItemsItemEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = (_temp = class SitesSiteDataKindsDataKindItemsItemEditController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dataItemFormValidations", _dataItemForm.default);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "formErrors", _descriptor2, this);

      _initializerDefineProperty(this, "showingSidebar", _descriptor3, this);
    }

    reset() {
      this.showingSidebar = false;
    }

    hideSidebar() {
      this.showingSidebar = false;
    }

    async save(changeset) {
      try {
        await changeset.validate();

        if (changeset.isValid) {
          try {
            // async forEach
            const start = async () => {
              let properties = Ember.A([]);
              changeset.get('properties').forEach(prop => {
                properties.addObject(prop);
              });
              await this.asyncForEach(properties, async prop => {
                await prop.save();
              });
            };

            start();
            await changeset.save();
            this.flashMessages.clearMessages().success('Item updated');
            this.transitionToRoute('sites.site.data-kinds.data-kind.items.item');
          } catch (reason) {
            this.formErrors.addErrors(reason, changeset);
            this.flashMessages.clearMessages().alert("There was an error");
          }
        } else {
          this.flashMessages.clearMessages().alert("There was an error");
        }
      } catch (reason) {//
      }
    }

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showingSidebar", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = SitesSiteDataKindsDataKindItemsItemEditController;
});