define("lightway-connect/controllers/sites/site/settings/mailing-lists/mailing-list/fields/new", ["exports", "lightway-connect/validations/new-data-field-form"], function (_exports, _newDataFieldForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, classic(_class = (_class2 = (_temp = class NewController extends Ember.Controller.extend(_newDataFieldForm.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "formErrors", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);
    }

    save(changeset) {
      changeset.validate().then(() => {
        if (changeset.get('isValid')) {
          this.set('model.dataField.parent', this.model.mailingList);
          changeset.save().then(() => {
            this.flashMessages.clearMessages().success('Field created');
            this.router.transitionTo('sites.site.settings.mailing-lists.mailing-list');
          }).catch(reason => {
            this.formErrors.addErrors(reason, changeset);
            this.model.mailingList.rollbackAttributes();
          });
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewController;
});