define("lightway-connect/services/editor-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditorServiceService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('model.constructor.modelName'), _dec4 = Ember.computed('model.constructor.modelName'), _dec5 = Ember.computed('model.constructor.modelName'), _dec6 = Ember.computed('model.constructor.modelName'), _dec7 = Ember.computed('model.constructor.modelName'), _dec8 = Ember.computed('model.constructor.modelName'), classic(_class = (_class2 = (_temp = class EditorServiceService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sortedListManager", _descriptor2, this);

      _defineProperty(this, "canvasVersion", null);

      _defineProperty(this, "selection", null);

      _defineProperty(this, "hoverSelection", null);

      _defineProperty(this, "hoverItem", null);

      _defineProperty(this, "viewport", null);

      _defineProperty(this, "editorUserKind", "guest");
    }

    init() {
      super.init(...arguments);
      this.initializeViewports();
      this.set('num', 0);
      this.set('modifications', Ember.A([]));
      this.set('itemsToSave', Ember.A([]));
      this.set('itemsToRemove', Ember.A([]));
    }

    initializeViewports() {
      // Start with fresh viewport models
      this.set('smallViewport', null);
      this.set('mediumViewport', null);
      this.set('lageViewport', null);
      this.store.peekAll('viewport').forEach(viewport => {
        this.store.unloadRecord(viewport);
      });
      this.createViewportModels();
    } // ! Mode


    get inPageMode() {
      if (!this.model) {
        return false;
      }

      return this.model.isA('page');
    }

    get inItemMode() {
      if (!this.model) {
        return false;
      }

      if (this.inEssentialContentItemMode) {
        return false;
      }

      return this.model.isA('data-item');
    }

    get inEssentialContentItemMode() {
      if (!this.model) {
        return false;
      }

      if (this.model.isA('data-item')) {
        return this.model.parent.get('preferences.essential-content');
      } else {
        return false;
      }
    }

    get inKindMode() {
      if (!this.model) {
        return false;
      }

      return this.model.isA('data-kind');
    }

    get inLayoutMode() {
      if (!this.model) {
        return false;
      }

      return this.model.isA('layout');
    }

    get inMailTemplateMode() {
      if (!this.model) {
        return false;
      }

      return this.model.isA('mail-template');
    }

    get inMailMessageMode() {
      if (!this.model) {
        return false;
      }

      return this.model.isA('mail-message');
    } // ! Canvas version


    selectVersion(version) {
      this.deselectVersion();

      if (version) {
        this.set('canvasVersion', version);
      }

      this.initializeViewports();
    }

    deselectVersion() {
      this.set('canvasVersion', null);
    } // ! Selection


    selectSelection(newSelection) {
      // Return if new selection is already currently selected
      if (this.selection == newSelection) {
        return;
      } // Prevent layout parts from being selected when in page mode


      if (this.inPageMode) {
        if (newSelection.baseParent && newSelection.baseParent.isA('layout-version')) {
          return;
        }
      } // Prevent mail template parts from being selected when in mail message mode


      if (this.inMailMessageMode) {
        if (newSelection.baseParent && newSelection.baseParent.isA('mail-template-version')) {
          return;
        }
      }

      this.set('selection', newSelection); // Set canvas version on selection
      // Used for sending canvasVersion to API when updating part so that
      // the returned data is relevant to the currently edited version only

      newSelection.set('canvasVersion', this.canvasVersion); // Set up hover item if needed

      this.setupHoverItem();
    }

    deselectSelection() {
      this.set('selection', this.canvasVersion);
      this.set('hoverSelection', null);
    } // ! Modifications
    // Returns an 'add' hash or if part, returns an 'add' array


    prepareAdd(item, itemsList, position) {
      if (item != null) {
        if (item.isA('part')) {
          let addArray = Ember.A([]);
          let addHash = {
            item: item,
            type: 'add'
          };
          addArray.addObject(addHash);

          if (!position) {
            if (item.rowOrder) {
              position = item.rowOrder - 1;
            } else {
              position = 0;
            }
          }

          if (itemsList) {
            let reorderedItems = this.sortedListManager.insertItemAt(position, itemsList, item);
            reorderedItems.forEach(itm => {
              let editHash = this.prepareEdit(itm.item, {
                rowOrder: itm.value
              });
              addArray.addObject(editHash);
            });
          } // Create temporary item-regions when creating prototype-based part.


          if (item.basePrototype) {
            item.basePrototype.regionParts.forEach(pt => {
              let itemRegion = this.store.createRecord('item-region', {
                item: item,
                region: pt
              });
              let addHash = {
                item: itemRegion,
                type: 'add'
              };
              addArray.addObject(addHash);
            });
          }

          return addArray;
        } else {
          return {
            item: item,
            type: 'add'
          };
        }
      } else {
        return null;
      }
    } // Returns an 'edit' hash


    prepareEdit(item, changes) {
      let values = {};
      let originalValues = {};
      Object.keys(changes).forEach(key => {
        let value = changes[key];
        let originalValue = Ember.get(item, key);

        if (originalValue != value) {
          if (originalValue === undefined) {
            originalValue = null;
          }

          if (Ember.isArray(originalValue)) {
            let zz = Ember.A([]);
            originalValue.forEach(mmm => {
              zz.addObject(mmm);
            });
            values[key] = value;
            originalValues[key] = zz;
          } else {
            values[key] = value;
            originalValues[key] = originalValue;
          }
        }
      }); // If originalValues is not empty

      if (Object.keys(originalValues).length != 0) {
        return {
          item: item,
          values: values,
          originalValues: originalValues
        };
      } else {
        return null;
      }
    } // Returns a 'remove' array


    prepareRemove(item) {
      let origParent;

      if (item.isA('prototype') || item.isA('style')) {
        origParent = item.get("layoutVersion.content"); // Remove temporarily from layout version

        item.set("layoutVersion", null);
      } else if (item.isA('page-link')) {
        origParent = item.get('part'); // Remove temporarily from part

        item.set("part", null);
      } else {
        origParent = item.get("parent"); // Remove temporarily from parent

        item.set("parent", null);
      }

      let removeArray = Ember.A([]);
      let removeHash = {
        item: item,
        type: 'remove',
        originalParent: origParent
      };
      removeArray.addObject(removeHash); // Deselect selection

      this.deselectSelection(); // Reorder siblings

      if (item.isA('part')) {
        let version = item.version; // Prevent crash by removing part from associations

        if (origParent) {
          origParent.parts.removeObject(item);
        }

        if (version) {
          version.versionParts.removeObject(item);
        } // Reorder existing siblings of part


        origParent.partsSortedBound.forEach((pt, i) => {
          // pt.set('rowOrder', i+1)
          let edit = this.prepareEdit(pt, {
            'rowOrder': i + 1
          });
          removeArray.addObject(edit);
        });
      }

      return removeArray;
    }

    addModifications(modifications) {
      let mods = Ember.A([]);
      modifications.forEach(modification => {
        if (modification != null) {
          let values = modification.values;

          if (values) {
            Object.keys(values).forEach(key => {
              let value = values[key];
              this.setPreference(modification.item, key, value);
            });
          }

          mods.addObject(modification);
        }
      });

      if (mods.length > 0) {
        this.modifications.addObject(mods);
      }
    }

    setPreference(model, property, value) {
      let newProp = "";
      property.split(".").forEach(key => {
        if (newProp == "") {
          newProp += key;
        } else {
          newProp += `.${key}`;
        }

        if (Ember.get(model, newProp) == null) {
          Ember.set(model, newProp, {});
        }
      }); // Update property

      Ember.set(model, property, value);
    } // ! Duplicate Items


    duplicateItem(item) {
      let changes = Ember.A([]);
      let duplication = this.duplicate(item, item.parent, item.rowOrder + 1);
      duplication.forEach(itm => {
        changes.addObject(itm);
      });
      this.addModifications(changes);
    }

    duplicate(item, newParent, rowOrder) {
      let changes = Ember.A([]);
      let newContentJson = Object.assign({}, item.contentJson);
      let newPreferences = Object.assign({}, item.preferences);
      let newItem = this.store.createRecord('part', {
        parent: newParent,
        kind: item.kind,
        contentJson: newContentJson,
        kontent: item.kontent,
        preferences: newPreferences,
        rowOrder: rowOrder,
        basePrototype: item.basePrototype
      }); // TODO: Duplicate page links, hover items etc.

      let addArray = this.prepareAdd(newItem);
      addArray.forEach(itm => {
        changes.addObject(itm);
      });
      item.partsSortedBound.forEach(pt => {
        let duplication = this.duplicate(pt, newItem, pt.rowOrder);
        duplication.forEach(dup => {
          changes.addObject(dup);
        });
      }); // For prototype based part

      if (item.basePrototype) {
        item.itemRegions.forEach(itemRegion => {
          // New item region has already been created at this point by prepareAdd()
          let newItemRegion;
          this.store.peekAll('item-region').forEach(ir => {
            if (ir.item === newItem && ir.region === itemRegion.region) {
              newItemRegion = ir;
            }
          }); // Duplicate all itemRegion's parts

          itemRegion.parts.forEach(pt => {
            let newPartItems = this.duplicate(pt, newItemRegion, pt.rowOrder);
            newPartItems.forEach(itm => {
              changes.addObject(itm);
            });
          });
        });
      }

      return changes;
    } // ! Undo


    undoLastChange() {
      let modification = this.modifications.lastObject; // Important: run through modification's mods in reverse order to prevent crashes

      modification.reverse().forEach(mod => {
        if (mod.type == "remove") {
          this.restoreRemovedItem(mod);
        } else if (mod.type == "add") {
          if (this.selection === mod.item) {
            this.deselectSelection();
          }

          this.store.unloadRecord(mod.item);
        } else {
          // Update item
          Object.keys(mod.originalValues).forEach(key => {
            let value = mod.originalValues[key];

            if (value === null) {
              // First set value as null to cause computed properties to recompute.
              // Just removing the key from the hash won't do this.
              mod.item.set(key, null); // Remove key altogether

              let splitKey = key.split(".");
              let propertyName = splitKey.lastObject;
              let baseKey = key.replace("." + propertyName, ""); //let obj = mod.item[baseKey]

              let obj = mod.item.get(baseKey);

              if (obj != null) {
                if (Array.isArray(obj)) {
                  // Remove from array
                  let objectIndex = obj.indexOf(propertyName);
                  obj.splice(objectIndex, 1);
                } else {
                  // Delete hash object
                  delete obj[propertyName];
                }
              }
            } else {
              this.setPreference(mod.item, key, value);
            }
          });
        }
      }); // Remove mod

      this.modifications.removeObject(modification);
    }

    restoreRemovedItem(mod) {
      if (mod.item.isA('prototype') || mod.item.isA('style')) {
        mod.item.set('layoutVersion', mod.originalParent);
      } else if (mod.item.isA('page-link')) {
        mod.item.set('part', mod.originalParent);
      } else {
        mod.item.set('parent', mod.originalParent);
      }
    } // ! Save


    saveChanges() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.itemsToSave = Ember.A([]);
        this.itemsToRemove = Ember.A([]);
        let modificationsToRemove = Ember.A([]);
        this.modifications.forEach(modification => {
          let modsToRemove = Ember.A([]);
          modification.forEach(mod => {
            if (mod.type == 'remove') {
              this.itemsToRemove.pushObject(mod.item);
            } else if (mod.item && mod.item.isA('data-property')) {
              // save data item with embedded properties instead of data property
              if (!this.itemsToSave.includes(mod.item.item)) {
                this.itemsToSave.pushObject(mod.item.item);
              } // Remove modification


              modsToRemove.addObject(mod);
            } else {
              if (!this.itemsToSave.includes(mod.item)) {
                this.itemsToSave.pushObject(mod.item);
              } // Remove modification


              modsToRemove.addObject(mod);
            } // Remove mods safely after iteration


            modsToRemove.forEach(() =>
            /*mod*/
            {// modification.removeObject(mod)
            });
          });

          if (modification.length === 0) {
            modificationsToRemove.addObject(modification);
          }
        }); // Remove modifications safely after iteration

        modificationsToRemove.forEach(modification => {
          this.modifications.removeObject(modification);
        });
        this.saveItem(this.canvasVersion).then(() => {
          this.destroyRemovedItems().then(() => {
            // Clear all modifications, then resolve
            this.set('modifications', Ember.A([]));
            return resolve();
          }).catch(reason => {
            return reject(reason);
          });
        }).catch(reason => {
          // TODO: select item with issue and present error
          return reject(reason);
        });
      });
    }

    saveItem(item, nestedStyles, nestedPrototypes, nestedParts, nestedTags) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let promises = [];

        if (!nestedStyles) {
          nestedStyles = [];
        }

        if (!nestedPrototypes) {
          nestedPrototypes = [];
        }

        if (!nestedParts) {
          nestedParts = [];
        }

        if (!nestedTags) {
          nestedTags = [];
        }

        let pbpItemRegionParts = []; // Before Save

        switch (item.get('constructor.modelName')) {
          case 'page-version':
            // Remove permalink if dynamic page
            if (item.dynamic) {
              item.set('permalink', null);
            }

            break;

          case 'layout-version':
            item.styles.forEach(style => {
              nestedStyles.push(style);
            });
            item.get('layout.prototypes').forEach(prototype => {
              nestedPrototypes.push(prototype);
            });
            item.parts.forEach(pt => {
              nestedParts.push(pt);
            });
            break;

          case 'mail-template-version':
            item.styles.forEach(style => {
              nestedStyles.push(style);
            });
            item.get('mailTemplate.prototypes').forEach(prototype => {
              nestedPrototypes.push(prototype);
            });
            item.parts.forEach(pt => {
              nestedParts.push(pt);
            });
            break;
          // case 'mail-message':
          //   item.styles.forEach((style) => {
          //     nestedStyles.push(style)
          //   });
          //   item.parts.forEach((pt) => {
          //     nestedParts.push(pt)
          //   });
          //   break;

          case 'data-item':
            item.parts.forEach(pt => {
              nestedParts.push(pt);
            });
            item.dataTags.forEach(dataTag => {
              nestedTags.addObject(dataTag); // Save new tags FIRST!

              if (!dataTag.id) {
                promises.push(this.saveItem(dataTag));
              }
            });
            this.itemsToSave.pushObject(item);
            break;

          case 'data-kind':
            item.parts.forEach(pt => {
              nestedParts.push(pt);
            }); // Don't save data kind itself as we are only using content-editor
            // to define default parts, not actually update the data kind.

            break;

          case 'data-tag':
            break;

          case 'prototype':
            item.parts.forEach(pt => {
              nestedParts.push(pt);
            });
            break;

          case 'part':
            if (!item.basePrototype) {
              item.parts.forEach(pt => {
                nestedParts.push(pt);
              });
            } // Keep track of parts that are nested within item regions to save them too


            if (item.basePrototype) {
              item.itemRegions.forEach(itemRegion => {
                itemRegion.parts.forEach(pt => {
                  pbpItemRegionParts.push(pt);
                });
              });
            }

            if (item.pageLink) {
              promises.push(this.saveItem(item.pageLink));
            }

            break;

          default: //

        }

        Ember.RSVP.all(promises) // Save new data tags (this must be done before data-item is saved)
        .then(() => {
          promises = [];
          this.saveRecord(item).then(savedItem => {
            // After save
            switch (savedItem.get('constructor.modelName')) {
              case 'layout-version':
                savedItem.styles.forEach(style => {
                  style.set('parent', savedItem);
                  promises.push(this.saveItem(style));
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  nestedPrototypes.forEach(prototype => {
                    // prototypes.layouts.addObject(savedItem.layout)
                    promises.push(this.saveItem(prototype));
                  });
                  Ember.RSVP.all(promises).then(() => {
                    promises = [];
                    nestedParts.forEach(part => {
                      part.set('parent', savedItem);
                      promises.push(this.saveItem(part));
                    });
                    resolve();
                  }).catch(reason => {
                    reject(reason);
                  });
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'mail-template-version':
                savedItem.styles.forEach(style => {
                  style.set('parent', savedItem);
                  promises.push(this.saveItem(style));
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  nestedPrototypes.forEach(prototype => {
                    // prototypes.mailTemplates.addObject(savedItem.mailTemplate)
                    promises.push(this.saveItem(prototype));
                  });
                  Ember.RSVP.all(promises).then(() => {
                    promises = [];
                    nestedParts.forEach(part => {
                      part.set('parent', savedItem);
                      promises.push(this.saveItem(part));
                    });
                    resolve();
                  }).catch(reason => {
                    reject(reason);
                  });
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'data-item':
                nestedParts.forEach(part => {
                  part.set('parent', savedItem);
                  promises.push(this.saveItem(part));
                });
                nestedTags.forEach(dataTag => {
                  // Reassociate with savedItem
                  savedItem.dataTags.addObject(dataTag);
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'data-kind':
                nestedParts.forEach(part => {
                  part.set('parent', savedItem);
                  promises.push(this.saveItem(part));
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'data-tag':
                promises = [];
                resolve();
                break;

              case 'page-version':
                savedItem.itemRegions.forEach(itemRegion => {
                  itemRegion.parts.forEach(p => {
                    promises.push(this.saveItem(p));
                  });
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'mail-message':
                savedItem.itemRegions.forEach(itemRegion => {
                  itemRegion.parts.forEach(p => {
                    promises.push(this.saveItem(p));
                  });
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'part':
                savedItem.itemRegions.forEach(itemRegion => {
                  if (itemRegion) {
                    // Replace newly created item-regions with those just returned from the server
                    // and transfer all descendants to that new item-region.
                    if (!itemRegion.id) {
                      let newItemRegion = this.findItemRegionFor({
                        item: savedItem,
                        region: itemRegion.region
                      });
                      itemRegion.parts.forEach(prt => {
                        prt.set('parent', newItemRegion);
                        promises.push(this.saveItem(prt));
                      }); // Remove temp item-region

                      this.store.unloadRecord(itemRegion);
                    } else {
                      // If item-region is not new
                      itemRegion.parts.forEach(part => {
                        promises.push(this.saveItem(part));
                      });
                    }
                  }
                });
                nestedParts.forEach(part => {
                  part.set('parent', savedItem);
                  promises.push(this.saveItem(part));
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = []; // TODO: Refactor this.
                  // This could be problematic?? (i.e. saving hoverItem before associated part/s are finished saving)

                  savedItem.hoverItems.forEach(hoverItem => {
                    // Used for sending version to API when updating hover-item so that
                    // the returned data is relevant to the currently edited version only
                    hoverItem.set('version', this.canvasVersion);
                    this.saveItem(hoverItem);
                  });
                  this.removeUnusedHoverItems(savedItem);
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'prototype':
                nestedParts.forEach(part => {
                  part.set('parent', savedItem);
                  promises.push(this.saveItem(part));
                });
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
                break;

              case 'hover-item':
                resolve();
                break;

              default:
                Ember.RSVP.all(promises).then(() => {
                  promises = [];
                  resolve();
                }).catch(reason => {
                  reject(reason);
                });
            }
          }).catch(reason => {
            reject(reason);
          });
        }).catch(reason => {
          reject(reason);
        });
      });
    }

    findItemRegionFor(options) {
      let iR;
      options.item.itemRegions.forEach(itemRegion => {
        if (itemRegion.id) {
          if (itemRegion.region === options.region) {
            iR = itemRegion;
          }
        }
      });
      return iR;
    }

    destroyRemovedItems() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        var promises = [];
        this.itemsToRemove.forEach(item => {
          promises.push(item.destroyRecord());
        });
        Ember.RSVP.all(promises).then(() => {
          resolve();
        }).catch(reason => {
          reject(reason);
        });
      });
    }

    removeUnusedHoverItems() {
      // Remove all unused hoverItems so that API does not throw
      // error on relationships that do not exist in the API
      if (this.selection && this.selection.hoverItems) {
        this.selection.hoverItems.forEach(hoverItem => {
          if (hoverItem) {
            if (!hoverItem.preferencesPresent) {
              hoverItem.unloadRecord();
            }
          }
        });
      }

      this.set('hoverItem', null);
    } // ! Hover Selection


    toggleHoverSelection(newSelection) {
      if (this.hoverSelection == newSelection) {
        // Remove selection
        this.set('hoverSelection', null); // Remove hover Item

        this.set('hoverItem', null);
      } else {
        if (newSelection) {
          // Select new selection
          this.set('hoverSelection', newSelection);
          this.setupHoverItem();
        } else {
          // Remove selection
          this.set('hoverSelection', null); // Remove hover Item

          this.set('hoverItem', null);
        }
      }
    }

    setupHoverItem() {
      let result = null;

      if (this.selection && this.selection.isA('part') && this.hoverSelection) {
        this.selection.hoverItems.forEach(hoverItem => {
          if (hoverItem.owner == this.hoverSelection) {
            result = hoverItem;
          }
        });

        if (!result) {
          this.selection.hoverItems.forEach(hoverItem => {
            if (hoverItem.owner == this.hoverSelection) {
              result = hoverItem;
            }
          }); // If no matching hover item exists, create it

          result = this.store.createRecord('hover-item', {
            item: this.selection,
            owner: this.hoverSelection,
            preferences: {
              small: {}
            }
          });
        }
      }

      this.set('hoverItem', result);
    } // ! Viewport


    selectViewport(size) {
      let viewport;
      this.store.peekAll('viewport').forEach(vp => {
        if (vp.size == size) {
          viewport = vp;
        }
      });

      if (this.viewport === viewport) {
        return;
      }

      this.set('viewport', viewport);
    }

    createViewportModels() {
      // Create viewport models
      this.set('smallViewport', this.store.createRecord('viewport', {
        label: "Small",
        size: "small"
      }));

      if (!this.inMailMessageMode && !this.inMailTemplateMode) {
        this.set('mediumViewport', this.store.createRecord('viewport', {
          label: "Medium",
          size: "medium",
          parent: this.smallViewport
        }));
      }

      this.set('largeViewport', this.store.createRecord('viewport', {
        label: "Large",
        size: "large",
        parent: this.mediumViewport
      }));
    }

    selectionApproved(selection) {
      if (!selection) {
        return false;
      }

      if (this.inPageMode) {
        // if (selection.isA('page-version')) { return true }
        if (selection.baseParent.isA('item-region')) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    saveRecord(record) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.itemsToSave.includes(record)) {
          this.saveAction(record).then(savedRecord => {
            return resolve(savedRecord);
          }).catch(reason => {
            return reject(reason);
          });
        } else {
          // If record doesn't need saving, it already exists and has not changed
          // so return it and resolve.
          return resolve(record);
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sortedListManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inPageMode", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inPageMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inItemMode", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "inItemMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inKindMode", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inKindMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inLayoutMode", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "inLayoutMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inMailTemplateMode", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "inMailTemplateMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inMailMessageMode", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "inMailMessageMode"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditorServiceService;
});