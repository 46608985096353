define("lightway-connect/components/form/input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input @type={{@type}}
    id={{@fieldId}}
    @value={{@value}}
    {{on "change" this.updateValue value="target.value"}}
    data-test-input-id={{@property}}
    ...attributes />
  
  */
  {
    id: "wGIxdp3D",
    block: "{\"symbols\":[\"@type\",\"@fieldId\",\"@value\",\"@property\",\"&attrs\"],\"statements\":[[5,\"input\",[[12,\"id\",[23,2,[]]],[12,\"data-test-input-id\",[23,4,[]]],[13,5],[3,\"on\",[\"change\",[23,0,[\"updateValue\"]]],[[\"value\"],[\"target.value\"]]]],[[\"@type\",\"@value\"],[[23,1,[]],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/input.hbs"
    }
  });

  let FormInputComponent = (_dec = Ember._action, (_class = class FormInputComponent extends _component.default {
    updateValue(value) {
      if (this.args.updateValue) {
        this.args.updateValue(value.target.value);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormInputComponent);
});