define("lightway-connect/routes/sites/site/user-kinds/user-kind/user/index", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteUserKindsUserKindUserIndexRoute = (_dec = Ember.inject.service, (_class = (_temp = class SitesSiteUserKindsUserKindUserIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentSite", _descriptor, this);
    }

    async model() {
      let userKind = await this.modelFor('sites.site.user-kinds.user-kind').userKind;
      let user = await this.modelFor('sites.site.user-kinds.user-kind.user');
      let productOffers = [];
      let userPlans = await this.store.query('user-plan', {
        filter: {
          site: this.currentSite.site.id,
          'user-kind': userKind.id
        }
      });
      await this.asyncForEach(userPlans.content, async plan => {
        let offers = await this.store.query('product-offer', {
          filter: {
            site: this.currentSite.site.id,
            product: plan.id
          }
        });
        offers.forEach(offer => {
          productOffers.addObject(offer);
        });
      });
      let userProductOffers = await this.store.query('user-product-offer', {
        filter: {
          site: this.currentSite.site.id,
          user: user.id
        }
      });
      let result = {
        user: user,
        userKind: userKind,
        productOffers: productOffers,
        userProductOffers: userProductOffers
      }; // Add data associates via dynamic association fields

      for (const field of userKind.allFields) {
        if (field.kind === "association-field") {
          let isReverseField = false;

          if (field.parent != userKind) {
            isReverseField = true;
          }

          let queryKey;

          if (isReverseField) {
            queryKey = (0, _lodash.camelCase)(field.secondaryIdentifier);
          } else {
            queryKey = (0, _lodash.camelCase)(field.identifier);
          } // Lookup matching data-associates


          var filter = {
            site: this.currentSite.site.id,
            field: field.id,
            size: 10
          };

          if (isReverseField) {
            filter["item"] = user.id;
          } else {
            filter["owner"] = user.id;
          }

          let assocResult = await this.store.query('data-associate', {
            filter: filter
          });
          result[queryKey] = assocResult;
        }
      }

      return result;
    }

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSite", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SitesSiteUserKindsUserKindUserIndexRoute;
});