define("lightway-connect/controllers/sites/site/content/pages/new", ["exports", "lightway-connect/validations/pages/page"], function (_exports, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    PageValidations: _page.default,
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    router: Ember.inject.service(),

    generatePermalink(changeset) {
      if (changeset.get('name')) {
        let permalink = changeset.get('name').replace("'", "").replace(/[_\W]+/g, "-").dasherize();
        changeset.set('permalink', permalink);
      }
    },

    generateRowOrder(changeset) {
      let rowOrder;

      if (changeset.get('parent')) {
        rowOrder = changeset.get('parent.pages.length') + 1;
      } else {
        let rootPages = Ember.A([]);
        this.store.peekAll('page').forEach(pg => {
          if (!pg.parent) {
            rootPages.pushObject(pg);
          }
        });
        rowOrder = rootPages.length;
      }

      changeset.set('rowOrder', rowOrder);
    },

    actions: {
      savePage(changesetObj) {
        this.generatePermalink(changesetObj);
        this.generateRowOrder(changesetObj);
        changesetObj.validate().then(() => {
          if (changesetObj.get("isValid")) {
            changesetObj.save().then(page => {
              this.flashMessages.clearMessages().success('Page created');
              this.router.transitionTo('sites.site.content.pages.page', page.id);
            }).catch(reason => {
              this.formErrors.addErrors(reason, changesetObj);
              this.flashMessages.clearMessages().alert("There was an error");
            });
          } else {
            this.flashMessages.clearMessages().alert("There was an error");
          }
        });
      }

    }
  });

  _exports.default = _default;
});