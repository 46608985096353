define("lightway-connect/mirage/identity-managers/page", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default {
    constructor() {
      this.ids = new Set();
    } // Returns a new unused unique identifier.


    fetch() {
      let uuid = (0, _emberUuid.v4)();

      while (this.ids.has(uuid)) {
        uuid = (0, _emberUuid.v4)();
      }

      this.ids.add(uuid);
      return uuid;
    } // Registers an identifier as used. Must throw if identifier is already used.


    set(id) {
      if (this.ids.has(id)) {
        throw new Error(`ID ${id} has already been used.`);
      }

      this.ids.add(id);
    } // Resets all used identifiers to unused.


    reset() {
      this.ids.clear();
    }

  }

  _exports.default = _default;
});