define("lightway-connect/components/editor/panel-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    id: "cszSKgmi",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/panel-header.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});