define("lightway-connect/routes/application", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = (_temp = class ApplicationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "currentAccount", _descriptor2, this);
    }

    beforeModel() {
      return this._loadCurrentAccount();
    }

    async _loadCurrentAccount() {
      try {
        await this.currentAccount.load();
      } catch (err) {
        await this.session.invalidate();
      }
    }

    model() {
      return this.store.peekAll('site');
    }

    willTransition(transition) {
      // transition.targetName returns the public / private route that was aimed to be visited last
      this.set('session.previousRouteName', transition.targetName);
    }

    error(error) {
      if (error instanceof _error.UnauthorizedError) {
        this.session.invalidate();
        this.currentAccount.unload();
        this.router.transitionTo('sign-in');
        return;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "willTransition", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "willTransition"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "error", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "error"), _class2.prototype)), _class2)) || _class);
  _exports.default = ApplicationRoute;
});