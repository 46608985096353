define("lightway-connect/mirage/factories/invitation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email: "email@example.org",
    withToken: (0, _emberCliMirage.trait)({
      token: 'invitationToken',
      siteName: "Site Name",
      roleName: "Role Name"
    })
  });

  _exports.default = _default;
});