define("lightway-connect/helpers/data-property-for-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dataPropertyForField(params
  /*, hash*/
  ) {
    let field = params[0];
    let item = params[1];
    let store = params[2];
    let property; // Find property match for field and item

    item.properties.forEach(prop => {
      if (prop.field === field) {
        property = prop;
      }
    }); // Create new property if no match

    if (!property) {
      property = store.createRecord('data-property', {
        item: item,
        field: field
      });
    }

    return property;
  });

  _exports.default = _default;
});