define("lightway-connect/components/resources-list", ["exports", "ember-drag-sort/components/drag-sort-list"], function (_exports, _dragSortList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dragSortList.default.extend({
    tagName: 'ol',
    classNames: 'grid-x resource-list',

    init() {
      this._super(...arguments);

      this.set('childTagName', 'li');
      this.set('childClass', 'cell small-12');
      this.set('itemSorting', ['rowOrder:asc']);
    },

    itemsSorted: Ember.computed.sort('items', 'itemSorting'),
    itemsSortedBound: Ember.computed('itemsSorted.@each', function () {
      const items = this.itemsSorted.slice();
      items.__parent__ = this;
      return items;
    }),

    saveItem(item) {
      item.save();
    },

    actions: {
      dragEnd({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach((item, i) => {
          item.set('rowOrder', i + 1);
          this.saveItem(item);
        });
      }

    }
  });

  _exports.default = _default;
});