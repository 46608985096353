define("lightway-connect/routes/sites/site/mailing-lists/mailing-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteMailingListsMailingListRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('mailing-list', params.mailing_list_id, {
        reload: true
      });
    }

  }

  _exports.default = SitesSiteMailingListsMailingListRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteMailingListsMailingListRoute, true);
});