define("lightway-connect/routes/accounts/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountsSettingsIndexRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.reset();
    }

  }

  _exports.default = AccountsSettingsIndexRoute;

  window.__CLASSIC_OWN_CLASSES__.set(AccountsSettingsIndexRoute, true);
});