define("lightway-connect/controllers/sites/site/content/pages/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    rootPages: Ember.computed('model.@each', function () {
      let pages = Ember.A([]);
      this.model.forEach(p => {
        if (!p.parent) {
          pages.pushObject(p);
        }
      });
      return pages;
    }),
    actions: {
      savePage(page) {
        this.target.send('savePage', page);
      },

      deletePage(page) {
        let pageParent = page.parent;
        page.destroyRecord().then(() => {
          let siblingsList;

          if (pageParent) {
            siblingsList = pageParent.pages;
          } else {
            siblingsList = this.rootPages;
          } // Decrease position of remaining pages only if others remain


          if (siblingsList) {
            siblingsList.forEach(page => {
              page.set('rowOrder', page.rowOrder - 1);

              if (page.hasDirtyAttributes) {
                page.save();
              }
            });
          }

          this.flashMessages.clearMessages().success('Page deleted');
        });
      }

    }
  });

  _exports.default = _default;
});