define("lightway-connect/classes/editor-js/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Collection {
    static get toolbox() {
      return {
        title: 'Data Collection',
        icon: ''
      };
    }

    constructor({
      data,
      api,
      config
    }) {
      this.data = data;
      this.api = api;
      this.config = config || {};
      this.wrapper = undefined;
      this.button = document.createElement('div');
      this.selectAction = this.config.selectAction;
    }

    render() {
      this.wrapper = document.createElement('div');
      this.wrapper.innerHTML = `
      <div class="grid-x editor-block-collection-grid">
        <div role="button" class="cell small-12 editor-block-collection editor-block-collection-cell">
          Data Collection
        </div>
      </div>
    `;
      this.setupListener();
      return this.wrapper;
    }

    isFirstBlock() {
      return this.api.blocks.getCurrentBlockIndex() === 0;
    }

    setupListener() {
      this.api.listeners.on(this.wrapper, 'click', () => {
        let blockIndex = this.api.blocks.getCurrentBlockIndex();
        this.selectAction(blockIndex);
      }, false);
    }

    save() {
      if (this.data) {
        return this.data;
      }

      return {};
    }

  }

  _exports.default = Collection;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(Collection, true);

  window.__CLASSIC_OWN_CLASSES__.set(Collection, true);
});