define("lightway-connect/components/accounts/change-email-form", ["exports", "fetch", "lightway-connect/validations/accounts/change-email-form"], function (_exports, _fetch, _changeEmailForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_changeEmailForm.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formIsHidden: false,

    hideForm() {
      this.set('formIsHidden', true);
    },

    actions: {
      changeEmail(changesetObj) {
        changesetObj.validate().then(() => {
          if (changesetObj.get('isValid')) {
            let {
              token
            } = this.get('session.data.authenticated');

            if (this.get('session.isAuthenticated') && token) {
              let authorizationToken = `Bearer ${token}`;
              return this.requestEmailChange(authorizationToken, changesetObj);
            } else {
              this.flashMessages.clearMessages().alert('There was an error');
              return;
            }
          } else {
            this.flashMessages.clearMessages().alert('There was an error');
            return;
          }
        });
      }

    },

    requestEmailChange(authorizationToken, changesetObj) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)('/api/backend/accounts', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: authorizationToken
          },
          body: JSON.stringify({
            "data": {
              "type": "accounts",
              "attributes": {
                "new-email": changesetObj.get('new-email')
              }
            }
          })
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.flashMessages.clearMessages().success('Change of email has been requested');
              this.hideForm();
              return resolve();
            });
          } else {
            response.json().then(responseJson => {
              Ember.run(() => {
                this.flashMessages.clearMessages().alert('There was an error');
                this.addErrorsToChangeset(changesetObj, responseJson);
                return reject();
              });
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            return reject();
          });
        });
      });
    },

    addErrorsToChangeset(changesetObj, reason) {
      reason.errors.forEach(({
        source,
        detail
      }) => {
        let paths = source.pointer.split('/');
        let attr = paths[paths.length - 1].split('-').join(' ');

        if (attr != "base") {
          return changesetObj.pushErrors('newEmail', `Email ${detail}`);
        } else {
          changesetObj.pushErrors('newEmail', `${detail}`);
        }
      });
    }

  });

  _exports.default = _default;
});