define("lightway-connect/routes/sites/site/data-kinds/data-kind/categories/category/categories/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let CategoryRoute = classic(_class = class CategoryRoute extends Ember.Route {
    model(params) {
      return Ember.RSVP.hash({
        dataCategory: this.store.findRecord('data-category', params.sub_category_id, {
          reload: true
        })
      });
    }

  }) || _class;

  _exports.default = CategoryRoute;
});