define("lightway-connect/models/data-kind", ["exports", "@ember-data/model", "lightway-connect/mixins/data-category-parentable", "lightway-connect/mixins/part-parentable", "lightway-connect/mixins/data-field-parentable", "lightway-connect/mixins/data-item-parentable", "lightway-connect/mixins/translation-parentable", "lightway-connect/mixins/page-link-resource", "lightway-connect/mixins/part-kontentable", "lightway-connect/mixins/notification-rule-resource"], function (_exports, _model, _dataCategoryParentable, _partParentable, _dataFieldParentable, _dataItemParentable, _translationParentable, _pageLinkResource, _partKontentable, _notificationRuleResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DataKind = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec6 = (0, _model.belongsTo)(), _dec7 = (0, _model.belongsTo)('data-field', {
    inverse: 'dataKindsDefaulted',
    async: false
  }), _dec8 = (0, _model.belongsTo)('data-field', {
    inverse: 'ownedDataKinds',
    async: false
  }), _dec9 = (0, _model.hasMany)({
    async: false
  }), _dec10 = (0, _model.belongsTo)('style', {
    async: false
  }), _dec11 = Ember.computed, _dec12 = Ember.computed, _dec13 = Ember.computed('fields', 'reverseAssociationFields'), _dec14 = Ember.computed('prototypes'), classic(_class = (_class2 = (_temp = class DataKind extends _model.default.extend(_dataCategoryParentable.default, _dataFieldParentable.default, _dataItemParentable.default, _translationParentable.default, _partKontentable.default, _pageLinkResource.default, _partParentable.default, _notificationRuleResource.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "identifier", _descriptor2, this);

      _initializerDefineProperty(this, "singularIdentifier", _descriptor3, this);

      _initializerDefineProperty(this, "enableCategories", _descriptor4, this);

      _initializerDefineProperty(this, "preferences", _descriptor5, this);

      _initializerDefineProperty(this, "site", _descriptor6, this);

      _initializerDefineProperty(this, "defaultField", _descriptor7, this);

      _initializerDefineProperty(this, "ownerField", _descriptor8, this);

      _initializerDefineProperty(this, "prototypes", _descriptor9, this);

      _initializerDefineProperty(this, "globalStyle", _descriptor10, this);
    }

    get parent() {
      return this;
    }

    get currentVersion() {
      return this;
    }

    get allFields() {
      var fieldsArray = Ember.A([]);
      this.fields.forEach(object => {
        fieldsArray.pushObject(object);
      });
      this.reverseAssociationFields.forEach(object => {
        fieldsArray.pushObject(object);
      });
      return fieldsArray;
    }

    get availablePrototypeKinds() {
      let prototypes = Ember.A([]);
      let addSection = false;
      let addCta = false;
      let addBadge = false;
      let addContentClip = false;
      let addDivider = false;
      let addDataProfile = false;
      let addDataCollection = false;
      let addImageGallery = false;
      this.prototypes.forEach(prototype => {
        switch (prototype.kind) {
          case 'section':
            addSection = true;
            break;

          case 'cta':
            addCta = true;
            break;

          case 'badge':
            addBadge = true;
            break;

          case 'content-clip':
            addContentClip = true;
            break;

          case 'divider':
            addDivider = true;
            break;

          case 'data-profile':
            addDataProfile = true;
            break;

          case 'data-collection':
            addDataCollection = true;
            break;

          case 'image-gallery':
            addImageGallery = true;
            break;
        }
      });

      if (addSection) {
        prototypes.pushObject("section");
      }

      if (addCta) {
        prototypes.pushObject("cta");
      }

      if (addBadge) {
        prototypes.pushObject("badge");
      }

      if (addContentClip) {
        prototypes.pushObject("content-clip");
      }

      if (addDivider) {
        prototypes.pushObject("divider");
      }

      if (addDataProfile) {
        prototypes.pushObject("data-profile");
      }

      if (addDataCollection) {
        prototypes.pushObject("data-collection");
      }

      if (addImageGallery) {
        prototypes.pushObject("image-gallery");
      }

      return prototypes;
    }

    get singularName() {
      return this.name;
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get defaultPropertyIsDateTime() {
      let reference = this.belongsTo('defaultField');

      if (reference.value() === null) {
        // Reference not yet loaded from API
        return false;
      } else {
        // Reference already loaded from API
        return this.defaultField.kind === 'date-field';
      }
    }

    get rowOrderSortable() {
      if (this.preferences['row-order-sortable']) {
        return true;
      }

      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "singularIdentifier", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "enableCategories", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "site", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "defaultField", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "ownerField", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "prototypes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "globalStyle", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "parent", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "parent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentVersion", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "currentVersion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "allFields", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "allFields"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "availablePrototypeKinds", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "availablePrototypeKinds"), _class2.prototype)), _class2)) || _class);
  _exports.default = DataKind;
});