define("lightway-connect/routes/sites/site/data-kinds/data-kind/items/item/index", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, classic(_class = (_class2 = (_temp = class IndexRoute extends Ember.Route {
    constructor() {
      super(...arguments); // Prevent error with editor component when
      // transitioning from this route

      _initializerDefineProperty(this, "permissions", _descriptor, this);

      _initializerDefineProperty(this, "currentSite", _descriptor2, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      this.router.on('routeWillChange', () => {
        let controller = this.controllerFor('sites/site/data-kinds/data-kind/items/item/index');

        if (controller) {
          controller.showEditor = false;
        }
      });
    }

    beforeModel() {
      let approvedPermissions = ['update_data_items'];
      let permitted = this.permissions.accountPermissionsInclude(approvedPermissions) == true;

      if (!permitted) {
        this.flashMessages.clearMessages().alert("Access denied");
        this.transitionTo('sites.site');
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.reset();
    }

    async model() {
      let dataKind = await this.modelFor('sites.site.data-kinds.data-kind').dataKind;
      let item = await this.modelFor('sites.site.data-kinds.data-kind.items.item');
      let result = {
        item: item,
        dataKind: dataKind
      }; // Add data associates via dynamic association fields
      // dataKind.fields.forEach(async (field) => {

      for (const field of dataKind.allFields) {
        if (field.kind === "association-field") {
          let isReverseField = false;

          if (field.parent != dataKind) {
            isReverseField = true;
          }

          let queryKey;

          if (isReverseField) {
            queryKey = (0, _lodash.camelCase)(field.secondaryIdentifier);
          } else {
            queryKey = (0, _lodash.camelCase)(field.identifier);
          } // Lookup matching data-associates


          var filter = {
            site: this.currentSite.site.id,
            field: field.id,
            size: 10
          };

          if (isReverseField) {
            filter["item"] = item.id;
          } else {
            filter["owner"] = item.id;
          }

          let assocResult = await this.store.query('data-associate', {
            filter: filter
          });
          result[queryKey] = assocResult;
        }
      }

      return result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "permissions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentSite", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = IndexRoute;
});