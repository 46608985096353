define("lightway-connect/mirage/factories/part", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    preferences: {},
    headerSection: (0, _emberCliMirage.trait)({
      kind: 'section',
      sectionName: 'header'
    }),
    mainSection: (0, _emberCliMirage.trait)({
      kind: 'section',
      sectionName: 'main'
    }),
    footerSection: (0, _emberCliMirage.trait)({
      kind: 'section',
      sectionName: 'footer'
    }),
    isContainer: (0, _emberCliMirage.trait)({
      kind: 'container'
    }),
    isImage: (0, _emberCliMirage.trait)({
      kind: 'image'
    }),
    isVideo: (0, _emberCliMirage.trait)({
      kind: 'video'
    }),
    withParagraph: (0, _emberCliMirage.trait)({
      kind: 'text',
      textContent: 'Long paragraph text.'
    })
  });

  _exports.default = _default;
});