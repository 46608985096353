define("lightway-connect/components/sortable-list-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    sortedListManager: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('itemSorting', ['rowOrder:asc']);
    },

    itemsSorted: Ember.computed.sort('items', 'itemSorting'),
    itemsSortedBound: Ember.computed('itemsSorted.@each', function () {
      const items = this.itemsSorted.slice();
      items.__parent__ = this;
      return items;
    }),
    actions: {
      dragEnd({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        this.sortedListManager.reorderItems(sourceList, sourceIndex, targetList, targetIndex, 'items');
        sourceList.forEach(item => {
          if (item.hasDirtyAttributes) {
            this.saveItemAction(item);
          }
        });
      }

    }
  });

  _exports.default = _default;
});