define("lightway-connect/models/item-region", ["exports", "@ember-data/model", "lightway-connect/mixins/part-parentable"], function (_exports, _model, _partParentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ItemRegion = (_dec = (0, _model.belongsTo)('part-parentable', {
    inverse: 'itemRegions',
    polymorphic: true,
    async: false
  }), _dec2 = (0, _model.belongsTo)('part', {
    inverse: 'itemRegionsAsRegion',
    async: false
  }), classic(_class = (_class2 = (_temp = class ItemRegion extends _model.default.extend(_partParentable.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "item", _descriptor, this);

      _initializerDefineProperty(this, "region", _descriptor2, this);
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get colorClasses() {
      return this.item.colorClasses;
    }

    get currentDynamicContainer() {
      return this.item.currentDynamicContainer;
    }

    get subjectTypeObject() {
      return this.item.subjectTypeObject;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "item", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "region", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ItemRegion;
});