define("lightway-connect/mirage/factories/data-field", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    required: false,
    textField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Text Field ${i}`;
      },

      identifier(i) {
        return `text-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'text-field'
    }),
    emailField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Email Field ${i}`;
      },

      identifier(i) {
        return `email-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'email-field'
    }),
    phoneField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Phone Field ${i}`;
      },

      identifier(i) {
        return `phone-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'phone-field'
    }),
    extendedTextField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Extended Text Field ${i}`;
      },

      identifier(i) {
        return `extended-text-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'extended-text-field'
    }),
    imageField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Image Field ${i}`;
      },

      identifier(i) {
        return `image-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'image-field'
    }),
    documentField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Document Field ${i}`;
      },

      identifier(i) {
        return `document-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'document-field'
    }),
    imageCollectionField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Image Collection Field ${i}`;
      },

      identifier(i) {
        return `image-collection-field-${i}`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'image-collection-field'
    }),
    associationField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Association Field ${i}`;
      },

      reverseLabel(i) {
        return `Reverse Association Field ${i}`;
      },

      identifier(i) {
        return `association-field-${i}`;
      },

      secondaryIdentifier(i) {
        return `association-field-${i}-secondary-identifier`;
      },

      rowOrder(i) {
        return i;
      },

      kind: 'association-field',
      limit: 0,
      reverseLimit: 1
    })
  });

  _exports.default = _default;
});