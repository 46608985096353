define("lightway-connect/templates/components/ui-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SfF2NbIS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"permittedToBeShown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"unless\",[[23,0,[\"showOnlyCurrent\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectOptionAction\"]],[23,0,[\"value\"]]],null]],null]],[11,\"class\",[29,[\"cell small-12 option \",[28,\"if\",[[23,0,[\"showingAll\"]],[28,\"if\",[[23,0,[\"isCurrentValue\"]],\"current-option\"],null]],null]]]],[11,\"data-test-id\",[23,0,[\"value\"]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-select-option.hbs"
    }
  });

  _exports.default = _default;
});