define("lightway-connect/routes/sites/site/web-forms/web-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let WebFormRoute = classic(_class = class WebFormRoute extends Ember.Route {
    model(params) {
      let webForm = this.store.peekRecord('web-form', params.web_form_id);
      return Ember.RSVP.hash({
        webForm: webForm
      });
    }

  }) || _class;

  _exports.default = WebFormRoute;
});