define("lightway-connect/models/user", ["exports", "@ember-data/model", "lightway-connect/mixins/data-property-item", "lightway-connect/mixins/data-item-author"], function (_exports, _model, _dataPropertyItem, _dataItemAuthor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let User = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)(), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.belongsTo)(), _dec8 = (0, _model.belongsTo)({
    async: false
  }), _dec9 = (0, _model.hasMany)(), _dec10 = (0, _model.hasMany)('user-plan-subscription', {
    async: true,
    inverse: 'user'
  }), _dec11 = (0, _model.belongsTo)('user-plan-subscription', {
    async: true,
    inverse: 'userCurrented'
  }), _dec12 = (0, _model.belongsTo)('subscription-trial', {
    async: true,
    inverse: 'user'
  }), _dec13 = (0, _model.hasMany)('user-product-offer', {
    async: true,
    inverse: 'user'
  }), _dec14 = (0, _model.hasMany)('invoice', {
    async: true,
    inverse: 'recipient'
  }), _dec15 = (0, _model.hasMany)('logged-mail-message', {
    async: true,
    inverse: 'user'
  }), _dec16 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec17 = Ember.computed('status'), classic(_class = (_class2 = (_temp = class User extends _model.default.extend(_dataPropertyItem.default, _dataItemAuthor.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "email", _descriptor, this);

      _initializerDefineProperty(this, "unconfirmedEmail", _descriptor2, this);

      _initializerDefineProperty(this, "confirmed", _descriptor3, this);

      _initializerDefineProperty(this, "status", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "confirmedAt", _descriptor6, this);

      _initializerDefineProperty(this, "site", _descriptor7, this);

      _initializerDefineProperty(this, "userKind", _descriptor8, this);

      _initializerDefineProperty(this, "subscriptions", _descriptor9, this);

      _initializerDefineProperty(this, "userPlanSubscriptions", _descriptor10, this);

      _initializerDefineProperty(this, "currentSubscription", _descriptor11, this);

      _initializerDefineProperty(this, "subscriptionTrial", _descriptor12, this);

      _initializerDefineProperty(this, "userProductOffers", _descriptor13, this);

      _initializerDefineProperty(this, "invoices", _descriptor14, this);

      _initializerDefineProperty(this, "loggedMailMessages", _descriptor15, this);

      _initializerDefineProperty(this, "customProperties", _descriptor16, this);
    }

    get name() {
      return this.email;
    }

    get parent() {
      return this.userKind;
    }

    get isADataItem() {
      return false;
    }

    get displayName() {
      return this.email;
    }

    get displayStatus() {
      switch (this.status) {
        case 'active':
          return "Active";

        case 'unapproved':
          return "Pending Review";

        default:
          return "";
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "email", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "unconfirmedEmail", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "confirmed", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "confirmedAt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "site", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "userKind", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "subscriptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "userPlanSubscriptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "currentSubscription", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "subscriptionTrial", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "userProductOffers", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "invoices", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "loggedMailMessages", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "customProperties", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "displayStatus", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "displayStatus"), _class2.prototype)), _class2)) || _class);
  _exports.default = User;
});