define("lightway-connect/components/form/switch", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="switch {{if @checked 'switch-checked' 'switch-unchecked'}}" data-test-switch-id={{@property}} ...attributes>
    <input
      id={{@fieldId}}
      type="checkbox"
      checked={{@checked}}
      class="switch-input"
      {{on "change" this.updateValue value="target.checked"}}>
  
    <label class="switch-paddle" for={{@fieldId}}>
      <span class="show-for-sr">
        {{@label}}
      </span>
      <span class="switch-active" aria-hidden="true">
        On
      </span>
      <span class="switch-inactive" aria-hidden="true">
        Off
      </span>
    </label>
  </div>
  */
  {
    id: "dKBdSbmb",
    block: "{\"symbols\":[\"@checked\",\"@property\",\"&attrs\",\"@fieldId\",\"@label\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"switch \",[28,\"if\",[[23,1,[]],\"switch-checked\",\"switch-unchecked\"],null]]]],[12,\"data-test-switch-id\",[23,2,[]]],[13,3],[8],[0,\"\\n  \"],[7,\"input\",false],[12,\"id\",[23,4,[]]],[12,\"checked\",[23,1,[]]],[12,\"class\",\"switch-input\"],[12,\"type\",\"checkbox\"],[3,\"on\",[\"change\",[23,0,[\"updateValue\"]]],[[\"value\"],[\"target.checked\"]]],[8],[9],[0,\"\\n\\n  \"],[7,\"label\",true],[10,\"class\",\"switch-paddle\"],[11,\"for\",[23,4,[]]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"show-for-sr\"],[8],[0,\"\\n      \"],[1,[23,5,[]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"switch-active\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n      On\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"switch-inactive\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n      Off\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/switch.hbs"
    }
  });

  let FormSwitchComponent = (_dec = Ember._action, (_class = class FormSwitchComponent extends _component.default {
    updateValue() {
      if (this.args.updateValue) {
        this.args.updateValue(!this.args.checked);
      } else {
        this.args.model[this.args.property] = !this.args.checked;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormSwitchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormSwitchComponent);
});