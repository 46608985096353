define("lightway-connect/components/content-editor/range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),

    updateProperty(value) {
      let changes = {};
      changes[this.propertyPath] = value;
      let edit = this.editorService.prepareEdit(this.model, changes);
      this.editorService.addModifications(Ember.A([edit]));
    },

    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    })
  });

  _exports.default = _default;
});