define("lightway-connect/mixins/data-field-parentable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fields: (0, _model.hasMany)('data-field', {
      inverse: 'parent',
      async: true
    }),
    reverseAssociationFields: (0, _model.hasMany)('data-field', {
      inverse: 'subjectKind',
      async: true
    }),
    fieldsSortOrder: ['rowOrder:asc'],
    fieldsSorted: Ember.computed.sort('fields', 'fieldsSortOrder'),
    sortedFields: Ember.computed('fieldsSorted.@each', function () {
      // Make a shallow copy of the array as CPs should never have side effects
      const fields = this.fieldsSorted.slice();
      fields.__parent__ = this;
      return fields;
    })
  });

  _exports.default = _default;
});