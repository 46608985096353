define("lightway-connect/components/content-editor/parts/divider-part", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="part-content p{{this.args.part.basePrototype.partsSortedBound.firstObject.identifier}}">
  
    <ContentEditor::DraggablePartsList
      @part={{this.args.part.basePrototype.partsSortedBound.firstObject}}
      @items={{this.args.part.basePrototype.partsSortedBound.firstObject.partsSortedBound}}
      @group={{this.listGroup}}
      @draggingEnabled={{false}}
      @prototypeBasedPart={{this.args.part}}
      @selectPartAction={{this.args.selectPartAction}}
      @dropPartAction={{this.args.dropPartAction}} />
  
  </div>
  
  {{yield}}
  
  */
  {
    id: "mnB+T1xr",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"args\",\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"identifier\"]]]]],[8],[0,\"\\n\\n  \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@group\",\"@draggingEnabled\",\"@prototypeBasedPart\",\"@selectPartAction\",\"@dropPartAction\"],[[23,0,[\"args\",\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\"]],[23,0,[\"args\",\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"partsSortedBound\"]],[23,0,[\"listGroup\"]],false,[23,0,[\"args\",\"part\"]],[23,0,[\"args\",\"selectPartAction\"]],[23,0,[\"args\",\"dropPartAction\"]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/divider-part.hbs"
    }
  });

  class ContentEditorPartsDividerPartComponent extends _component.default {}

  _exports.default = ContentEditorPartsDividerPartComponent;

  window.__CLASSIC_OWN_CLASSES__.set(ContentEditorPartsDividerPartComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentEditorPartsDividerPartComponent);
});