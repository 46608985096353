define("lightway-connect/components/content-editor/setting-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isOpen: false,
    editorService: Ember.inject.service(),
    scopedModel: Ember.computed('model', 'editorService.hoverSelection', function () {
      let result = this.model;

      if (this.editorService.hoverItem) {
        result = this.editorService.hoverItem;
      }

      return result;
    }),
    actions: {
      togglePanel() {
        this.toggleProperty('isOpen');
      }

    }
  });

  _exports.default = _default;
});