define("lightway-connect/components/select-option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    didReceiveAttrs() {
      this._super(...arguments);

      this.checkIfValueMatches();
    },

    checkIfValueMatches() {
      let result = this.valueToMatch == this.value;
      this.set('optionSelected', result);
    }

  });

  _exports.default = _default;
});