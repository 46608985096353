define("lightway-connect/components/resources-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.set('itemToDelete', null);
    },

    statusClasses: Ember.computed('item.{status,enabled}', function () {
      let classes = "status-indicator";

      if (this.item.isA('page')) {
        if (this.item.status === "published") {
          classes += " enabled";
        } else {
          classes += " disabled";
        }
      } else {
        if (this.item.enabled) {
          classes += " enabled";
        } else {
          classes += " disabled";
        }
      }

      return classes;
    }),
    actions: {
      closeDeleteModal() {
        this.set('itemToDelete', null);
      },

      prepareItemForDeletion(item) {
        this.set('itemToDelete', item);
      },

      confirmDeletion() {
        this.deleteAction(this.itemToDelete);
        this.set('itemToDelete', null);
      },

      toggleStatus() {
        if (this.item.isA('page')) {
          if (this.item.status === "published") {
            this.item.set('status', 'unpublished');
            this.item.save();
          } else {
            this.item.set('status', 'published');
            this.item.save();
          }
        } else {
          this.item.toggleProperty('enabled');
          this.item.save();
        }
      }

    }
  });

  _exports.default = _default;
});