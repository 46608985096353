define("lightway-connect/components/file-selector", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['selector-modal file-selector grid-x align-center cell-block-y'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    selectingImages: Ember.computed(function () {
      return this.type == 'image';
    }),
    selectingDocuments: Ember.computed(function () {
      return this.type == 'document';
    }),

    init() {
      this._super(...arguments);

      this.loadFolders();

      if (this.selectionMode === "multiple") {
        this.set('selectedFiles', Ember.A([]));
      }
    },

    loadFolders() {
      let site = this.get('currentSite.site');
      this.store.query(`${this.type}-folder`, {
        filter: {
          site: site.id,
          parent: site.id,
          parent_type: 'sites',
          id: !null
        }
      }).then(results => {
        this.set('folders', results);
      });
    },

    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      let {
        token
      } = this.get('session.data.authenticated');

      if (this.get('session.isAuthenticated') && token) {
        let headers = {
          'Authorization': `Bearer ${token}`
        }; // Upload request

        yield file.upload({
          url: `/api/backend/${this.type}s`,
          headers: headers,
          accepts: 'application/json;version=1',
          data: {
            [`${this.type}-folder-id`]: this.selectedFolder.id
          },
          fileKey: `${this.type}-file`
        }).then(response => {
          this.store.pushPayload(response.body); // Clear flash messages in case of multiple alerts

          this.flashMessages.clearMessages().success("File uploaded"); // Set new file as selection

          let newFile = this.store.peekRecord(this.type, response.body.data.id);
          this.selectFile(newFile);
        }, () => {
          //(failure)
          this.flashMessages.clearMessages().alert("There was an error");
        });
      }
    }).maxConcurrency(3).enqueue(),

    selectFile(file) {
      if (this.selectionMode === "multiple") {
        if (!this.selectedFiles) {
          this.set('selectedFiles', Ember.A([]));
        }

        this.selectedFiles.addObject(file);
      } else {
        this.set('selectedFile', file);
      }
    },

    actions: {
      selectFolder(folder) {
        this.set('selectedFolder', folder);
      },

      deselectFolder() {
        this.set('selectedFile', null);
        this.set('selectedFolder', null);
      },

      selectFile(file) {
        this.selectFile(file);
      },

      deselectFile(file) {
        if (this.selectionMode === "multiple") {
          this.selectedFiles.removeObject(file);
        } else {
          this.set('selectedFile', null);
        }
      },

      uploadFile(file) {
        this.uploadFile.perform(file);
      }

    }
  });

  _exports.default = _default;
});