define("lightway-connect/models/site", ["exports", "@ember-data/model", "lightway-connect/mixins/folder-parentable", "lightway-connect/mixins/style-parentable"], function (_exports, _model, _folderParentable, _styleParentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Site = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.belongsTo)('account'), _dec7 = (0, _model.hasMany)('notification-rule', {
    async: true,
    inverse: 'site'
  }), _dec8 = (0, _model.belongsTo)('domain', {
    inverse: 'primarySite'
  }), _dec9 = (0, _model.hasMany)(), _dec10 = (0, _model.hasMany)(), _dec11 = (0, _model.hasMany)(), _dec12 = (0, _model.hasMany)(), _dec13 = (0, _model.hasMany)(), _dec14 = (0, _model.hasMany)('user-plan', {
    async: true,
    inverse: 'site'
  }), _dec15 = (0, _model.hasMany)(), _dec16 = (0, _model.hasMany)(), _dec17 = (0, _model.hasMany)(), _dec18 = (0, _model.hasMany)(), _dec19 = (0, _model.belongsTo)('mail-template', {
    inverse: 'siteDefaulted'
  }), _dec20 = (0, _model.hasMany)(), _dec21 = (0, _model.hasMany)(), _dec22 = (0, _model.hasMany)('product-offer', {
    async: true,
    inverse: 'site'
  }), _dec23 = (0, _model.hasMany)('user-product-offer', {
    async: true,
    inverse: 'site'
  }), _dec24 = (0, _model.hasMany)(), _dec25 = (0, _model.hasMany)(), _dec26 = (0, _model.hasMany)(), _dec27 = (0, _model.hasMany)(), _dec28 = (0, _model.hasMany)('logged-mail-message', {
    async: true,
    inverse: 'site'
  }), _dec29 = (0, _model.belongsTo)('style', {
    inverse: 'globalPaletteSite',
    async: false
  }), classic(_class = (_class2 = (_temp = class Site extends _model.default.extend(_folderParentable.default, _styleParentable.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "subdomain", _descriptor2, this);

      _initializerDefineProperty(this, "gTag", _descriptor3, this);

      _initializerDefineProperty(this, "selfOwned", _descriptor4, this);

      _initializerDefineProperty(this, "defaultTimeZone", _descriptor5, this);

      _initializerDefineProperty(this, "owner", _descriptor6, this);

      _initializerDefineProperty(this, "notificationRules", _descriptor7, this);

      _initializerDefineProperty(this, "primaryDomain", _descriptor8, this);

      _initializerDefineProperty(this, "domains", _descriptor9, this);

      _initializerDefineProperty(this, "roles", _descriptor10, this);

      _initializerDefineProperty(this, "screens", _descriptor11, this);

      _initializerDefineProperty(this, "dataKinds", _descriptor12, this);

      _initializerDefineProperty(this, "userKinds", _descriptor13, this);

      _initializerDefineProperty(this, "userPlans", _descriptor14, this);

      _initializerDefineProperty(this, "users", _descriptor15, this);

      _initializerDefineProperty(this, "layouts", _descriptor16, this);

      _initializerDefineProperty(this, "pages", _descriptor17, this);

      _initializerDefineProperty(this, "mailTemplates", _descriptor18, this);

      _initializerDefineProperty(this, "defaultMailTemplate", _descriptor19, this);

      _initializerDefineProperty(this, "prototypes", _descriptor20, this);

      _initializerDefineProperty(this, "dataTags", _descriptor21, this);

      _initializerDefineProperty(this, "productOffers", _descriptor22, this);

      _initializerDefineProperty(this, "userProductOffers", _descriptor23, this);

      _initializerDefineProperty(this, "orders", _descriptor24, this);

      _initializerDefineProperty(this, "invoices", _descriptor25, this);

      _initializerDefineProperty(this, "mailingLists", _descriptor26, this);

      _initializerDefineProperty(this, "paymentGateways", _descriptor27, this);

      _initializerDefineProperty(this, "loggedMailMessages", _descriptor28, this);

      _initializerDefineProperty(this, "globalPalette", _descriptor29, this);
    } // Also has many folders through FolderParentable


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "subdomain", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "gTag", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "selfOwned", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "defaultTimeZone", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "owner", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "notificationRules", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "primaryDomain", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "domains", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "roles", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "screens", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "dataKinds", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "userKinds", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "userPlans", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "users", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "layouts", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "pages", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplates", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "defaultMailTemplate", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "prototypes", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "dataTags", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "productOffers", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "userProductOffers", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "orders", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "invoices", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "mailingLists", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class2.prototype, "paymentGateways", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class2.prototype, "loggedMailMessages", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class2.prototype, "globalPalette", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = Site;
});