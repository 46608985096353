define("lightway-connect/components/tab-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="grid-x tab-bar">
    {{yield (hash
      Option=(component 'tab-option'
        property=@property
        currentValue=@currentValue
        route=@route)
    )}}
  </div>
  */
  {
    id: "hi2kXruY",
    block: "{\"symbols\":[\"@route\",\"@currentValue\",\"@property\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-x tab-bar\"],[8],[0,\"\\n  \"],[14,4,[[28,\"hash\",null,[[\"Option\"],[[28,\"component\",[\"tab-option\"],[[\"property\",\"currentValue\",\"route\"],[[23,3,[]],[23,2,[]],[23,1,[]]]]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/tab-bar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});