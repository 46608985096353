define("lightway-connect/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvrnf6LZ",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-y grid-frame\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x app-messages\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"      \"],[5,\"flash-message\",[],[[\"@flash\",\"@messageStyle\",\"@class\"],[[23,1,[]],\"foundation\",\"cell small-12\"]],{\"statements\":[[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n          \"],[7,\"div\",true],[11,\"class\",[29,[\"cell small-12 message \",[23,2,[\"flashType\"]]]]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"flash-type\"],[8],[0,\"\\n              \"],[1,[23,2,[\"flashType\"]],false],[0,\" -\\n            \"],[9],[0,\"\\n\\n            \"],[7,\"span\",true],[10,\"class\",\"flash-message\"],[10,\"data-test-id\",\"flash-message\"],[8],[0,\"\\n              \"],[1,[23,3,[\"message\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"showProgressBar\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\",true],[10,\"class\",\"alert-progress\"],[8],[0,\"\\n                \"],[7,\"div\",true],[11,\"class\",[29,[\"alert-progressBar \",[23,2,[\"progressDuration\"]]]]],[8],[9],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"]],\"parameters\":[2,3]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[14,4],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/application.hbs"
    }
  });

  _exports.default = _default;
});