define("lightway-connect/templates/sites/site/settings/user-kinds/user-kind/plans/plan/offers/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OCPmbr2O",
    "block": "{\"symbols\":[\"F\",\"Field\",\"Field\",\"Field\",\"@model\"],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  Update Offer\\n\"],[9],[0,\"\\n\\n\"],[5,\"form\",[[12,\"data-test-id\",\"update-offer-form\"]],[[\"@model\",\"@errors\"],[[23,5,[]],[23,0,[\"formErrors\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[6,[23,1,[\"Field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,4,[\"Label\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Offer Name\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,4,[\"TextInput\"]],[],[[],[]]],[0,\"\\n    \"],[6,[23,4,[\"ErrorsList\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[4]}],[0,\"\\n\\n  \"],[6,[23,1,[\"Field\"]],[],[[\"@property\"],[\"redemptionCode\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[\"Label\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Redemption Code\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"TextInput\"]],[],[[],[]]],[0,\"\\n    \"],[6,[23,3,[\"ErrorsList\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\\n  \"],[6,[23,1,[\"Field\"]],[],[[\"@property\"],[\"data.trial-days\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"Label\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Trial Period, Number of Days\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"Description\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"NumberInput\"]],[],[[],[]]],[0,\"\\n    \"],[6,[23,2,[\"ErrorsList\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[6,[23,1,[\"Button\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,0,[\"update\"]],[23,5,[]]],null]]],{\"statements\":[[0,\"\\n    Update\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/user-kinds/user-kind/plans/plan/offers/offer.hbs"
    }
  });

  _exports.default = _default;
});