define("lightway-connect/routes/sites/site/content/mail-messages/mail-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteContentMailMessagesMailMessageRoute extends Ember.Route {
    beforeModel() {
      // Hide section sidebar
      this.controllerFor('sites.site.content').hideSidebar();
    }

    async model(params) {
      return this.store.findRecord('mail-message', params.mail_message_id, {
        reload: true
      });
    }

  }

  _exports.default = SitesSiteContentMailMessagesMailMessageRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteContentMailMessagesMailMessageRoute, true);
});