define("lightway-connect/components/sites/parts/sidebars/container-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sLQ4Kl7A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-sidebar\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    Container Properties\\n  \"],[9],[0,\"\\n\\n  Applied link:\\n  \"],[1,[28,\"input\",null,[[\"value\",\"data-test-id\"],[[28,\"mut\",[[28,\"get\",[[24,[\"selectedElement\"]],\"element.linkToUrl\"],null]],null],\"link-to-url-field\"]]],false],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"remove button alert\"],[12,\"data-test-id\",\"remove-part-link\"],[3,\"action\",[[23,0,[]],\"removePart\"]],[8],[0,\"\\n    Remove Container\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/sidebars/container-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});