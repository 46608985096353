define("lightway-connect/helpers/dynamic-style-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicStyleProperty = dynamicStyleProperty;
  _exports.default = void 0;

  function dynamicStyleProperty(property, key, inherited) {
    return Ember.computed('editorService.hoverSelection', 'editorService.viewport.{parent,parent.parent}', `${property}.small.${key}`, `${property}.medium.${key}`, `${property}.large.${key}`, function () {
      let viewport = this.editorService.viewport;
      let object = this;

      if (inherited) {
        if (viewport.parent) {
          if (object.get(`${property}.${viewport.parent.size}.${key}`) != null) {
            return object.get(`${property}.${viewport.parent.size}.${key}`);
          } else {
            if (viewport.parent.parent) {
              if (object.get(`${property}.${viewport.parent.parent.size}.${key}`) != null) {
                return object.get(`${property}.${viewport.parent.parent.size}.${key}`);
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        } else {
          return null;
        }
      } else {
        if (object.get(`${property}.${viewport.size}`)) {
          if (object.get(`${property}.${viewport.size}.${key}`) != null) {
            return object.get(`${property}.${viewport.size}.${key}`);
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  var _default = Ember.Helper.helper(dynamicStyleProperty);

  _exports.default = _default;
});