define("lightway-connect/routes/sites/site/data-kinds/data-kind/items/item/associates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteDataKindsDataKindItemsItemAssociatesNewRoute = (_dec = Ember.inject.service, (_class = (_temp = class SitesSiteDataKindsDataKindItemsItemAssociatesNewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentSite", _descriptor, this);
    }

    async model() {
      let associatesModel = this.modelFor('sites.site.data-kinds.data-kind.items.item.associates');
      let field = associatesModel.field;
      let item = associatesModel.item;
      let fieldParent = await associatesModel.field.parent;
      let isReverseField = false;

      if (fieldParent != item.parent) {
        isReverseField = true;
      }

      let associateItemParent = null;

      if (isReverseField) {
        associateItemParent = fieldParent;
      } else {
        associateItemParent = await field.subjectKind;
        await associateItemParent.fields;
      }

      let associateItem = this.store.createRecord('data-item', {
        parent: associateItemParent
      });
      let associate = null;

      if (isReverseField) {
        associate = this.store.createRecord('data-associate', {
          owner: associateItem,
          item: item,
          field: field
        });
      } else {
        associate = this.store.createRecord('data-associate', {
          owner: item,
          item: associateItem,
          field: field
        });
      }

      let newDataAssociates = Ember.A([associate]);
      return Ember.RSVP.hash({
        associateItem: associateItem,
        parentItem: item,
        associateField: field,
        newDataAssociates: newDataAssociates
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSite", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SitesSiteDataKindsDataKindItemsItemAssociatesNewRoute;
});