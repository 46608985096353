define("lightway-connect/components/add-object-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    property: null,

    init() {
      this._super(...arguments);

      this.setUpProperty();
      this.setUpPreference();
    },

    setUpProperty() {
      if (!this.model.get(`preferences.${this.property}`)) {
        this.model.set(`preferences.${this.property}`, Ember.A([]));
      }
    },

    setUpPreference() {
      this.obj = {};
    }

  });

  _exports.default = _default;
});