define("lightway-connect/components/content-editor/draggable-part", ["exports", "ember-drag-sort/components/drag-sort-item"], function (_exports, _dragSortItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dragSortItem.default.extend({
    editorService: Ember.inject.service(),
    classNameBindings: ['outerCell', 'isCurrentPart:current-part-outer', 'isHidden:hidden-part', // For ember-drag-sort:
    'isDragged2:-isDragged', 'isDraggingOver:-isDraggingOver', 'shouldShowPlaceholderAbove2:-placeholderAbove', 'shouldShowPlaceholderBelow2:-placeholderBelow'],
    outerCell: Ember.computed('orientation', 'directionClasses', 'widthClasses', 'heightType', function () {
      let finalString = "oc cell ";

      if (this.part.kind == 'region') {
        // IMPORTANT: Adding 'auto' cell stops the
        // container height from growing with the
        // content. Don't do this.
        finalString += " fill-auto full-height ";
        return finalString;
      }

      if (this.orientation == 'vertical') {
        // IMPORTANT: Adding 'auto' cell stops the
        // container height from growing with the
        // content. Don't do this.
        if (this.heightType == 'vh') {
          finalString += "canvas-height ";
        } else if (this.heightType == 'fill') {
          finalString += "fill-auto";
        }
      } else {
        if (this.heightType == 'fill') {
          finalString += "fill-height ";
        }

        finalString += this.widthClasses;
      }

      return finalString;
    }),
    // innerGrid rendered in the template
    innerGrid: Ember.computed('orientation', 'directionXClass', 'widthClasses', 'heightType', 'part.identifier', function () {
      let finalString = " ig";

      if (this.orientation == 'vertical') {
        finalString += " grid-x ";

        if (this.directionXClass) {
          finalString += this.directionXClass;
        }
      } else {
        finalString += " grid-y ";

        if (this.directionYClass) {
          finalString += this.directionYClass;
        }
      }

      if (this.heightType == 'vh') {
        // TODO: Bug fix
        // When a vh container is nested inside a vh
        // container, the child container becomes
        // larger than the parent. Fix: parent
        // container should not have set height.
        finalString += " canvas-height-adjusted";
      } else if (this.heightType == 'fill') {
        finalString += " full-height";
      } else if (this.part.kind == 'region') {
        // Set region height type to 100%
        finalString += " full-height";
      }

      finalString += " ps" + this.part.identifier;
      finalString += ` ${this.part.kind}-part part`;
      return finalString;
    }),
    innerCell: Ember.computed('widthClasses', 'part.parent.contentDirection', 'selectable', 'isCurrentPart', 'isCurrentHoverSelection', 'item.identifier', function () {
      // Use item id instead of part id so that for
      // prototype based parts, the id is of the
      // basePrototype's first nested part.
      let finalString = `ic cell pt${this.item.identifier} `;

      if (this.selectable) {
        finalString += " selectable ";
      }

      if (this.isCurrentPart) {
        finalString += " current-part ";
      }

      if (this.isCurrentHoverSelection) {
        finalString += " current-hover-selection ";
      }

      if (this.part.kind === "region" || this.part.kind === "form") {
        finalString += " small-12 ";
        return finalString;
      }

      if (this.part.parent && this.part.parent.isA('part')) {
        if (this.orientation == 'vertical') {
          finalString += this.widthClasses;
        } else {
          finalString += "small-12";
        }
      } else {
        if (this.part.kind == 'text') {
          // Text part that is rich needs an inner cell of auto, not shrink.
          finalString += " auto";
        } else {
          // should be "shrink" when no width specified
          finalString += this.widthClasses;
        }
      } // IMPORTANT: Adding 'auto' cell stops the
      // container height from growing with the
      // content. Don't do this.


      finalString = finalString.replace("auto", "fill-auto");
      return finalString;
    }),
    widthClasses: Ember.computed('widthType', 'width', function () {
      let finalString = "";

      switch (this.widthType) {
        case 'fill':
          finalString += " auto";
          break;

        case 'fraction':
          finalString += `small-${this.width}`;
          break;

        default:
          if (this.item.kind == 'text') {
            finalString += "small-auto ";
          } else {
            finalString += " shrink";
          }

      }

      return finalString;
    }),
    isHidden: Ember.computed('editorService.viewport.size', 'part.preferences.{small.hidden,medium.hidden,large.hidden}', function () {
      return this.part.hidden;
    }),
    isCurrentPart: Ember.computed('editorService.selection', function () {
      return this.part == this.editorService.selection;
    }),
    isCurrentHoverSelection: Ember.computed('editorService.hoverSelection', function () {
      return this.part == this.editorService.hoverSelection;
    }),
    width: Ember.computed('item.{width,inheritedWidth}', function () {
      return this.item.get('width') || this.item.get('inheritedWidth');
    }),
    widthType: Ember.computed('item.{widthType,inheritedWidthType}', function () {
      return this.item.get('widthType') || this.item.get('inheritedWidthType');
    }),
    heightType: Ember.computed('item.{heightType,inheritedHeightType}', function () {
      return this.item.get('heightType') || this.item.get('inheritedHeightType');
    }),
    selectable: Ember.computed(function () {
      if (this.editorService.inPageMode) {
        if (this.part.baseParent.isA('layout-version')) {
          return false;
        }

        if (this.part.baseParent.isA('prototype')) {
          return false;
        }
      }

      if (this.editorService.inPageMode || this.editorService.inItemMode || this.editorService.inEssentialContentItemMode || this.editorService.inKindMode) {
        if (this.part.baseParent.isA('prototype')) {
          return false;
        }
      }

      if (this.editorService.inMailMessageMode) {
        if (this.part.baseParent.isA('mail-template-version')) {
          return false;
        }
      }

      return true;
    })
  });

  _exports.default = _default;
});