define("lightway-connect/components/form/select-option", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.selected}}
  
    <option value={{@value}} selected data-test-option-id={{@value}}>
      {{yield}}
    </option>
  
  {{else}}
  
    <option value={{@value}} data-test-option-id={{@value}}>
      {{yield}}
    </option>
  
  {{/if}}
  */
  {
    id: "tamnNW3t",
    block: "{\"symbols\":[\"@value\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"selected\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[10,\"selected\",\"\"],[11,\"data-test-option-id\",[23,1,[]]],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"data-test-option-id\",[23,1,[]]],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/select-option.hbs"
    }
  });

  class FormSelectOptionComponent extends _component.default {
    get selected() {
      if (this.args.currentSelection === this.args.value) {
        return true;
      }

      return false;
    }

  }

  _exports.default = FormSelectOptionComponent;

  window.__CLASSIC_OWN_CLASSES__.set(FormSelectOptionComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormSelectOptionComponent);
});