define("lightway-connect/components/content-editor/parts/button-part", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button>
    {{this.label}}
  </button>
  {{yield}}
  */
  {
    id: "+vCr2v2A",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",true],[8],[0,\"\\n  \"],[1,[23,0,[\"label\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/button-part.hbs"
    }
  });

  class ContentEditorPartsButtonPartComponent extends _component.default {
    get label() {
      if (this.args.part.preferences.label) {
        return this.args.part.preferences.label;
      } else {
        return "Submit";
      }
    }

  }

  _exports.default = ContentEditorPartsButtonPartComponent;

  window.__CLASSIC_OWN_CLASSES__.set(ContentEditorPartsButtonPartComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentEditorPartsButtonPartComponent);
});