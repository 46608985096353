define("lightway-connect/router", ["exports", "lightway-connect/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;

  window.__CLASSIC_OWN_CLASSES__.set(Router, true);

  Router.map(function () {
    // Accounts
    this.route('accounts', {
      path: 'account'
    }, function () {
      this.route('registration-confirmations', {
        path: 'confirm-registration'
      }, function () {
        this.route('registration-confirmation', {
          path: ':confirmation_token'
        });
      });
      this.route('settings', {
        path: '/'
      }, function () {
        this.route('notifications', function () {
          this.route('site', {
            path: ':site_id'
          });
        });
      });
    });
    this.route('sign-up', {
      path: '/register'
    });
    this.route('sign-in', {
      path: '/signin'
    });
    this.route('reset-password', function () {
      this.route('token', {
        path: '/:token_id'
      });
    });
    this.route('sites', function () {
      this.route('site', {
        path: ':site_id'
      }, function () {
        this.route('settings', function () {
          this.route('edit');
          this.route('contributors', function () {
            this.route('roles', function () {
              this.route('new');
              this.route('role', {
                path: ':role_id'
              }, function () {
                this.route('edit');
                this.route('delete');
                this.route('invitations', function () {
                  this.route('invitation', {
                    path: ':invitation_id'
                  }, function () {
                    this.route('revoke');
                  });
                });
                this.route('contributors', function () {
                  this.route('contributor', {
                    path: ':contributor_id'
                  }, function () {
                    this.route('remove');
                  });
                });
              });
            });
          });
          this.route('domains', function () {
            this.route('new');
            this.route('domain', {
              path: ':domain_id'
            }, function () {
              this.route('subdomains', function () {
                this.route('subdomain', {
                  path: ':subdomain_id'
                });
                this.route('new');
              });
            });
          });
          this.route('social-accounts');
          this.route('data-kinds', function () {
            this.route('new');
            this.route('data-kind', {
              path: ':data_kind_id'
            }, function () {
              this.route('fields', function () {
                this.route('new');
                this.route('field', {
                  path: ':field_id'
                }, function () {});
              });
              this.route('default-content');
            });
          });
          this.route('mailing-lists', function () {
            this.route('new');
            this.route('mailing-list', {
              path: ':mailing_list_id'
            }, function () {
              this.route('fields', function () {
                this.route('field', {
                  path: ':field_id'
                }, function () {});
                this.route('new');
              });
            });
          });
          this.route('user-kinds', function () {
            this.route('new');
            this.route('user-kind', {
              path: ':user_kind_id'
            }, function () {
              this.route('fields', function () {
                this.route('field', {
                  path: ':field_id'
                }, function () {});
                this.route('new');
              });
              this.route('plans', function () {
                this.route('new');
                this.route('plan', {
                  path: ':plan_id'
                }, function () {
                  this.route('offers', function () {
                    this.route('new');
                    this.route('offer', {
                      path: ':offer_id'
                    });
                  });
                });
              });
            });
          });
          this.route('payment-gateways', function () {
            this.route('new');
            this.route('payment-gateway', {
              path: ':payment_gateway_id'
            });
          });
          this.route('apple-app-config');
        });
        this.route('media', function () {
          this.route('image-folders', {
            path: 'images'
          }, function () {
            this.route('new');
            this.route('image-folder', {
              path: ':folder_id'
            }, function () {
              this.route('edit');
              this.route('delete');
              this.route('image', {
                path: ':image_id'
              }, function () {
                this.route('rename');
                this.route('delete');
                this.route('move');
              });
            });
          });
          this.route('document-folders', {
            path: 'documents'
          }, function () {
            this.route('new');
            this.route('document-folder', {
              path: ':folder_id'
            }, function () {
              this.route('edit');
              this.route('delete');
              this.route('document', {
                path: ':document_id'
              }, function () {
                this.route('rename');
                this.route('delete');
                this.route('move');
              });
            });
          });
        });
        this.route('data-kinds', {
          path: 'data'
        }, function () {
          this.route('data-kind', {
            path: ':data_kind_id'
          }, function () {
            this.route('categories', function () {
              this.route('new');
              this.route('category', {
                path: ':category_id'
              }, function () {
                this.route('edit');
                this.route('delete');
                this.route('items', function () {
                  this.route('new');
                  this.route('item', {
                    path: ':item_id'
                  }, function () {
                    this.route('delete');
                  });
                });
                this.route('categories', function () {
                  this.route('new');
                  this.route('category', {
                    path: ':sub_category_id'
                  }, function () {
                    this.route('edit');
                    this.route('delete');
                    this.route('items', function () {
                      this.route('new');
                      this.route('item', {
                        path: ':item_id'
                      }, function () {
                        this.route('delete');
                      });
                    });
                  });
                });
              });
            });
            this.route('items', function () {
              this.route('new');
              this.route('item', {
                path: ':item_id'
              }, function () {
                this.route('delete');
                this.route('registrations', function () {
                  this.route('registration', {
                    path: ':registration_id'
                  }, function () {
                    this.route('edit');
                  });
                });
                this.route('edit');
                this.route('associates', {
                  path: ':field_id'
                }, function () {
                  this.route('new');
                });
              });
            });
          });
          this.route('orders', function () {
            this.route('order', {
              path: ':order_id'
            });
          });
          this.route('invoices', function () {
            this.route('invoice', {
              path: ':invoice_id'
            }, function () {});
          });
          this.route('mail-logs', function () {
            this.route('mail-log', {
              path: ':mail_log_id'
            });
          });
          this.route('user-plan-subscriptions', function () {
            this.route('user-plan-subscription', {
              path: ':user_plan_subscription_id'
            });
          });
        });
        this.route('web-forms', {
          path: 'forms'
        }, function () {
          this.route('web-form', {
            path: ':web_form_id'
          }, function () {
            this.route('delete');
            this.route('edit');
            this.route('fields', function () {
              this.route('new');
              this.route('field', {
                path: ':form_field_id'
              }, function () {});
            });
          });
          this.route('new');
        });
        this.route('user-kinds', {
          path: 'users'
        }, function () {
          this.route('user-kind', {
            path: ':user_kind_id'
          }, function () {
            this.route('user', {
              path: ':user_id'
            }, function () {
              this.route('edit');
              this.route('associates', {
                path: ':field_id'
              }, function () {});
            });
          });
        });
        this.route('mailing-lists', function () {
          this.route('new');
          this.route('mailing-list', {
            path: ':mailing_list_id'
          }, function () {
            this.route('subscribers', function () {
              this.route('subscriber', {
                path: ':subscription_id'
              });
            });
          });
        });
        this.route('design', function () {
          this.route('global-palette');
          this.route('layouts', function () {
            this.route('new');
            this.route('layout', {
              path: ':layout_id'
            }, function () {});
          });
          this.route('mail-templates', function () {
            this.route('new');
            this.route('mail-template', {
              path: ':mail_template_id'
            });
          });
        });
        this.route('content', function () {
          this.route('pages', function () {
            this.route('new');
            this.route('page', {
              path: ':page_id'
            }, function () {});
          });
          this.route('screens', function () {
            this.route('new');
            this.route('screen', {
              path: ':screen_id'
            }, function () {
              this.route('delete');
            });
          });
          this.route('mail-messages', function () {
            this.route('new');
            this.route('mail-message', {
              path: ':mail_message_id'
            });
          });
        });
      });
      this.route('new');
    });
    this.route('invitations', function () {
      this.route('invitation', {
        path: ':invitation_id'
      });
    });
    this.route('policies', function () {
      this.route('privacy');
    });
    this.route('auth', function () {
      this.route('instagram');
    });
  });
});