define("lightway-connect/components/item-picker", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell small-12 item-picker-container">
    <div class="grid-y cell-block-y item-picker-inner-container">
  
      {{yield (hash
        Header=(component 'item-picker/header')
        SubHeader=(component 'item-picker/sub-header')
        List=(component 'item-picker/list'
          selectedItem=this.selectedItem
          selectAction=(action this.selectItem)
          deselectAction=(action this.deselectItem)
        )
        Footer=(component 'item-picker/footer'
          selectedItem=this.selectedItem
          confirmAction=(action this.confirmItem)
        )
      )}}
  
    </div>
  </div>
  */
  {
    id: "ZFhqBrYt",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12 item-picker-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-y cell-block-y item-picker-inner-container\"],[8],[0,\"\\n\\n    \"],[14,1,[[28,\"hash\",null,[[\"Header\",\"SubHeader\",\"List\",\"Footer\"],[[28,\"component\",[\"item-picker/header\"],null],[28,\"component\",[\"item-picker/sub-header\"],null],[28,\"component\",[\"item-picker/list\"],[[\"selectedItem\",\"selectAction\",\"deselectAction\"],[[23,0,[\"selectedItem\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectItem\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"deselectItem\"]]],null]]]],[28,\"component\",[\"item-picker/footer\"],[[\"selectedItem\",\"confirmAction\"],[[23,0,[\"selectedItem\"]],[28,\"action\",[[23,0,[]],[23,0,[\"confirmItem\"]]],null]]]]]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/item-picker.hbs"
    }
  });

  let ItemPickerComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class ItemPickerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "selectedItem", _descriptor, this);
    }

    selectItem(item) {
      if (this.args.selectAction) {
        this.args.selectAction(item);
      }

      this.selectedItem = item;
    }

    deselectItem() {
      this.selectedItem = null;
    }

    confirmItem() {
      this.args.confirmAction(this.selectedItem);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedItem", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectItem", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "deselectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "confirmItem"), _class.prototype)), _class));
  _exports.default = ItemPickerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ItemPickerComponent);
});