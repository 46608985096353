define("lightway-connect/components/content-editor/image-collection-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model, store) {
    return Ember.computed(key, `model.${key}`, function () {
      let images = Ember.A([]);
      let val = Ember.get(model, `${key}`);

      if (val) {
        val.forEach(item => {
          let image = store.findRecord('image', item.id);
          images.addObject(image);
        });
      }

      return images;
    });
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'currentImages', valueFromProperty(this.propertyPath, this.model, this.store));
    },

    inputId: Ember.computed({
      get() {
        return Ember.guidFor(this) + '-inpudid';
      }

    }),
    propertyPath: Ember.computed(function () {
      return this.property;
    }),
    actions: {
      toggleModalForImages() {
        this.toggleProperty('isShowingModalForImages');
      },

      selectImages(images) {
        let imageArray = Ember.A([]); // Keep any images already existing in property

        if (this.model.get(this.propertyPath)) {
          this.model.get(this.propertyPath).forEach(item => {
            let imageHash = {
              "id": item.id
            };
            imageArray.addObject(imageHash);
          });
        }

        images.forEach(image => {
          let imageHash = {
            "id": image.id
          };
          imageArray.addObject(imageHash);
        });
        let changes = {};
        changes[this.propertyPath] = imageArray;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.toggleProperty('isShowingModalForImages');
      },

      removeImage(image) {
        let imageArray = Ember.A([]);
        this.model.get(this.propertyPath).forEach(item => {
          if (item.id != image.id) {
            let imageHash = {
              "id": item.id
            };
            imageArray.addObject(imageHash);
          }
        });
        let changes = {};
        changes[this.propertyPath] = imageArray;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }

    }
  });

  _exports.default = _default;
});