define("lightway-connect/templates/sites/site/settings/contributors/roles/role/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "faBka2KW",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\",\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-8 large-6 card\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"\\n        Edit Role\\n      \"],[9],[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[23,4,[\"role\"]],[23,0,[\"RoleFormValidations\"]]],null]],null,{\"statements\":[[0,\"        \"],[5,\"form-errors\",[],[[\"@changeset\"],[[23,1,[]]]]],[0,\"\\n\\n        \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"save\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n          \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n            \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n              Name\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[6,[23,3,[\"textInput\"]],[],[[],[]]],[0,\"\\n          \"]],\"parameters\":[3]}],[0,\"\\n\\n          \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n            Update Role\\n          \"]],\"parameters\":[]}],[0,\"\\n\\n        \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/contributors/roles/role/edit.hbs"
    }
  });

  _exports.default = _default;
});