define("lightway-connect/mirage/factories/web-form", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Web Form ${i}`;
    },

    identifier(i) {
      return `web-form-${i}`;
    },

    preferences: {},
    messageForm: (0, _emberCliMirage.trait)({
      kind: "message_form"
    }),
    recipientEmail: "foobar@foobar.com",
    introductionMessage: "Introduction message.",
    confirmationMessage: "Confirmation message."
  });

  _exports.default = _default;
});