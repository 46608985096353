define("lightway-connect/models/data-item", ["exports", "@ember-data/model", "lightway-connect/mixins/part-kontentable", "lightway-connect/mixins/part-parentable", "lightway-connect/mixins/page-link-resource", "lightway-connect/mixins/data-property-item", "lightway-connect/mixins/data-property-resource"], function (_exports, _model, _partKontentable, _partParentable, _pageLinkResource, _dataPropertyItem, _dataPropertyResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DataItem = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec6 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec7 = (0, _model.belongsTo)('dataItemParentable', {
    polymorphic: true,
    async: false
  }), _dec8 = (0, _model.belongsTo)('user', {
    polymorphic: true,
    async: false
  }), _dec9 = (0, _model.hasMany)({
    async: false
  }), _dec10 = (0, _model.hasMany)(), _dec11 = (0, _model.hasMany)(), _dec12 = (0, _model.hasMany)(), _dec13 = (0, _model.belongsTo)('style', {
    async: false
  }), _dec14 = Ember.computed, _dec15 = Ember.computed, _dec16 = Ember.computed('parent.prototypes'), _dec17 = Ember.computed('parent.prototypes'), classic(_class = (_class2 = (_temp = class DataItem extends _model.default.extend(_partKontentable.default, _partParentable.default, _pageLinkResource.default, _dataPropertyItem.default, _dataPropertyResource.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "permalink", _descriptor2, this);

      _initializerDefineProperty(this, "rowOrder", _descriptor3, this);

      _initializerDefineProperty(this, "enabled", _descriptor4, this);

      _initializerDefineProperty(this, "preferences", _descriptor5, this);

      _initializerDefineProperty(this, "contentJson", _descriptor6, this);

      _initializerDefineProperty(this, "parent", _descriptor7, this);

      _initializerDefineProperty(this, "author", _descriptor8, this);

      _initializerDefineProperty(this, "dataTags", _descriptor9, this);

      _initializerDefineProperty(this, "orders", _descriptor10, this);

      _initializerDefineProperty(this, "orderItems", _descriptor11, this);

      _initializerDefineProperty(this, "registrations", _descriptor12, this);

      _initializerDefineProperty(this, "globalStyle", _descriptor13, this);
    }

    get currentVersion() {
      return this;
    }

    get baseParent() {
      return this;
    }

    get prototypes() {
      return this.parent.prototypes;
    }

    get availablePrototypeKinds() {
      let prototypes = Ember.A([]);
      let addSection = false;
      let addCta = false;
      let addBadge = false;
      let addContentClip = false;
      let addDivider = false;
      let addDataProfile = false;
      let addDataCollection = false;
      let addImageGallery = false;
      this.parent.prototypes.forEach(prototype => {
        switch (prototype.kind) {
          case 'section':
            addSection = true;
            break;

          case 'cta':
            addCta = true;
            break;

          case 'badge':
            addBadge = true;
            break;

          case 'content-clip':
            addContentClip = true;
            break;

          case 'divider':
            addDivider = true;
            break;

          case 'data-profile':
            addDataProfile = true;
            break;

          case 'data-collection':
            addDataCollection = true;
            break;

          case 'image-gallery':
            addImageGallery = true;
            break;
        }
      });

      if (addSection) {
        prototypes.pushObject("section");
      }

      if (addCta) {
        prototypes.pushObject("cta");
      }

      if (addBadge) {
        prototypes.pushObject("badge");
      }

      if (addContentClip) {
        prototypes.pushObject("content-clip");
      }

      if (addDivider) {
        prototypes.pushObject("divider");
      }

      if (addDataProfile) {
        prototypes.pushObject("data-profile");
      }

      if (addDataCollection) {
        prototypes.pushObject("data-collection");
      }

      if (addImageGallery) {
        prototypes.pushObject("image-gallery");
      }

      return prototypes;
    }

    get subjectTypeObject() {
      return this;
    }

    get currentDynamicContainer() {
      return this;
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get displayName() {
      if (this.parent && this.parent.defaultPropertyIsDateTime) {
        return this.formatDate(this.name);
      }

      return this.name;
    }

    get isADataItem() {
      return true;
    }

    formatDate(value) {
      let date = new Date(value);

      if (!date) {
        date = new Date();
      }

      let year = date.getFullYear();
      let month = date.toLocaleString('default', {
        month: 'short'
      });
      let day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      minutes = minutes < 10 ? '0' + minutes : minutes;
      let formatted = `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;
      return formatted;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "permalink", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "rowOrder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "enabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "contentJson", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "parent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "author", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "dataTags", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "orders", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "orderItems", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "registrations", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "globalStyle", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "currentVersion", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "currentVersion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "baseParent", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "baseParent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "prototypes", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "prototypes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "availablePrototypeKinds", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "availablePrototypeKinds"), _class2.prototype)), _class2)) || _class);
  _exports.default = DataItem;
});