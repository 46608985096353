define("lightway-connect/mirage/factories/site", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id(i) {
      return `site-${i + 1}`;
    },

    name(i) {
      return `Site ${i + 1}`;
    },

    subdomain(i) {
      return `site-${i + 1}`;
    },

    selfOwned: true,
    associated: (0, _emberCliMirage.trait)({
      selfOwned: false
    }),
    defaultTimeZone: "Sydney"
  });

  _exports.default = _default;
});