define("lightway-connect/adapters/application", ["exports", "@ember-data/adapter/json-api", "lightway-connect/mixins/cascade-delete", "ember-inflector"], function (_exports, _jsonApi, _cascadeDelete, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_cascadeDelete.default, {
    namespace: 'api/backend',
    // ember-simple-auth
    session: Ember.inject.service(),
    identificationAttributeName: 'accountId',

    pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    },

    headers: Ember.computed('session.{data.authenticated.access_token,isAuthenticated}', function () {
      let headers = {};

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headers;
    })
  });

  _exports.default = _default;
});