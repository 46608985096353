define("lightway-connect/services/current-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CurrentSiteService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, classic(_class = (_class2 = (_temp = class CurrentSiteService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);
    }

    existingRecord(params) {
      return this.store.peekRecord('site', params.site_id);
    }

    fetchedRecord(params) {
      return this.store.findRecord('site', params.site_id).catch(reason => {
        let error = reason.errors.get('lastObject'); // Add error flash message

        this.flashMessages.clearMessages().alert(error);
        Ember.RSVP.resolve();
      });
    }

    load(params) {
      // Unload current site if exists
      this.unload();

      if (!Ember.isEmpty(params)) {
        let site = null;

        if (this.existingRecord(params)) {
          site = this.existingRecord(params);
        } else {
          site = this.fetchedRecord(params);
        }

        this.set('site', site);
      } else {
        return Ember.RSVP.resolve();
      }
    }

    unload() {
      // If current site exists, unload it
      if (this.site != null) {
        // Clear records in store so that records from other sites do not persist.
        let self = this;
        this.store.peekAll('data-category').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('data-field').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('data-item').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('data-kind').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('data-property').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('document-folder').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('document').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('image-folder').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('image').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('invitation').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.store.peekAll('part').forEach(function (object) {
          self.get('store').unloadRecord(object);
        }); // this.get('store').peekAll('permission').forEach(function(object){
        //   self.get('store').unloadRecord(object)
        // })
        // this.get('store').peekAll('role').forEach(function(object){
        //   self.get('store').unloadRecord(object)
        // })

        this.store.peekAll('screen').forEach(function (object) {
          self.get('store').unloadRecord(object);
        });
        this.set('site', null);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = CurrentSiteService;
});