define("lightway-connect/routes/sites/site/settings/contributors/roles/role/invitations/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let InvitationRoute = classic(_class = class InvitationRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('invitation', params.invitation_id);
    }

  }) || _class;

  _exports.default = InvitationRoute;
});