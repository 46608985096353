define("lightway-connect/mirage/factories/data-property", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'image-field': (0, _emberCliMirage.trait)({}),
    'document-field': (0, _emberCliMirage.trait)({}),
    'association-field': (0, _emberCliMirage.trait)({})
  });

  _exports.default = _default;
});