define("lightway-connect/components/content-editor/current-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),
    currentPreview: Ember.computed('model', 'editorService.viewport.size', 'backgroundImageReference', 'backgroundSize', 'backgroundPosition', 'backgroundRepeat', function () {
      let previewObject = {};

      if (this.backgroundImageReference) {
        previewObject['background-image-reference'] = this.backgroundImageReference;
      }

      if (this.backgroundSize) {
        previewObject['background-size'] = this.backgroundSize;
      }

      if (this.backgroundPosition) {
        previewObject['background-position'] = this.backgroundPosition;
      }

      if (this.backgroundRepeat) {
        previewObject['background-repeat'] = this.backgroundRepeat;
      }

      return previewObject;
    }),
    viewport: Ember.computed('editorService.viewport.size', function () {
      return this.editorService.viewport.size;
    }),
    overrideHash: Ember.computed('model.preferences.{small.background-image-reference,medium,large}', 'viewport', `prototypeBasedPart.preferences.{small.overrides.@each,medium.overrides.@each,large.overrides.@each}`, function () {
      let combinedHash = {};
      let smallHash = {};
      let mediumHash = {};
      let largeHash = {};

      if (this.prototypeBasedPart) {
        if (this.prototypeBasedPart.get(`preferences.small.overrides`)) {
          this.prototypeBasedPart.get(`preferences.small.overrides`).forEach(override => {
            if (override && override['part-id'] === this.model.id) {
              smallHash = override;
            }
          });
        }

        Object.keys(smallHash).forEach(key => {
          combinedHash[key] = smallHash[key];
        });

        if (this.viewport === "medium" || this.viewport === "large") {
          if (this.prototypeBasedPart.get(`preferences.medium.overrides`)) {
            this.prototypeBasedPart.get(`preferences.medium.overrides`).forEach(override => {
              if (override && override['part-id'] === this.model.id) {
                mediumHash = override;
              }
            });
          }

          Object.keys(mediumHash).forEach(key => {
            combinedHash[key] = mediumHash[key];
          });
        }

        if (this.viewport === "large") {
          if (this.prototypeBasedPart.get(`preferences.large.overrides`)) {
            this.prototypeBasedPart.get(`preferences.large.overrides`).forEach(override => {
              if (override && override['part-id'] === this.model.id) {
                largeHash = override;
              }
            });
          }

          Object.keys(largeHash).forEach(key => {
            combinedHash[key] = largeHash[key];
          });
        }
      }

      if (Object.keys(combinedHash).length > 0) {
        return combinedHash;
      }

      return null;
    }),
    backgroundImageReference: Ember.computed('model.preferences.{small.background-image-reference,medium.background-image-reference,large.background-image-reference}', 'overrideHash', 'viewport', function () {
      return this.valueFor('background-image-reference');
    }),
    backgroundSize: Ember.computed('model.preferences.{small.background-size,medium.background-size,large.background-size}', 'overrideHash', 'viewport', function () {
      return this.valueFor('background-size');
    }),
    backgroundPosition: Ember.computed('model.preferences.{small.background-position,medium.background-position,large.background-position}', 'overrideHash', 'viewport', function () {
      return this.valueFor('background-position');
    }),
    backgroundRepeat: Ember.computed('model.preferences.{small.background-repeat,medium.background-repeat,large.background-repeat}', 'overrideHash', 'viewport', function () {
      return this.valueFor('background-repeat');
    }),

    valueFor(key) {
      if (this.overrideHash) {
        if (this.overrideHash[key]) {
          return this.overrideHash[key];
        }
      }

      if (this.model.get(`preferences.${this.viewport}.${key}`)) {
        return this.model.get(`preferences.${this.viewport}.${key}`);
      } else {
        if (this.viewport === "large") {
          if (this.model.get(`preferences.medium.${key}`)) {
            return this.model.get(`preferences.medium.${key}`);
          } else if (this.model.get(`preferences.small.${key}`)) {
            return this.model.get(`preferences.small.${key}}`);
          }
        } else if (this.viewport === "medium") {
          if (this.model.get(`preferences.small.${key}`)) {
            return this.model.get(`preferences.small.${key}`);
          }
        }

        return null;
      }
    }

  });

  _exports.default = _default;
});