define("lightway-connect/routes/sites/site/data-kinds/data-kind/categories/category/categories/category/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EditRoute = classic(_class = class EditRoute extends Ember.Route {}) || _class;

  _exports.default = EditRoute;
});