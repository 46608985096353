define("lightway-connect/components/item-picker/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @selectedItem}}
    <div class="cell shrink">
      <div class="grid-x align-middle item-picker-confirm">
        <div class="cell auto selected-item-name" data-test-id="selected-item">
          {{@selectedItem.label}}
        </div>
        <div class="cell shrink">
          <button {{action @confirmAction}} class="confirm-button" data-test-id="confirm-selection">
            Confirm
          </button>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    id: "tZQgtL4O",
    block: "{\"symbols\":[\"@selectedItem\",\"@confirmAction\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x align-middle item-picker-confirm\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell auto selected-item-name\"],[10,\"data-test-id\",\"selected-item\"],[8],[0,\"\\n        \"],[1,[23,1,[\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"confirm-button\"],[12,\"data-test-id\",\"confirm-selection\"],[3,\"action\",[[23,0,[]],[23,2,[]]]],[8],[0,\"\\n          Confirm\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/item-picker/footer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});