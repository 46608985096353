define("lightway-connect/components/accounts/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    actions: {
      acceptInvitation() {
        this.invitation.save().then(() => {
          // Remove the invitation from store
          this.store.unloadRecord(this.invitation); // Reload account to find sites

          this.currentAccount.load(); // Transition and report

          this.flashMessages.clearMessages().success('Invitation accepted');
          this.router.transitionTo('index');
        }).catch(reason => {
          this.presentError(reason);
          this.router.transitionTo('index');
        });
      },

      declineInvitation() {
        this.invitation.destroyRecord().then(() => {
          this.flashMessages.clearMessages().success('Invitation declined');
          this.router.transitionTo('index');
        }).catch(reason => {
          this.presentError(reason);
          this.router.transitionTo('index');
        });
      }

    },

    presentError(reason) {
      let source = reason.errors.lastObject.source;
      let detail = reason.errors.lastObject.detail;

      if (source) {
        let paths = source.pointer.split('/');
        let attr = paths[paths.length - 1].split('_').join(' ').capitalize();
        this.flashMessages.clearMessages().alert(`${attr} ${detail}`);
      } else {
        this.flashMessages.clearMessages().alert('There was an error');
      }
    }

  });

  _exports.default = _default;
});