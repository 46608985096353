define("lightway-connect/templates/components/content-editor/range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WUJxDy2o",
    "block": "{\"symbols\":[],\"statements\":[[5,\"input\",[[12,\"class\",\"slider\"],[12,\"value\",[28,\"get\",[[23,0,[\"model\"]],[23,0,[\"propertyPath\"]]],null]],[12,\"oninput\",[28,\"action\",[[23,0,[]],[23,0,[\"updateProperty\"]]],[[\"value\"],[\"target.value\"]]]],[12,\"data-test-range-id\",[23,0,[\"propertyPath\"]]]],[[\"@type\",\"@min\",\"@max\"],[\"range\",[23,0,[\"min\"]],[23,0,[\"max\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/range-input.hbs"
    }
  });

  _exports.default = _default;
});