define("lightway-connect/components/if-translation-belongs-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      this.checkIfParentMatches();
    },

    checkIfParentMatches() {
      this.set('parent-matches', false);

      if (this.get('translation.parent') != null && this.targetString != null) {
        if (this.get('translation.parent') == this.model) {
          if (this.get('translation.targetString') == this.targetString.underscore()) {
            this.set('parent-matches', true);
          }
        }
      }
    }

  });

  _exports.default = _default;
});