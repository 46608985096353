define("lightway-connect/components/content-editor/properties/data-kind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    classNames: ['current-canvas-settings'],
    selectingWebFontDocument: false,
    actions: {
      newColorClass() {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        let i = globalStyle.get('styleProperties.color-class-ids').length + 1;
        let newColorClassId = `color-class-${i}`;
        let newColorClass = {
          id: newColorClassId,
          name: `Color Class ${i}`,
          type: "custom",
          value: "#CCCCCC"
        }; // Collect existing color class ids

        let ids = Ember.A([]);
        globalStyle.get('styleProperties.color-class-ids').forEach(id => {
          ids.addObject(id);
        });
        ids.addObject(newColorClassId); // Collect existing color classes

        let colorClasses = Ember.A([]);
        globalStyle.get('styleProperties.color-classes').forEach(color => {
          colorClasses.addObject(color);
        });
        colorClasses.addObject(newColorClass);
        let changes = {};
        changes[`styleProperties.color-class-ids`] = ids;
        changes[`styleProperties.color-classes`] = colorClasses;
        let editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      newGlobalFont() {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        let i = globalStyle.globalFonts.length + 1;
        let newGF = {
          value: "Arial",
          category: "sans-serif",
          "webfont-reference": null
        };
        let changes = {};
        changes[`styleProperties.gf${i}`] = newGF;
        globalStyle.gFontsToWatch.pushObject(`gf${i}`);
        let editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      toggleModalForWebFontDocument() {
        this.set('selectingWebFontDocument', !this.get('selectingWebFontDocument'));
      },

      cancelWebFontDocumentSelection() {
        this.set('selectingWebFontDocument', false);
      },

      selectWebFontDocument(path, document) {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        let changes = {};
        changes[path] = document.id;
        let editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingWebFontDocument', false);
      },

      removeWebFontDocument(path) {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        let changes = {};
        changes[path] = null;
        let editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      selectDocument(document, property) {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        globalStyle.set(property, document.id);
      },

      removeDocument(property) {
        let globalStyle = this.editorService.canvasVersion.globalStyle;
        globalStyle.set(property, null);
      }

    }
  });

  _exports.default = _default;
});