define("lightway-connect/components/content-editor/panels/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function backgroundImageReference(model, overrideBackground, viewport, store) {
    return Ember.computed('background-image-reference', 'overrideBackground', `model.preferences.${viewport}.background-image-reference`, `model.preferences.{small.overrides.@each,medium.overrides.@each,large.overrides.@each}`, function () {
      let imageId = model.get(`preferences.${viewport}.background-image-reference`);

      if (imageId) {
        return store.peekRecord('image', imageId);
      } else {
        return null;
      }
    });
  }

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    isShowingModalForBackgroundImage: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'nativeBackgroundImage', backgroundImageReference(this.model, this.overrideBackground, this.editorService.viewport.size, this.store));
    },

    overrideHash: Ember.computed('model.preferences.{small.overrides.@each,medium.overrides.@each,large.overrides.@each},editorService.viewport.size', function () {
      if (!this.model.basePrototype) {
        return null;
      }

      if (!this.model.get(`preferences.${this.editorService.viewport.size}.overrides`)) {
        this.model.set(`preferences.${this.editorService.viewport.size}.overrides`, Ember.A([]));
      }

      let ovr;
      this.model.get(`preferences.${this.editorService.viewport.size}.overrides`).forEach(override => {
        if (override['part-id'] === this.overridable['part-id']) {
          ovr = override;
        }
      });

      if (!ovr) {
        ovr = {
          'part-id': this.overridable['part-id']
        };
        this.model.get(`preferences.${this.editorService.viewport.size}.overrides`).addObject(ovr);
      }

      return ovr;
    }),
    currentBackgroundImage: Ember.computed('nativeBackgroundImage', 'overrideHash', 'overrideBackgroundImage.id', 'editorService.viewport.size', function () {
      if (this.overrideBackgroundImage) {
        return this.overrideBackgroundImage;
      }

      return this.nativeBackgroundImage;
    }),
    overrideBackgroundImage: Ember.computed('overrideHash', 'overrideHash.background-image-reference', function () {
      if (!this.overrideHash) {
        return null;
      }

      let imageId = this.overrideHash['background-image-reference'];

      if (imageId) {
        return this.store.peekRecord('image', imageId);
      } else {
        return null;
      }
    }),
    actions: {
      toggleModalForBackgroundImage() {
        this.toggleProperty('isShowingModalForBackgroundImage');
      },

      selectBackgroundImage(image) {
        let edit;
        let changes = {};

        if (this.overrideHash) {
          let newOverrides = Ember.A([]);

          if (!this.model.get(`preferences.${this.editorService.viewport.size}.overrides`)) {
            this.model.set(`preferences.${this.editorService.viewport.size}.overrides`, Ember.A([]));
          }

          this.model.get(`preferences.${this.editorService.viewport.size}.overrides`).forEach(override => {
            if (Ember.get(override, 'part-id') === `${this.overrideHash['part-id']}`) {
              let newOverride = {};
              Object.keys(override).forEach(key => {
                newOverride[key] = override[key];
              });
              newOverride['background-image-reference'] = image.id;
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes[`preferences.${this.editorService.viewport.size}.overrides`] = newOverrides;
        } else {
          changes[`preferences.${this.editorService.viewport.size}.background-image-reference`] = image.id;
        }

        edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.toggleProperty('isShowingModalForBackgroundImage');
      },

      removeBackgroundImage() {
        let edit;
        let changes = {};

        if (this.overrideHash) {
          let newOverrides = Ember.A([]);
          this.model.get(`preferences.${this.editorService.viewport.size}.overrides`).forEach(override => {
            if (Ember.get(override, 'part-id') === `${this.overrideHash['part-id']}`) {
              let newOverride = {};
              Object.keys(override).forEach(key => {
                if (key != "background-image-reference") {
                  newOverride[key] = override[key];
                }
              });
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes[`preferences.${this.editorService.viewport.size}.overrides`] = newOverrides;
          edit = this.editorService.prepareEdit(this.model, changes);
        } else {
          changes[`preferences.${this.editorService.viewport.size}.background-image-reference`] = null;
          edit = this.editorService.prepareEdit(this.model, changes);
        }

        this.editorService.addModifications(Ember.A([edit]));
      },

      toggleOverridable(value, event) {
        let checked = event.toElement.checked;

        if (checked) {
          if (!this.modelObject.preferences.overridable) {
            this.modelObject.set('preferences.overridable', Ember.A([]));
          }

          this.modelObject.preferences.overridable.addObject(value);
        } else {
          this.modelObject.preferences.overridable.removeObject(value);
        }
      }

    }
  });

  _exports.default = _default;
});