define("lightway-connect/routes/sites/site/design/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDesignIndexRoute extends Ember.Route {
    beforeModel() {
      this.transitionTo('sites.site.design.layouts');
    }

  }

  _exports.default = SitesSiteDesignIndexRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDesignIndexRoute, true);
});