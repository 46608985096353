define("lightway-connect/routes/sites/site/settings/user-kinds/user-kind/plans/plan/offers/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsUserKindsUserKindPlansPlanOffersOfferRoute extends Ember.Route {
    async model(params) {
      return await this.store.findRecord('product-offer', params.offer_id);
    }

  }

  _exports.default = SitesSiteSettingsUserKindsUserKindPlansPlanOffersOfferRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsUserKindsUserKindPlansPlanOffersOfferRoute, true);
});