define("lightway-connect/routes/sites/site/settings/mailing-lists/mailing-list/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let FieldsRoute = classic(_class = class FieldsRoute extends Ember.Route {}) || _class;

  _exports.default = FieldsRoute;
});