define("lightway-connect/controllers/auth/instagram", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let InstagramController = (_dec = Ember.inject.service, classic(_class = (_class2 = (_temp = class InstagramController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "queryParams", ['code', 'state', 'error', 'error_description']);

      _defineProperty(this, "code", null);

      _defineProperty(this, "state", null);

      _defineProperty(this, "error", null);

      _defineProperty(this, "error_description", null);
    }

    prepareCodeForSending() {
      let lastSite;

      if (this.state) {
        lastSite = this.state;
      }

      if (this.error) {
        this.flashMessages.clearMessages().alert(`Error: ${this.error_description}`);
        this.transitionToRoute('sites.site.settings.social-accounts', lastSite);
      } else {
        if (this.code) {
          let {
            token
          } = this.get('session.data.authenticated');

          if (this.get('session.isAuthenticated') && token) {
            let authorizationToken = `Bearer ${token}`;
            this.sendCodeToAPI(authorizationToken, lastSite);
          } else {
            this.flashMessages.clearMessages().alert('There was an error');
            this.transitionToRoute('sites.site.settings.social-accounts', lastSite);
          }
        }
      }
    }

    sendCodeToAPI(authorizationToken, lastSiteId) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)('/api/backend/social_accounts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            accept: 'application/json;version=1',
            Authorization: authorizationToken
          },
          body: JSON.stringify({
            "data": {
              "type": "social-accounts",
              "attributes": {
                "kind": "instagram",
                "code": this.code
              }
            },
            "filter": {
              "site": lastSiteId
            }
          })
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.flashMessages.clearMessages().success('Added Instagram account'); // Redirect to site/settings/social-accounts

              this.transitionToRoute('sites.site.settings.social-accounts', lastSiteId);
              return resolve();
            });
          } else {
            Ember.run(() => {
              this.flashMessages.clearMessages().alert('There was an error');
              this.transitionToRoute('sites.site.settings.social-accounts', lastSiteId);
              return reject();
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            return reject();
          });
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = InstagramController;
});