define("lightway-connect/components/sites/parts/add-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedElement: Ember.inject.service(),
    // Disable wrapper div
    tagName: '',
    actions: {
      addContainer(pnt) {
        let totalParts = pnt.get('parts.length');
        let container = this.store.createRecord('part', {
          kind: 'container',
          parent: pnt,
          rowOrder: totalParts + 1
        });
        this.selectedElement.select(container);
      }

    }
  });

  _exports.default = _default;
});