define("lightway-connect/controllers/sites/site/user-kinds/user-kind/user/associates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SitesSiteUserKindsUserKindUserAssociatesIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page']);

      _defineProperty(this, "page", 1);
    }

  }

  _exports.default = SitesSiteUserKindsUserKindUserAssociatesIndexController;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteUserKindsUserKindUserAssociatesIndexController, true);
});