define("lightway-connect/components/form-button-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      removeAction() {
        let actions = Ember.get(this.button, 'actions');
        actions.removeObject(this.action);
      }

    }
  });

  _exports.default = _default;
});