define("lightway-connect/components/sites/parts/image-part/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c/nzcxM+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"image-part \",[28,\"if\",[[24,[\"elementSelected\"]],\"selected-part\"],null],\" part\"]]],[12,\"data-test-image-part-for\",[24,[\"part\",\"id\"]]],[3,\"action\",[[23,0,[]],\"editImage\",[24,[\"part\"]]]],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[24,[\"part\",\"kontent\",\"imageUrl\"]]],[11,\"alt\",[24,[\"part\",\"kontent\",\"name\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/image-part/template.hbs"
    }
  });

  _exports.default = _default;
});