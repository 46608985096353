define("lightway-connect/mirage/factories/screen", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Screen ${i}`;
    },

    identifier(i) {
      return `screen-${i}`;
    },

    description: 'Screen description',
    enabled: true,
    disabled: (0, _emberCliMirage.trait)({
      enabled: false
    })
  });

  _exports.default = _default;
});