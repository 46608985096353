define("lightway-connect/templates/sites/site/web-forms/web-form/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GikgZibn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"Delete Form\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n    Are you sure you want to delete this Form? All associated data will be permanently lost.\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"data-test-delete-button\",\"\"],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"destroyForm\"]],[8],[0,\"\\n      Delete Form\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/web-forms/web-form/delete.hbs"
    }
  });

  _exports.default = _default;
});