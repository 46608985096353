define("lightway-connect/components/content-editor/resource-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiForm as |Form|>
    <Form.field as |Field|>
  
      <Field.resourceInput
        @model={{@model}}
        @label={{@label}}
        @resource={{associate.owner}}
        @subjectKind={{@subjectKind}}
        @property={{@property}}
        @associate={{associate}}
        @associateProperty={{@associateProperty}}
        @selectAction={{@selectAction}}
        @removeAction={{this.removeAssociate}}
        @value={{get @model @property}}
        @id=this.uniqueId
        data-test-input-id=@property />
  
    </Form.field>
  </UiForm>
  
  {{yield}}
  */
  {
    id: "DV/O0/TM",
    block: "{\"symbols\":[\"Form\",\"Field\",\"@model\",\"@label\",\"@subjectKind\",\"@property\",\"@associateProperty\",\"@selectAction\",\"&default\"],\"statements\":[[5,\"ui-form\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"field\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,2,[\"resourceInput\"]],[[12,\"data-test-input-id\",\"@property\"]],[[\"@model\",\"@label\",\"@resource\",\"@subjectKind\",\"@property\",\"@associate\",\"@associateProperty\",\"@selectAction\",\"@removeAction\",\"@value\",\"@id\"],[[23,3,[]],[23,4,[]],[24,[\"associate\",\"owner\"]],[23,5,[]],[23,6,[]],[22,\"associate\"],[23,7,[]],[23,8,[]],[23,0,[\"removeAssociate\"]],[28,\"get\",[[23,3,[]],[23,6,[]]],null],\"this.uniqueId\"]]],[0,\"\\n\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/resource-input.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});