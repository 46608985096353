define("lightway-connect/routes/sites/site/settings/data-kinds/data-kind/default-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DefaultContentRoute = classic(_class = class DefaultContentRoute extends Ember.Route {}) || _class;

  _exports.default = DefaultContentRoute;
});