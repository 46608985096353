define("lightway-connect/components/item-picker/item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.args.selectedItem this.args.item)}}
    <div role="button"
      {{action this.args.deselectAction}}
      class="item-picker-item selected-item"
      data-test-id="{{this.args.item.id}}-item">
  
      {{yield}}
  
    </div>
  {{else}}
    <div role="button"
      {{action this.args.selectAction this.args.item}}
      class="item-picker-item"
      data-test-id="{{this.args.item.id}}-item">
  
      {{yield}}
  
    </div>
  {{/if}}
  */
  {
    id: "WwP7v6FE",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"args\",\"selectedItem\"]],[23,0,[\"args\",\"item\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"item-picker-item selected-item\"],[12,\"data-test-id\",[29,[[23,0,[\"args\",\"item\",\"id\"]],\"-item\"]]],[3,\"action\",[[23,0,[]],[23,0,[\"args\",\"deselectAction\"]]]],[8],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"item-picker-item\"],[12,\"data-test-id\",[29,[[23,0,[\"args\",\"item\",\"id\"]],\"-item\"]]],[3,\"action\",[[23,0,[]],[23,0,[\"args\",\"selectAction\"]],[23,0,[\"args\",\"item\"]]]],[8],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/item-picker/item.hbs"
    }
  });

  class ItemPickerItemComponent extends _component.default {}

  _exports.default = ItemPickerItemComponent;

  window.__CLASSIC_OWN_CLASSES__.set(ItemPickerItemComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ItemPickerItemComponent);
});