define("lightway-connect/components/tags-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),

    existingTag(tag) {
      let result = null;
      this.store.peekAll('data-tag').forEach(dataTag => {
        if (dataTag.value == tag) {
          result = dataTag;
        }
      });
      return result;
    },

    actions: {
      addTag(value) {
        // 1. Check if tag already exists
        // 2. If it doesn't, create and save
        // 3. Associate with model
        let dataTag = this.existingTag(value);

        if (!dataTag) {
          dataTag = this.store.createRecord('data-tag', {
            value: value,
            site: this.currentSite.site
          });

          if (this.addAction) {
            this.addAction(dataTag);
          } else {
            dataTag.save().then(() => {
              this.model.dataTags.pushObject(dataTag);
            });
          }
        } else {
          if (this.addAction) {
            this.addAction(dataTag);
          } else {
            this.model.dataTags.pushObject(dataTag);
          }
        }
      },

      removeTagAtIndex(index) {
        if (this.removeAction) {
          this.removeAction(index);
        } else {
          this.model.dataTags.removeAt(index);
        }
      }

    }
  });

  _exports.default = _default;
});