define("lightway-connect/mirage/factories/order", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    status: 'pending',
    subtotalCents: 10050,
    subtotalCurrency: "AUD",
    taxCents: 1005,
    taxCurrency: "AUD",
    totalCents: 11055,
    totalCurrency: "AUD",
    customerName: "Foo Bar",
    customerEmail: "foo@bar.com",
    createdAt: "2020-11-06T01:26:56.792Z"
  });

  _exports.default = _default;
});