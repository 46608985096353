define("lightway-connect/mirage/utils/generate-translations-for", ["exports", "lightway-connect/mirage/utils/generate-random-id"], function (_exports, _generateRandomId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(item, db, data) {
    data.forEach(function (translationData) {
      let existingTranslation = translationData.id;

      if (existingTranslation) {
        let translation = db.translations.find(translationData.id);

        if (translationData.attributes && translationData.attributes.locale) {
          translation.locale = translationData.attributes.locale;
        }

        if (translationData.attributes && translationData.attributes['string-value']) {
          translation.stringValue = translationData.attributes['string-value'];
          translation.save();
        }
      } else {
        let id = (0, _generateRandomId.default)(); // Create new translation

        db.translations.create({
          id: id,
          locale: translationData.attributes.locale,
          stringValue: translationData.attributes['string-value'],
          targetString: translationData.attributes['target-string'],
          parent: item
        });
      }
    });
  }
});