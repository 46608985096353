define("lightway-connect/controllers/reset-password/index", ["exports", "lightway-connect/validations/accounts/request-reset-password-form"], function (_exports, _requestResetPasswordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResetPasswordIndexController = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class ResetPasswordIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "requestResetPasswordValidations", _requestResetPasswordForm.default);

      _defineProperty(this, "formIsHidden", false);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);
    }

    requestResetPassword(changesetObj) {
      changesetObj.validate().then(() => {
        if (changesetObj.get('isValid')) {
          return new Ember.RSVP.Promise((resolve, reject) => {
            return fetch(`/api/backend/accounts/password`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify({
                "email": changesetObj.get('email')
              })
            }).then(response => {
              if (response.ok) {
                Ember.run(() => {
                  this.set('formIsHidden', true);
                  this.flashMessages.clearMessages();
                  resolve();
                });
              } else {
                Ember.run(() => {
                  this.flashMessages.clearMessages().alert('There was an error');
                  this.addErrorsToChangeset(response);
                  this.set('formIsHidden', false);
                  reject();
                });
              }
            });
          });
        } else {
          this.flashMessages.clearMessages().alert('There was an error');
          return;
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "requestResetPassword", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "requestResetPassword"), _class.prototype)), _class));
  _exports.default = ResetPasswordIndexController;
});