define("lightway-connect/components/content-editor/parts/region-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    regionBelongsWithinLayoutVersionAndInLayoutMode: Ember.computed('part.baseParent', 'editorService.model', function () {
      return this.part.baseParent.isA('layout-version') && this.editorService.model.isA('layout');
    }),
    regionBelongsWithinMailTemplateVersionAndInMailTemplateMode: Ember.computed('part.baseParent', 'editorService.model', function () {
      return this.part.baseParent.isA('mail-template-version') && this.editorService.model.isA('mail-template');
    }),
    itemRegion: Ember.computed('part', 'part.{itemRegions.@each,itemRegionsAsRegion.id}', 'editorService.{canvasVersion,canvasVersion.itemRegions}', 'prototypeBasedPart.itemRegions', {
      // itemRegion's item is either a:
      // page-version
      // prototype-based-part
      // data-item
      get() {
        if (this._itemRegion) {
          return this._itemRegion;
        }

        let item;
        let itemRegion;

        if (this.part.baseParent.isA('layout-version')) {
          item = this.editorService.canvasVersion; // page-version
        }

        if (this.part.baseParent.isA('mail-template-version')) {
          item = this.editorService.canvasVersion; // mail-message
        }

        if (this.part.baseParent.isA('prototype')) {
          item = this.prototypeBasedPart;
        }

        this.store.peekAll('item-region').forEach(iR => {
          if (iR.item && iR.region && iR.item === item && iR.region === this.part) {
            itemRegion = iR;
          }
        });
        return itemRegion;
      },

      set(key, value) {
        return this._itemRegion = value;
      }

    })
  });

  _exports.default = _default;
});