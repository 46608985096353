define("lightway-connect/routes/error404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Error404Route = classic(_class = class Error404Route extends Ember.Route {}) || _class;

  _exports.default = Error404Route;
});