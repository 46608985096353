define("lightway-connect/routes/sites/site/settings/payment-gateways/payment-gateway", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsPaymentGatewaysPaymentGatewayRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('payment-gateway', params.payment_gateway_id, {
        reload: true
      });
    }

  }

  _exports.default = SitesSiteSettingsPaymentGatewaysPaymentGatewayRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsPaymentGatewaysPaymentGatewayRoute, true);
});