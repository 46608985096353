define("lightway-connect/components/editor/save-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell small-12 save-bar" ...attributes>
    <div class="grid-x" data-test-id="editor-buttons">
      <div role="button" {{action @saveAction @model}} class="cell auto save-bar-option editor-save" data-test-button-id="save">
        Save
      </div>
  
      {{yield}}
    </div>
  </div>
  
  */
  {
    id: "VjMk0ZGM",
    block: "{\"symbols\":[\"&attrs\",\"@model\",\"@saveAction\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"cell small-12 save-bar\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[10,\"data-test-id\",\"editor-buttons\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell auto save-bar-option editor-save\"],[12,\"data-test-button-id\",\"save\"],[3,\"action\",[[23,0,[]],[23,3,[]],[23,2,[]]]],[8],[0,\"\\n      Save\\n    \"],[9],[0,\"\\n\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/save-bar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});