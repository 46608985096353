define("lightway-connect/routes/sites/site/data-kinds/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDataKindsInvoicesRoute extends Ember.Route {}

  _exports.default = SitesSiteDataKindsInvoicesRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDataKindsInvoicesRoute, true);
});