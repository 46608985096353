define("lightway-connect/components/roles/role-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissions: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.checkForExistingPermission();
    },

    checkForExistingPermission() {
      const self = this;

      if (this.permissionIsPermitted() == true) {
        this.set('permissionPermitted', true); // Set to false by default

        self.set('permissionExists', false); // If a matching permission exists, change to true

        this.role.get('permissions').forEach(function (permission) {
          if (permission.get('kind') == self.kind) {
            self.replaceEnableButtonWithDisable();
          }
        });
      } else {
        self.replaceButtonWithListItem();
      }
    },

    permissionIsPermitted() {
      return this.permissions.accountPermissionsInclude([this.kind]);
    },

    replaceEnableButtonWithDisable() {
      this.set('permissionExists', true);
    },

    replaceDisableButtonWithEnable() {
      this.set('permissionExists', false);
    },

    replaceButtonWithListItem() {
      this.set('permissionPermitted', false);
    },

    actions: {
      enablePermission() {
        const self = this; // create permission record

        var permission = this.store.createRecord('permission', {
          role: this.role,
          kind: this.kind
        });
        permission.save().then(() => {
          self.replaceEnableButtonWithDisable();
          return;
        }).catch(() => {
          this.flashMessages.clearMessages().alert('There was an error');
          this.store.unloadRecord(permission);
          return;
        });
      },

      disablePermission() {
        const self = this;
        self.role.get('permissions').forEach(function (p) {
          if (p.get('kind') == self.kind) {
            // Destroy permission
            p.destroyRecord();
            self.replaceDisableButtonWithEnable();
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});