define("lightway-connect/components/checkbox-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classForPropertyName: Ember.computed(function () {
      if (this.propertyName == 'label') {
        return 'field-label';
      } else {
        return this.propertyName;
      }
    }),
    inputId: Ember.computed({
      get() {
        return Ember.guidFor(this) + '-inpudid';
      }

    }),
    withErrorIfError: Ember.computed('changeset.errors', function () {
      var hasError;

      if (this.changeset) {
        if (this.changeset.errors.length > 0) {
          this.changeset.errors.forEach(error => {
            if (error.key == this.propertyName || error.key == this.propertyName.dasherize()) {
              hasError = true;
            }
          });
        }
      }

      if (hasError) {
        return "with-error";
      }

      return null;
    })
  });

  _exports.default = _default;
});