define("lightway-connect/templates/sites/site/settings/contributors/roles/role/contributors/contributor/remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TTDqLuhd",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"Remove Contributor\"],[9],[0,\"\\n\\nAre you sure you want to remove contributor \"],[1,[23,1,[\"firstName\"]],false],[0,\" \"],[1,[23,1,[\"lastName\"]],false],[0,\"?\\n\\n\"],[7,\"button\",false],[12,\"data-test-delete-button\",\"\"],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"removeContributor\"]],[8],[0,\"\\n  Remove Contributor\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/contributors/roles/role/contributors/contributor/remove.hbs"
    }
  });

  _exports.default = _default;
});