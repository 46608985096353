define("lightway-connect/components/content-editor/remove-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    isShowingModal: false,

    confirmPartRemovalAction() {
      let remove = this.editorService.prepareRemove(this.editorService.selection);
      this.editorService.addModifications(remove);
      this.hideModal();
    },

    hideModal() {
      this.set('isShowingModal', false);
    },

    actions: {
      removePart() {
        this.set('isShowingModal', true);
      },

      confirmPartRemovalAction() {
        this.confirmPartRemovalAction();
      },

      hideModal() {
        this.hideModal();
      }

    }
  });

  _exports.default = _default;
});