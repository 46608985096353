define("lightway-connect/components/sites/media/upload-document-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      let {
        token
      } = this.get('session.data.authenticated');

      if (this.get('session.isAuthenticated') && token) {
        let headers = {
          'Authorization': `Bearer ${token}`
        }; // Upload request

        yield file.upload({
          url: '/api/backend/documents',
          headers: headers,
          accepts: 'application/json;version=1',
          data: {
            "document-folder-id": this.folder.id
          },
          fileKey: 'document-file'
        }).then(response => {
          this.store.pushPayload(response.body); // Clear flash messages in case of multiple alerts

          this.flashMessages.clearMessages().success("Document uploaded");
        }, () => {
          //(failure)
          this.flashMessages.clearMessages().alert("There was an error");
        });
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadDocument(file) {
        this.uploadFile.perform(file);
      }

    }
  });

  _exports.default = _default;
});