define("lightway-connect/components/sites/parts/nested-part/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AD9sbuW/",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"if\",[[24,[\"part\",\"name\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"screen-parts\"],[10,\"data-test-parts-for\",\"screen\"],[8],[0,\"\\n\\n\"],[4,\"drag-sort-list\",null,[[\"items\",\"group\",\"dragEndAction\"],[[24,[\"part\",\"partsSortedBound\"]],\"nested group\",[28,\"action\",[[23,0,[]],\"dragEnd\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"sites/parts/nested-part\",null,[[\"part\",\"dragEndAction\"],[[23,1,[]],[24,[\"dragEndAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"component\",[[28,\"concat\",[\"sites/parts/\",[24,[\"part\",\"kind\"]],\"-part\"],null]],[[\"part\"],[[24,[\"part\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/nested-part/template.hbs"
    }
  });

  _exports.default = _default;
});