define("lightway-connect/routes/sites/site/settings/user-kinds/user-kind/plans/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsUserKindsUserKindPlansPlanRoute extends Ember.Route {
    async model(params) {
      return await this.store.findRecord('user-plan', params.plan_id);
    }

  }

  _exports.default = SitesSiteSettingsUserKindsUserKindPlansPlanRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsUserKindsUserKindPlansPlanRoute, true);
});