define("lightway-connect/controllers/sites/site/design/global-palette", ["exports", "ember-uuid", "lightway-connect/validations/global-palette"], function (_exports, _emberUuid, _globalPalette) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteDesignGlobalPaletteController = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class SitesSiteDesignGlobalPaletteController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "GlobalPaletteValidations", _globalPalette.default);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);
    }

    addColor(changeset) {
      let uuid = (0, _emberUuid.v4)();
      let newColor = {
        "id": uuid,
        "name": "",
        "value": ""
      };
      Ember.get(changeset, 'styleProperties.color-ids').addObject(uuid);
      Ember.get(changeset, 'styleProperties.colors').addObject(newColor);
    }

    async save(changeset) {
      try {
        await changeset.validate();

        if (changeset.isValid) {
          try {
            await changeset.save();
            this.flashMessages.clearMessages().success("Global Palette updated");
          } catch (reason) {
            this.formErrors.addErrors(reason, changeset);
            this.flashMessages.clearMessages().alert("There was an error");
          }
        } else {
          this.flashMessages.clearMessages().alert("There was an error");
        }
      } catch (reason) {
        this.flashMessages.clearMessages().alert("There was an error");
      }
    }

    removeColor(changeset, colorIndex) {
      changeset.get('styleProperties.color-ids').removeAt(colorIndex);
      changeset.get('styleProperties.colors').removeAt(colorIndex);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addColor", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeColor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeColor"), _class.prototype)), _class));
  _exports.default = SitesSiteDesignGlobalPaletteController;
});