define("lightway-connect/controllers/sites/site/web-forms/web-form/fields/field/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      destroyField(field) {
        this.target.send('destroyField', field);
      }

    }
  });

  _exports.default = _default;
});