define("lightway-connect/routes/sites/site/data-kinds/mail-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDataKindsMailLogsRoute extends Ember.Route {}

  _exports.default = SitesSiteDataKindsMailLogsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDataKindsMailLogsRoute, true);
});