define("lightway-connect/templates/components/content-editor/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HQaaYoPr",
    "block": "{\"symbols\":[\"Box\",\"@model\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"data-test-id\",[29,[[23,0,[\"propertyPath\"]],\"-label\"]]],[11,\"for\",[23,0,[\"inputId\"]]],[10,\"class\",\"cell small-10\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"ui-form/select-box\",[[12,\"data-test-id\",[23,0,[\"propertyPath\"]]]],[[\"@model\",\"@property\",\"@selectAction\"],[[28,\"if\",[[23,0,[\"overrideHash\"]],[23,0,[\"overrideHash\"]],[23,2,[]]],null],[23,0,[\"propertyPath\"]],[28,\"action\",[[23,0,[]],\"selectAction\"],null]]],{\"statements\":[[0,\"\\n\\n  \"],[14,3,[[23,1,[]]]],[0,\"\\n\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/select-box.hbs"
    }
  });

  _exports.default = _default;
});