define("lightway-connect/mirage/serializers/part", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    include: ['parent', 'parts', 'itemRegions', 'itemRegionsAsRegion', 'inheritedParts', 'hoverItems', 'content', 'basePrototype', 'pageLink', 'parts.pageLink', 'linkedImages', 'linkedDocuments', 'linkedPages']
  });

  _exports.default = _default;
});