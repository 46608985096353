define("lightway-connect/components/model-object", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.modelObject}}
  */
  {
    id: "4xU5db7g",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[\"modelObject\"]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/model-object.hbs"
    }
  });

  let ModelObjectComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = (_temp = class ModelObjectComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modelObject", _descriptor2, this);

      this._lookupMatch();
    }

    async _lookupMatch() {
      let type = this.args.type;
      let id = this.args.id;

      if (!id) {
        id = this.args.identifier;
      }

      if (!id) {
        return;
      }

      let existingMatch = this.store.peekRecord(type, id);

      if (existingMatch) {
        this.modelObject = existingMatch;
      } else {
        let allModels = this.store.peekAll(type);
        allModels.forEach(mod => {
          if (mod.identifier === id) {
            this.modelObject = mod;
          }
        });

        if (!this.modelObject) {
          this.modelObject = await this.store.findRecord(type, id);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelObject", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = ModelObjectComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModelObjectComponent);
});