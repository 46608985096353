define("lightway-connect/controllers/sites/site/data-kinds/data-kind/items/new", ["exports", "lightway-connect/validations/data-item-form"], function (_exports, _dataItemForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, classic(_class = (_class2 = (_temp = class NewController extends Ember.Controller.extend(_dataItemForm.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "formErrors", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "associatesToSave", _descriptor4, this);
    }

    generatePermalink() {
      let permalink;
      let defaultField = this.model.dataKind.defaultField;
      let name = this.model.dataItem.get('preferences.' + defaultField.identifier);
      permalink = name.replace("'", "").replace(/[_\W]+/g, "-").dasherize();
      this.model.dataItem.set('permalink', permalink);
    }

    async save(changeset) {
      this.generatePermalink();

      try {
        await changeset.validate();

        if (changeset.isValid) {
          try {
            let result = await changeset.save(); // Save changes to associations

            for (const associate of this.associatesToSave) {
              associate.owner = result;
              await associate.save();
            }

            this.flashMessages.clearMessages().success('Item created');
            this.transitionToRoute('sites.site.data-kinds.data-kind');
          } catch (reason) {
            this.formErrors.addErrors(reason, changeset);
            this.flashMessages.clearMessages().alert("There was an error");
          }
        } else {
          this.flashMessages.clearMessages().alert("There was an error");
        }
      } catch (reason) {// console.log(reason)
      }
    }

    removeAssociate(associate, associateProperty) {
      let index = this.associatesToSave.indexOf(associate);

      if (index > -1) {
        // only splice array when item is found
        this.associatesToSave.splice(index, 1); // 2nd parameter means remove one item only
      }

      Ember.set(associate, associateProperty, null);
    }

    selectResource(resource, associate, associateProperty) {
      Ember.set(associate, associateProperty, resource); // Mark associate for saving

      this.associatesToSave.addObject(associate);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "associatesToSave", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeAssociate", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "removeAssociate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectResource", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "selectResource"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewController;
});