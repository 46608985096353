define("lightway-connect/components/ui-form/datetime-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiForm::Input
    type="datetime-local"
    @value={{this.finalValue}}
    onChange={{action 'updateValue' value="target.value"}}
    data-test-input-id="{{this.args.property}}" />
  
  <span class="timezone" data-test-id="timezone-description">
    In your timezone: <i>{{this.timeZoneDescription}}</i>
  </span>
  
  */
  {
    id: "YLHtAb2v",
    block: "{\"symbols\":[],\"statements\":[[5,\"ui-form/input\",[[12,\"onChange\",[28,\"action\",[[23,0,[]],\"updateValue\"],[[\"value\"],[\"target.value\"]]]],[12,\"data-test-input-id\",[29,[[23,0,[\"args\",\"property\"]]]]],[12,\"type\",\"datetime-local\"]],[[\"@value\"],[[23,0,[\"finalValue\"]]]]],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"timezone\"],[10,\"data-test-id\",\"timezone-description\"],[8],[0,\"\\n  In your timezone: \"],[7,\"i\",true],[8],[1,[23,0,[\"timeZoneDescription\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/ui-form/datetime-input.hbs"
    }
  });

  let UiFormDatetimeInputComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class UiFormDatetimeInputComponent extends _component.default {
    get timeZoneDescription() {
      const today = new Date();
      const short = today.toLocaleDateString(undefined);
      const full = today.toLocaleDateString(undefined, {
        timeZoneName: 'long'
      }); // Try to remove date from the string in a locale-agnostic way

      const shortIndex = full.indexOf(short);

      if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length); // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides

        return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
      } else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return full;
      }
    }

    constructor() {
      super(...arguments); // Reference:
      // https://stackoverflow.com/questions/30166338/setting-value-of-datetime-local-from-date

      _initializerDefineProperty(this, "finalValue", _descriptor, this);

      if (this.args.value) {
        let localDate = new Date(this.args.value);
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        this.finalValue = localDate.toISOString().slice(0, 16);
      }
    }

    updateValue(newValue) {
      let newDate = new Date(newValue); // Convert to UTC

      let isoString = newDate.toISOString(); // Update model value

      Ember.set(this.args.model, this.args.property, isoString); // Update final value

      this.finalValue = newValue;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finalValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = UiFormDatetimeInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiFormDatetimeInputComponent);
});