define("lightway-connect/routes/sites/site/data-kinds/user-plan-subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDataKindsUserPlanSubscriptionsRoute extends Ember.Route {}

  _exports.default = SitesSiteDataKindsUserPlanSubscriptionsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDataKindsUserPlanSubscriptionsRoute, true);
});