define("lightway-connect/components/content-editor/parts/badge-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="part-content p{{@part.basePrototype.partsSortedBound.firstObject.identifier}}">
  
    <ContentEditor::DraggablePartsList
      @part={{@part.basePrototype.partsSortedBound.firstObject}}
      @items={{@part.basePrototype.partsSortedBound.firstObject.partsSortedBound}}
      @group={{this.listGroup}}
      @draggingEnabled={{false}}
      @prototypeBasedPart={{@part}}
      @selectPartAction={{this.args.selectPartAction}}
      @dropPartAction={{this.args.dropPartAction}} />
  
  </div>
  
  {{yield}}
  
  */
  {
    id: "1ZSkTgQZ",
    block: "{\"symbols\":[\"@part\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,1,[\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"identifier\"]]]]],[8],[0,\"\\n\\n  \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@group\",\"@draggingEnabled\",\"@prototypeBasedPart\",\"@selectPartAction\",\"@dropPartAction\"],[[23,1,[\"basePrototype\",\"partsSortedBound\",\"firstObject\"]],[23,1,[\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"partsSortedBound\"]],[23,0,[\"listGroup\"]],false,[23,1,[]],[23,0,[\"args\",\"selectPartAction\"]],[23,0,[\"args\",\"dropPartAction\"]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/badge-part.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});