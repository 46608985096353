define("lightway-connect/components/editor/secondary-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell medium-cell-block-y editor-secondary-panel content-editor-side-bar" ...attributes>
    {{yield (hash
      Header=(component "editor/panel-header")
      Body=(component "editor/panel-body")
    )}}
  </div>
  
  */
  {
    id: "CohoD8H2",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"cell medium-cell-block-y editor-secondary-panel content-editor-side-bar\"],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"Header\",\"Body\"],[[28,\"component\",[\"editor/panel-header\"],null],[28,\"component\",[\"editor/panel-body\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/secondary-panel.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});