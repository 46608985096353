define("lightway-connect/routes/sites/site/user-kinds/user-kind/user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteUserKindsUserKindUserEditRoute extends Ember.Route {}

  _exports.default = SitesSiteUserKindsUserKindUserEditRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteUserKindsUserKindUserEditRoute, true);
});