define("lightway-connect/components/roles/invitation-form", ["exports", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/roles/invitation-form"], function (_exports, _emberChangeset, _emberChangesetValidations, _invitationForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.resetForm();
    },

    resetForm() {
      let invitation = this.store.createRecord('invitation', {
        role: this.role
      });
      this.set('invitation', invitation);
      let changeset = new _emberChangeset.default(this.invitation, (0, _emberChangesetValidations.default)(_invitationForm.default), _invitationForm.default);
      this.set('changeset', changeset);
    },

    actions: {
      sendInvitation() {
        this.changeset.validate().then(() => {
          if (this.changeset.get("isValid")) {
            this.changeset.save().then(() => {
              this.flashMessages.clearMessages().success('Invitation sent');
              this.resetForm();
            }).catch(reason => {
              this.flashMessages.clearMessages().alert('There was an error');
              this.formErrors.addErrors(reason, this.changeset);
            });
          } else {
            this.flashMessages.clearMessages().alert('There was an error');
          }
        });
      }

    }
  });

  _exports.default = _default;
});