define("lightway-connect/routes/sites/site/design/mail-templates/mail-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDesignMailTemplatesMailTemplateRoute extends Ember.Route {
    async model(params) {
      return this.store.findRecord('mail-template', params.mail_template_id, {
        reload: true
      });
    }

  }

  _exports.default = SitesSiteDesignMailTemplatesMailTemplateRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDesignMailTemplatesMailTemplateRoute, true);
});