define("lightway-connect/mirage/factories/translation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    frenchName: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'name'
    }),
    germanName: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'name'
    }),
    frenchLabel: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'label'
    }),
    germanLabel: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'label'
    }),
    frenchProperty: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'value'
    }),
    germanProperty: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'value'
    }),
    frenchTextContent: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'text_content'
    }),
    germanTextContent: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'text_content'
    }),
    frenchIntroductionMessage: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'introduction_message'
    }),
    germanIntroductionMessage: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'introduction_message'
    }),
    frenchConfirmationMessage: (0, _emberCliMirage.trait)({
      locale: 'fr',
      stringValue: 'French value',
      targetString: 'confirmation_message'
    }),
    germanConfirmationMessage: (0, _emberCliMirage.trait)({
      locale: 'de',
      stringValue: 'German value',
      targetString: 'confirmation_message'
    })
  });

  _exports.default = _default;
});