define("lightway-connect/controllers/invitations/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentOption: 'register-option',
    actions: {
      switchOption(option) {
        this.set('currentOption', option);
      },

      acceptInvitation() {
        this.target.send('acceptInvitation', this.model);
      },

      declineInvitation() {
        this.target.send('declineInvitation', this.model);
      }

    }
  });

  _exports.default = _default;
});