define("lightway-connect/templates/components/content-editor/part-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7IDjcJOg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"cell small-12\"],[11,\"title\",[23,0,[\"label\"]]],[8],[0,\"\\n\\n    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"page-editor-tool \",[23,0,[\"tool\"]],\"-tool\"]]],[12,\"data-test-id\",[29,[[23,0,[\"tool\"]],\"-tool\"]]],[3,\"action\",[[23,0,[]],\"desiredAction\"]],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[\"/assets/images/tool-\",[23,0,[\"tool\"]],\".svg\"]]],[11,\"alt\",[23,0,[\"label\"]]],[8],[9],[0,\"\\n\\n      \"],[7,\"span\",true],[10,\"class\",\"tool-label\"],[8],[0,\"\\n        \"],[1,[23,0,[\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/part-tool.hbs"
    }
  });

  _exports.default = _default;
});