define("lightway-connect/routes/sites/site/content/mail-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteContentMailMessagesRoute extends Ember.Route {}

  _exports.default = SitesSiteContentMailMessagesRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteContentMailMessagesRoute, true);
});