define("lightway-connect/components/ui-form/advanced-select-option", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @selectBoxIsOpen}}
    <div class={{this.optionClasses}} role="button" {{on "click" (fn @selectOptionAction @value @valueType)}} data-test-select-option-id={{this.valueId}}>
      {{yield}}
    </div>
  {{else}}
    {{#if this.matchesCurrentValue}}
      <div class={{this.optionClasses}} role="button" {{on "click" (fn @selectOptionAction @value @valueType)}} data-test-select-option-id={{this.valueId}}>
        {{yield}}
      </div>
    {{/if}}
  {{/if}}
  */
  {
    id: "Aw6mrbBa",
    block: "{\"symbols\":[\"@valueType\",\"@value\",\"@selectOptionAction\",\"&default\",\"@selectBoxIsOpen\"],\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[23,0,[\"optionClasses\"]]],[12,\"role\",\"button\"],[12,\"data-test-select-option-id\",[23,0,[\"valueId\"]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]],[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"matchesCurrentValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[23,0,[\"optionClasses\"]]],[12,\"role\",\"button\"],[12,\"data-test-select-option-id\",[23,0,[\"valueId\"]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]],[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n      \"],[14,4],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/ui-form/advanced-select-option.hbs"
    }
  });

  class UiFormAdvancedSelectOptionComponent extends _component.default {
    get valueId() {
      if (this.args.value === null) {
        return "null";
      }

      if (this.args.value) {
        return this.args.value;
      }

      return "";
    }

    get matchesCurrentValue() {
      let currentVal = this.args.currentValue;

      if (this.args.currentValue === undefined) {
        currentVal = null;
      }

      return currentVal === this.args.value;
    }

    get optionClasses() {
      let string = "select-option";

      if (this.args.value === null) {
        string += ' select-option-empty';
      }

      return string;
    }

  }

  _exports.default = UiFormAdvancedSelectOptionComponent;

  window.__CLASSIC_OWN_CLASSES__.set(UiFormAdvancedSelectOptionComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiFormAdvancedSelectOptionComponent);
});