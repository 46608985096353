define("lightway-connect/helpers/model-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function modelMatch(positional) {
    const models = positional[0];
    const property = positional[1];
    const valueToMatch = positional[2];
    const property2 = positional[3];
    const value2ToMatch = positional[4];

    if (!models || !property || !valueToMatch) {
      return null;
    }

    var match = null;
    models.forEach(model => {
      if (model[property] === valueToMatch) {
        if (property2) {
          if (Ember.get(model, property2) === value2ToMatch) {
            match = model;
          }
        } else {
          match = model;
        }
      }
    });
    return match;
  });

  _exports.default = _default;
});