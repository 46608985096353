define("lightway-connect/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base", "fetch"], function (_exports, _base, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    tokenEndpoint: '/api/backend/accounts/sign_in',
    // Keeps the session continuing across pages
    restore: function (data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          // Initialise account
          // Resolve
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function (options) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)(this.tokenEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            email: options.identification,
            password: options.password
          })
        }).then(function (response) {
          if (response.ok) {
            response.json().then(responseJson => {
              Ember.run(() => {
                resolve({
                  token: responseJson.auth_token,
                  accountId: responseJson.account.id
                });
              });
            });
          } else {
            Ember.run(() => {
              reject(response);
            });
          }
        }).catch(function (response) {
          Ember.run(() => {
            reject(response);
          });
        });
      });
    },
    invalidate: function () {
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});