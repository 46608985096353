define("lightway-connect/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatDate(params, hash) {
    let date = new Date(hash.date);

    if (!date) {
      date = new Date();
    }

    let year = date.getFullYear();
    let month = date.toLocaleString('default', {
      month: 'short'
    });
    let day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formatted = `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;
    return formatted;
  });

  _exports.default = _default;
});