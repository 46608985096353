define("lightway-connect/controllers/sites/site/settings/contributors/roles/role/contributors/contributor/remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      removeContributor() {
        this.target.send('removeContributor', this.model.contributor);
      }

    }
  });

  _exports.default = _default;
});