define("lightway-connect/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email(i) {
      return `foo${i}@foobar.com`;
    },

    firstName(i) {
      return `firstName${i}`;
    },

    lastName(i) {
      return `lastName${i}`;
    },

    status() {
      return 'active';
    },

    createdAt() {
      return '2023-09-01T09:34:18Z';
    },

    confirmedAt() {
      return '2023-09-01T09:35:18Z';
    }

  });

  _exports.default = _default;
});