define("lightway-connect/routes/sites/site/design/mail-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDesignMailTemplatesRoute extends Ember.Route {}

  _exports.default = SitesSiteDesignMailTemplatesRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDesignMailTemplatesRoute, true);
});