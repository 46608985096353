define("lightway-connect/routes/policies/privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PrivacyRoute = classic(_class = class PrivacyRoute extends Ember.Route {}) || _class;

  _exports.default = PrivacyRoute;
});