define("lightway-connect/routes/sites/site/data-kinds/orders/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let OrderRoute = classic(_class = class OrderRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('order', params.order_id, {
        reload: true
      });
    }

  }) || _class;

  _exports.default = OrderRoute;
});