define("lightway-connect/templates/components/field-association", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgomTYwv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"selecting\"]]],null,{\"statements\":[[0,\"\\n  \"],[5,\"modal-dialog\",[],[[\"@overlayClass\",\"@containerClass\",\"@onClose\"],[\"modal-bg-overlay grid-y align-center\",\"select-page-modal cell small-shrink\",[28,\"action\",[[23,0,[]],\"cancelAssociateSelection\"],null]]],{\"statements\":[[0,\"\\n\\n    \"],[7,\"h3\",true],[8],[0,\"Select \"],[1,[23,0,[\"field\",\"subjectKind\",\"name\"]],false],[9],[0,\"\\n\\n    \"],[5,\"resource-selector\",[],[[\"@type\",\"@parentId\",\"@parentType\",\"@selectAction\"],[\"data-item\",[23,0,[\"field\",\"subjectKind\",\"id\"]],\"data-kinds\",[28,\"action\",[[23,0,[]],\"selectAssociate\"],null]]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"property\",\"resource\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"select-item\"],[12,\"data-test-id\",\"select-item\"],[3,\"action\",[[23,0,[]],\"toggleModalForItem\"]],[8],[0,\"\\n      \"],[1,[23,0,[\"property\",\"resource\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"select-item\"],[12,\"data-test-id\",\"select-item\"],[3,\"action\",[[23,0,[]],\"toggleModalForItem\"]],[8],[0,\"\\n      Select Item\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/field-association.hbs"
    }
  });

  _exports.default = _default;
});