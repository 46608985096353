define("lightway-connect/mirage/utils/remove-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(data, db) {
    data.forEach(function (translationData) {
      let existingTranslation = translationData.id;

      if (existingTranslation) {
        let translation = db.translations.find(translationData.id);
        translation.destroy();
      }
    });
    data = [];
  }
});