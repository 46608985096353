define("lightway-connect/mirage/serializers/data-property", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    include: ['item', 'field', 'resource', 'image', 'data-item-defaulted']
  });

  _exports.default = _default;
});