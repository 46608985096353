define("lightway-connect/components/content-editor/new-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div role="button" {{on "click" @addAction}} class="cell shrink new-property-button" ...attributes>
    {{yield}}
  </div>
  */
  {
    id: "tw6jTq4Z",
    block: "{\"symbols\":[\"&attrs\",\"@addAction\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell shrink new-property-button\"],[13,1],[3,\"on\",[\"click\",[23,2,[]]]],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/new-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});