define("lightway-connect/routes/sites/site/mailing-lists/mailing-list/subscribers/subscriber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteMailingListsMailingListSubscribersSubscriberRoute extends Ember.Route {
    async model(params) {
      return this.store.findRecord('subscription', params.subscription_id);
    }

  }

  _exports.default = SitesSiteMailingListsMailingListSubscribersSubscriberRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteMailingListsMailingListSubscribersSubscriberRoute, true);
});