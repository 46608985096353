define("lightway-connect/components/accounts/change-password-form", ["exports", "fetch", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/accounts/change-password-form"], function (_exports, _fetch, _emberChangeset, _emberChangesetValidations, _changePasswordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_changePasswordForm.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formIsHidden: false,

    init() {
      this._super(...arguments);

      this.set('mod', {});
      let changeset = new _emberChangeset.default(this.mod, (0, _emberChangesetValidations.default)(_changePasswordForm.default), _changePasswordForm.default);
      this.set('changeset', changeset);
    },

    hideForm() {
      this.set('formIsHidden', true);
    },

    actions: {
      changePassword() {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isValid')) {
            let {
              token
            } = this.get('session.data.authenticated');

            if (this.get('session.isAuthenticated') && token) {
              let authorizationToken = `Bearer ${token}`;
              return this.requestPasswordChange(authorizationToken);
            } else {
              this.flashMessages.clearMessages().alert('There was an error');
              return;
            }
          } else {
            this.flashMessages.clearMessages().alert('There was an error');
            return;
          }
        });
      }

    },

    requestPasswordChange(authorizationToken) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)('/api/backend/accounts', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: authorizationToken
          },
          body: JSON.stringify({
            data: {
              "type": "accounts",
              "attributes": {
                "current-password": this.changeset.get('currentPassword'),
                "new-password": this.changeset.get('newPassword'),
                "new-password-confirmation": this.changeset.get('newPasswordConfirmation')
              }
            }
          })
        }).then(response => {
          if (response.ok) {
            this.flashMessages.clearMessages().success('Password has been changed');
            this.hideForm();
            return resolve();
          } else {
            response.json().then(responseJson => {
              Ember.run(() => {
                this.flashMessages.clearMessages().alert('There was an error');
                this.addErrorsToChangeset(responseJson);
                return reject();
              });
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            return reject();
          });
        });
      });
    },

    addErrorsToChangeset(responseJSON) {
      responseJSON.errors.forEach(({
        source,
        detail
      }) => {
        let paths = source.pointer.split('/');
        let attr = paths[paths.length - 1].split('-').join(' ');
        this.changeset.pushErrors(attr, `${String(attr).capitalize()} ${detail}`);
      });
    }

  });

  _exports.default = _default;
});