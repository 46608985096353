define("lightway-connect/components/item-picker/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell shrink">
    <div class="grid-x align-middle item-picker-header">
      <div class="cell small-3">
        {{#if this.selectedItem}}
          <!-- Selected folder or kind -->
        {{/if}}
      </div>
  
      <div class="cell small-6">
        <h3>
          {{yield}}
        </h3>
      </div>
      <div class="cell small-3">
      </div>
  
    </div>
  </div>
  */
  {
    id: "+6igWjQ9",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-middle item-picker-header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-3\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"selectedItem\"]]],null,{\"statements\":[[0,\"        \"],[2,\" Selected folder or kind \"],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-6\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-3\"],[8],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/item-picker/header.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});