define("lightway-connect/components/property-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['selector-modal file-selector grid-x align-center cell-block-y'],
    selectedProperty: null,
    associationIsReverse: null,
    actions: {
      selectProperty(property) {
        this.set('selectedProperty', property);
      },

      deselectProperty() {
        this.set('selectedProperty', null);
      },

      selectAssociation(association, reverse = false) {
        if (reverse) {
          this.set('associationIsReverse', reverse);
        }

        this.set('selectedAssociation', association);
      },

      deselectAssociation() {
        this.set('selectedAssociation', null);
        this.set('selectedProperty', null);
        this.set('associationIsReverse', null);
      }

    }
  });

  _exports.default = _default;
});