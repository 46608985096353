define("lightway-connect/components/sites/parts/image-part/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    selectedElement: Ember.inject.service(),
    // Disable wrapper div
    tagName: '',
    elementSelected: Ember.computed('selectedElement.element', function () {
      return this.selectedElement.element == this.part;
    }),
    actions: {
      editImage(part) {
        this.selectedElement.select(part);
      },

      removePart(part) {
        let parent = part.get('parent');
        parent.get('removedParts').pushObject(part);
        part.set('parent', null);
        this.flashMessages.clearMessages().success('Content removed');
      }

    }
  });

  _exports.default = _default;
});