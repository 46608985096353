define("lightway-connect/models/prototype", ["exports", "@ember-data/model", "lightway-connect/mixins/part-parentable", "lightway-connect/mixins/page-link-resource"], function (_exports, _model, _partParentable, _pageLinkResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Prototype = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.belongsTo)(), _dec4 = (0, _model.hasMany)({
    async: false
  }), _dec5 = (0, _model.hasMany)({
    async: false
  }), _dec6 = (0, _model.hasMany)({
    async: false
  }), _dec7 = (0, _model.hasMany)('part', {
    inverse: 'approvedPrototypes'
  }), _dec8 = (0, _model.hasMany)('part', {
    inverse: 'removedApprovedPrototypes'
  }), _dec9 = (0, _model.hasMany)('part', {
    inverse: 'basePrototype'
  }), _dec10 = (0, _model.hasMany)(), _dec11 = (0, _model.hasMany)('style', {
    inverse: 'formerPrototypes',
    async: false
  }), _dec12 = Ember.computed, _dec13 = Ember.computed, classic(_class = (_class2 = (_temp = class Prototype extends _model.default.extend(_partParentable.default, _pageLinkResource.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "kind", _descriptor2, this);

      _initializerDefineProperty(this, "site", _descriptor3, this);

      _initializerDefineProperty(this, "layouts", _descriptor4, this);

      _initializerDefineProperty(this, "mailTemplates", _descriptor5, this);

      _initializerDefineProperty(this, "dataKinds", _descriptor6, this);

      _initializerDefineProperty(this, "regions", _descriptor7, this);

      _initializerDefineProperty(this, "formerRegions", _descriptor8, this);

      _initializerDefineProperty(this, "basedParts", _descriptor9, this);

      _initializerDefineProperty(this, "styles", _descriptor10, this);

      _initializerDefineProperty(this, "removedStyles", _descriptor11, this);
    }

    get tempId() {
      if (!this.id) {
        return Ember.guidFor(this);
      } else {
        return null;
      }
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get baseParent() {
      return this;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "kind", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "site", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "layouts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "dataKinds", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "regions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "formerRegions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "basedParts", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "styles", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "removedStyles", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "tempId", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "tempId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "baseParent", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "baseParent"), _class2.prototype)), _class2)) || _class);
  _exports.default = Prototype;
});