define("lightway-connect/mirage/models/data-item", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    parent: (0, _emberCliMirage.belongsTo)('dataItemParentable', {
      polymorphic: true,
      async: false
    }),
    author: (0, _emberCliMirage.belongsTo)('user', {
      polymorphic: true,
      async: false
    }),
    ownedDataAssociates: (0, _emberCliMirage.hasMany)('data-associate', {
      inverse: 'owner'
    }),
    dataAssociates: (0, _emberCliMirage.hasMany)('data-associate', {
      inverse: 'item'
    }),
    dataTags: (0, _emberCliMirage.hasMany)({
      async: false
    }),
    orders: (0, _emberCliMirage.hasMany)(),
    orderItems: (0, _emberCliMirage.hasMany)(),
    registrations: (0, _emberCliMirage.hasMany)(),
    globalStyle: (0, _emberCliMirage.belongsTo)('style', {
      async: false
    }),
    // ! part-kontentable
    // Remember: use 'content' for API instead of 'kontent' (for ember)
    contentParts: (0, _emberCliMirage.hasMany)('parts', {
      inverse: 'content',
      cascadeDelete: true
    }),
    // ! part-parentable
    parts: (0, _emberCliMirage.hasMany)({
      inverse: 'parent',
      async: false
    }),
    itemRegions: (0, _emberCliMirage.hasMany)({
      inverse: 'item',
      async: false
    }),
    removedParts: (0, _emberCliMirage.hasMany)('part', {
      inverse: 'formerParent',
      async: false
    }),
    inheritedParts: (0, _emberCliMirage.hasMany)('part', {
      inverse: 'parentInherited',
      async: false
    }),
    // ! page-link-resource
    pageLinks: (0, _emberCliMirage.hasMany)({
      inverse: 'resource',
      async: false
    }),
    // ! data-property-item
    properties: (0, _emberCliMirage.hasMany)('data-property', {
      inverse: 'item',
      async: false
    }),
    // ! data-property-resource
    resourceProperties: (0, _emberCliMirage.hasMany)('data-property', {
      inverse: 'resource'
    })
  });

  _exports.default = _default;
});