define("lightway-connect/routes/sites/site/data-kinds/data-kind/items/item/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDataKindsDataKindItemsItemEditRoute extends Ember.Route {
    beforeModel() {
      // Hide section sidebar
      this.controllerFor('sites.site.data-kinds.data-kind.items.item.edit').hideSidebar();
    }

  }

  _exports.default = SitesSiteDataKindsDataKindItemsItemEditRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDataKindsDataKindItemsItemEditRoute, true);
});