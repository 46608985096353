define("lightway-connect/components/editor/panel-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel-body" ...attributes>
    {{yield}}
  </div>
  */
  {
    id: "6YEXi5JB",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"panel-body\"],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/panel-body.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});