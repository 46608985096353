define("lightway-connect/mirage/serializers/data-item", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    include: ['parent', 'properties', 'parts', 'itemRegions', 'itemRegionsAsRegion', 'inheritedParts', 'dataTags', 'globalStyle', 'author']
  });

  _exports.default = _default;
});