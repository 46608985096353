define("lightway-connect/routes/sites/site/media/image-folders/image-folder/image/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DeleteRoute = classic(_class = class DeleteRoute extends Ember.Route {}) || _class;

  _exports.default = DeleteRoute;
});