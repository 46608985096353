define("lightway-connect/controllers/sites/site/settings/domains/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    flashMessages: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    actions: {
      savePrimaryDomainFor(site) {
        this.target.send('savePrimaryDomainFor', site);
      },

      setAsPrimary(domain) {
        domain.site.set('primaryDomain', domain);
        domain.save().then(() => {
          this.flashMessages.clearMessages().success('Domain set as primary');
        });
      },

      deleteDomain(domain) {
        domain.destroyRecord().then(() => {
          this.flashMessages.clearMessages().success('Domain deleted');
        });
      }

    }
  });

  _exports.default = _default;
});