define("lightway-connect/mirage/factories/form-field", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    rowOrder: 1,
    requiredField: false,
    textField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Text Field ${i}`;
      },

      kind: 'text-field'
    }),
    emailField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Email Field ${i}`;
      },

      kind: 'email-field'
    }),
    phoneField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Phone Field ${i}`;
      },

      kind: 'phone-field'
    }),
    extendedTextField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Extended Text Field ${i}`;
      },

      kind: 'extended-text-field'
    }),
    imageField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Image Field ${i}`;
      },

      kind: 'image-field'
    }),
    documentField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Document Field ${i}`;
      },

      kind: 'document-field'
    }),
    imageCollectionField: (0, _emberCliMirage.trait)({
      label(i) {
        return `Image Collection Field ${i}`;
      },

      kind: 'image-collection-field'
    })
  });

  _exports.default = _default;
});