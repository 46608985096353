define("lightway-connect/components/content-editor/measurement-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model) {
    return Ember.computed(key, 'model', `model.${key}`, function () {
      if (model.get(key)) {
        return model.get(key);
      } else {
        return "";
      }
    });
  }

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'modelValue', valueFromProperty("model." + this.propertyPath, this));
      Ember.defineProperty(this, 'modelTopValue', valueFromProperty("model." + this.propertyPath + "-top", this));
      Ember.defineProperty(this, 'modelRightValue', valueFromProperty("model." + this.propertyPath + "-right", this));
      Ember.defineProperty(this, 'modelBottomValue', valueFromProperty("model." + this.propertyPath + "-bottom", this));
      Ember.defineProperty(this, 'modelLeftValue', valueFromProperty("model." + this.propertyPath + "-left", this));

      if (this.modelTopValue || this.modelRightValue || this.modelBottomValue || this.modelLeftValue) {
        this.set('advancedEnabled', true);
      } else if (this.values.topValue || this.values.rightValue || this.values.bottomValue || this.values.leftValue) {
        this.set('advancedEnabled', true);
      }
    },

    values: Ember.computed('modelValue', 'modelTopValue', 'modelRightValue', 'modelBottomValue', 'modelLeftValue', 'advancedEnabled', function () {
      if (this.modelTopValue || this.modelRightValue || this.modelBottomValue || this.modelLeftValue) {
        return {
          topValue: this.modelTopValue.split('px')[0],
          rightValue: this.modelRightValue.split('px')[0],
          bottomValue: this.modelBottomValue.split('px')[0],
          leftValue: this.modelLeftValue.split('px')[0]
        };
      } else {
        let splitValues = this.modelValue.split(" ");

        if (splitValues.length > 1) {
          return {
            topValue: splitValues[0].split('px')[0],
            rightValue: splitValues[1].split('px')[0],
            bottomValue: splitValues[2].split('px')[0],
            leftValue: splitValues[3].split('px')[0]
          };
        } else {
          if (this.advancedEnabled) {
            return {
              topValue: this.modelValue.split('px')[0],
              rightValue: this.modelValue.split('px')[0],
              bottomValue: this.modelValue.split('px')[0],
              leftValue: this.modelValue.split('px')[0]
            };
          } else {
            return {
              singleValue: this.modelValue.split('px')[0]
            };
          }
        }
      }
    }),
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),

    allValuesPresent() {
      let top = this.get("values.topValue");
      let right = this.get("values.rightValue");
      let bottom = this.get("values.bottomValue");
      let left = this.get("values.leftValue");

      if (top && right && bottom && left) {
        return true;
      } else {
        return false;
      }
    },

    allOtherValuesPresent(property) {
      let top = this.get("values.topValue");
      let right = this.get("values.rightValue");
      let bottom = this.get("values.bottomValue");
      let left = this.get("values.leftValue");

      if (property == "top") {
        return right && bottom && left;
      }

      if (property == "right") {
        return top && bottom && left;
      }

      if (property == "bottom") {
        return top && right && left;
      }

      if (property == "left") {
        return top && right && bottom;
      }
    },

    actions: {
      toggleAdvanced() {
        this.toggleProperty('advancedEnabled');
      },

      updateValue(val) {
        let changes = {};

        if (val != "") {
          changes[this.propertyPath] = val + this.measurement;
        } else {
          changes[this.propertyPath] = null;
        }

        changes[this.propertyPath + "-top"] = null;
        changes[this.propertyPath + "-right"] = null;
        changes[this.propertyPath + "-bottom"] = null;
        changes[this.propertyPath + "-left"] = null;
        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      updateTopValue(val) {
        let changes = {};

        if (val != "") {
          if (this.allOtherValuesPresent("top")) {
            let newValue = val + this.measurement + " " + this.values.rightValue + this.measurement + " " + this.values.bottomValue + this.measurement + " " + this.values.leftValue + this.measurement;
            changes[this.propertyPath] = newValue;
            changes[this.propertyPath + "-top"] = null;
            changes[this.propertyPath + "-right"] = null;
            changes[this.propertyPath + "-bottom"] = null;
            changes[this.propertyPath + "-left"] = null;
          } else {
            changes[this.propertyPath + "-top"] = val + this.measurement;
          }
        } else {
          changes[this.propertyPath + "-top"] = null;
        }

        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      updateRightValue(val) {
        let changes = {};

        if (val != "") {
          if (this.allOtherValuesPresent("right")) {
            let newValue = this.values.topValue + this.measurement + " " + val + this.measurement + " " + this.values.bottomValue + this.measurement + " " + this.values.leftValue + this.measurement;
            changes[this.propertyPath] = newValue;
            changes[this.propertyPath + "-top"] = null;
            changes[this.propertyPath + "-right"] = null;
            changes[this.propertyPath + "-bottom"] = null;
            changes[this.propertyPath + "-left"] = null;
          } else {
            changes[this.propertyPath + "-right"] = val + this.measurement;
          }
        } else {
          changes[this.propertyPath + "-right"] = null;
        }

        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      updateBottomValue(val) {
        let changes = {};

        if (val != "") {
          if (this.allOtherValuesPresent("bottom")) {
            let newValue = this.values.topValue + this.measurement + " " + this.values.rightValue + this.measurement + " " + val + this.measurement + " " + this.values.leftValue + this.measurement;
            changes[this.propertyPath] = newValue;
            changes[this.propertyPath + "-top"] = null;
            changes[this.propertyPath + "-right"] = null;
            changes[this.propertyPath + "-bottom"] = null;
            changes[this.propertyPath + "-left"] = null;
          } else {
            changes[this.propertyPath + "-bottom"] = val + this.measurement;
          }
        } else {
          changes[this.propertyPath + "-bottom"] = null;
        }

        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },

      updateLeftValue(val) {
        let changes = {};

        if (val != "") {
          if (this.allOtherValuesPresent("left")) {
            let newValue = this.values.topValue + this.measurement + " " + this.values.rightValue + this.measurement + " " + this.values.bottomValue + this.measurement + " " + val + this.measurement;
            changes[this.propertyPath] = newValue;
            changes[this.propertyPath + "-top"] = null;
            changes[this.propertyPath + "-right"] = null;
            changes[this.propertyPath + "-bottom"] = null;
            changes[this.propertyPath + "-left"] = null;
          } else {
            changes[this.propertyPath + "-left"] = val + this.measurement;
          }
        } else {
          changes[this.propertyPath + "-left"] = null;
        }

        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      }

    }
  });

  _exports.default = _default;
});