define("lightway-connect/controllers/accounts/settings/notifications/site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountsSettingsNotificationsSiteController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class AccountsSettingsNotificationsSiteController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "notificationRules", _descriptor2, this);

      this.reset();
    }

    reset() {
      this.notificationRules = Ember.A([]);

      if (this.model && this.model.notificationRules) {
        this.model.notificationRules.forEach(notificationRule => {
          this.notificationRules.addObject(notificationRule);
        });
      }
    }

    notificationRuleBy(rule, resource) {
      let result = null;

      if (this.model) {
        this.notificationRules.forEach(notificationRule => {
          if (notificationRule.rule === rule) {
            if (resource) {
              if (notificationRule.resource.get('id') === resource.get('id')) {
                result = notificationRule;
              }
            } else {
              result = notificationRule;
            }
          }
        });
      }

      return result;
    }

    underscoredRule(rule) {
      return rule.replace(/-/g, '_');
    }

    async enableRule(rule) {
      let notificationRule = this.store.createRecord('notification-rule', {
        site: this.model.site,
        kind: 'mail',
        rule: rule
      });
      await notificationRule.save();
      this.notificationRules.addObject(notificationRule);
    }

    async disableRule(rule) {
      let notificationRule;

      if (this.model) {
        this.notificationRules.forEach(nr => {
          if (nr.rule === rule) {
            notificationRule = nr;
          }
        });
      }

      if (notificationRule) {
        this.notificationRules.removeObject(notificationRule);
        await notificationRule.destroyRecord();
      }
    }

    async toggleRule(rule, resource) {
      let underscoredRule = this.underscoredRule(rule);
      let notificationRule = this.notificationRuleBy(underscoredRule, resource);

      if (notificationRule) {
        // Delete rule
        await notificationRule.destroyRecord();
        this.notificationRules.removeObject(notificationRule);
      } else {
        // Create and save rule
        let notificationRule = this.store.createRecord('notification-rule', {
          site: this.model.site,
          kind: 'mail',
          rule: underscoredRule,
          resource: resource
        });
        await notificationRule.save();
        this.notificationRules.addObject(notificationRule);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notificationRules", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enableRule", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "enableRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableRule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "disableRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleRule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRule"), _class.prototype)), _class));
  _exports.default = AccountsSettingsNotificationsSiteController;
});