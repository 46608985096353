define("lightway-connect/templates/sites/site/web-forms/web-form/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LH78tlH/",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"\\n  Edit Form\\n\"],[9],[0,\"\\n\\n\"],[5,\"web-forms/edit-web-form-form\",[],[[\"@webForm\",\"@saveAction\"],[[23,1,[\"webForm\"]],[28,\"action\",[[23,0,[]],\"saveWebForm\"],null]]]],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/web-forms/web-form/edit.hbs"
    }
  });

  _exports.default = _default;
});