define("lightway-connect/mirage/models/part", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    approvedPrototypes: (0, _emberCliMirage.hasMany)('prototype', {
      inverse: 'regions'
    }),
    removedApprovedPrototypes: (0, _emberCliMirage.hasMany)('prototype', {
      inverse: 'formerRegions'
    }),
    basePrototype: (0, _emberCliMirage.belongsTo)('prototype', {
      inverse: 'basedParts',
      async: false
    }),
    itemRegionsAsRegion: (0, _emberCliMirage.hasMany)('item-region', {
      inverse: 'region',
      async: false
    }),
    styles: (0, _emberCliMirage.hasMany)(),
    removedStyles: (0, _emberCliMirage.hasMany)('style', {
      inverse: 'formerParts',
      async: false
    }),
    pageLink: (0, _emberCliMirage.belongsTo)({
      async: false
    }),
    // ! part-parentable
    parts: (0, _emberCliMirage.hasMany)({
      inverse: 'parent',
      async: false
    }),
    itemRegions: (0, _emberCliMirage.hasMany)({
      inverse: 'item',
      async: false
    }),
    removedParts: (0, _emberCliMirage.hasMany)('part', {
      inverse: 'formerParent',
      async: false
    }),
    inheritedParts: (0, _emberCliMirage.hasMany)('part', {
      inverse: 'parentInherited',
      async: false
    }),
    // ! parent-partable
    parent: (0, _emberCliMirage.belongsTo)('partParentable', {
      inverse: 'parts',
      polymorphic: true,
      async: false
    }),
    formerParent: (0, _emberCliMirage.belongsTo)('partParentable', {
      inverse: 'removedParts',
      polymorphic: true,
      async: false
    }),
    parentInherited: (0, _emberCliMirage.belongsTo)('partParentable', {
      inverse: 'inheritedParts',
      async: false
    }),
    // ! kontent-partable
    // Remember: use 'content' for API instead of 'kontent' (for ember)
    content: (0, _emberCliMirage.belongsTo)('partKontentable', {
      inverse: 'contentParts',
      polymorphic: true,
      async: false
    }),
    // ! hover-item-rel
    hoverItems: (0, _emberCliMirage.hasMany)('hoverItem', {
      inverse: 'item',
      async: false
    }),
    ownedHoverItems: (0, _emberCliMirage.hasMany)('hoverItem', {
      inverse: 'owner',
      async: false
    }),
    // ! translation-parentable
    translations: (0, _emberCliMirage.hasMany)({
      inverse: 'parent',
      async: true
    }),
    removedTranslations: (0, _emberCliMirage.hasMany)('translation', {
      inverse: 'formerParent',
      async: true
    }),
    // ! IMPORTANT - Needed only for mirage
    // These associations do not exist on the ember data models because they only needed for the Parts API to include associated images and documents
    linkedImages: (0, _emberCliMirage.hasMany)('image', {
      reverse: 'linkedParts'
    }),
    linkedDocuments: (0, _emberCliMirage.hasMany)('document', {
      reverse: 'linkedParts'
    }),
    linkedPages: (0, _emberCliMirage.hasMany)('page', {
      reverse: 'linkedParts'
    })
  });

  _exports.default = _default;
});