define("lightway-connect/templates/sites/site/media/document-folders/document-folder/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bWgF7f5k",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[0,\"Link: \"],[1,[23,1,[\"documentUrl\"]],false],[0,\"\\n\"],[7,\"a\",true],[11,\"href\",[23,1,[\"documentUrl\"]]],[8],[0,\"\\n  \"],[1,[23,1,[\"documentUrl\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"data-test-id\",\"rename-document\"]],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.document.rename\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Rename\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"data-test-id\",\"move-document\"]],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.document.move\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Move to Folder\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"data-test-id\",\"delete-document\"]],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.document.delete\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Delete\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/document-folders/document-folder/document.hbs"
    }
  });

  _exports.default = _default;
});