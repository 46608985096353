define("lightway-connect/templates/reset-password/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45IjPro/",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\"],\"statements\":[[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-8 large-6 card\"],[8],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"Reset Password\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"formIsHidden\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"confirmation-message\"],[10,\"data-test-id\",\"confirmation-message\"],[8],[0,\"\\n            Please check your email for a link to change your password.\\n          \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[28,\"hash\",null,null],[23,0,[\"requestResetPasswordValidations\"]]],null]],null,{\"statements\":[[0,\"\\n            \"],[5,\"form-errors\",[],[[\"@changeset\"],[[23,1,[]]]]],[0,\"\\n\\n            \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"requestResetPassword\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n              \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"email\"]],{\"statements\":[[0,\"\\n                \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n                  Your email\\n                \"]],\"parameters\":[]}],[0,\"\\n                \"],[6,[23,3,[\"emailInput\"]],[],[[\"@autocomplete\"],[\"username\"]]],[0,\"\\n              \"]],\"parameters\":[3]}],[0,\"\\n\\n              \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n                Submit\\n              \"]],\"parameters\":[]}],[0,\"\\n\\n            \"]],\"parameters\":[2]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/reset-password/index.hbs"
    }
  });

  _exports.default = _default;
});