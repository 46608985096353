define("lightway-connect/components/content-editor/parts/image-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNameBindings: ['grid-x', 'align-center'],
    image: Ember.computed('part.{kontent,preferences.dynamic-value.id}', function () {
      let result;

      if (this.loopItem) {
        let imageId = this.loopItem.id;
        let image;

        if (imageId) {
          image = this.store.findRecord('image', imageId);
        }

        return image;
      }

      let dynamicValue = this.part.get('preferences.dynamic-value');

      if (dynamicValue) {
        let subject = this.part.currentDynamicContainer;
        let fieldId = dynamicValue['id'];

        if (fieldId) {
          let field = this.store.peekRecord('data-field', fieldId);
          let assocFieldId = dynamicValue['association-field-id'];

          if (assocFieldId) {
            let assocField = this.store.peekRecord('data-field', assocFieldId);

            if (assocField) {
              assocField.properties.forEach(prop => {
                let associate = prop.resource;

                if (associate) {
                  if (field) {
                    associate.properties.forEach(p => {
                      if (p.field == field) {
                        result = p.image;
                      }
                    });
                  }
                }
              });
            }
          } else {
            // if not through associate
            if (subject) {
              if (subject.isA('data-kind')) {
                subject.fields.forEach(prop => {
                  if (prop.field == field) {
                    result = prop.image;
                  }
                });
              } else if (subject.isA('part')) {
                if (subject.preferences.dynamic != "gallery-item") {
                  if (subject.subjectTypeObject != "pages") {
                    if (subject.subjectTypeObject.isA('data-item')) {
                      subject.subjectTypeObject.properties.forEach(prop => {
                        if (prop.field == field) {
                          result = prop.image;
                        }
                      });
                    } else {
                      subject.subjectTypeObject.fields.forEach(prop => {
                        if (prop.field == field) {
                          result = prop.image;
                        }
                      });
                    }
                  }
                }
              } else {
                subject.properties.forEach(prop => {
                  if (prop.field == field) {
                    result = prop.image;
                  }
                });
              }
            }
          }
        }
      } else if (this.part.kontent) {
        result = this.part.kontent;
      }

      return result;
    }),
    dynamicContainer: Ember.computed('part.currentDynamicContainer', function () {
      if (this.part) {
        return this.part.currentDynamicContainer;
      }

      return null;
    }),
    subject: Ember.computed('dynamicContainer', function () {
      return this.dynamicContainer.subject;
    }),
    subjectType: Ember.computed('dynamicContainer.preferences.subject-type', function () {
      let result;

      if (this.dynamicContainer.isA('page-version')) {
        let subjectType = this.dynamicContainer.get('preferences.subject-type');

        if (subjectType && subjectType != {}) {
          if (subjectType.type) {
            this.store.peekAll(subjectType.type).forEach(itm => {
              if (itm.id == subjectType.id) {
                result = itm;
              }
            });
          }
        }
      }

      return result;
    }),
    dynamicImage: Ember.computed('part.preferences.dynamic-value', function () {
      let result;
      let dVHash = this.part.get('preferences.dynamic-value');

      if (dVHash) {
        if (dVHash.id) {
          let referenceRecord = this.store.peekRecord('data-field', dVHash.id);

          if (this.subject) {
            this.subject.properties.forEach(prop => {
              if (prop.field.kind == 'association-field') {
                prop.resource.properties.forEach(property => {
                  if (property.field == referenceRecord) {
                    result = property.image;
                  }
                });
              } else {
                if (prop.field == referenceRecord) {
                  result = prop.image;
                } else if (prop.field.subjectKind == referenceRecord) {
                  result = prop.image;
                }
              }
            });
          } else {
            if (referenceRecord) {
              result = "{ " + referenceRecord.name + " }";
            }
          }
        }
      }

      return result;
    })
  });

  _exports.default = _default;
});