define("lightway-connect/components/drag-sort-container", ["exports", "ember-drag-sort/components/drag-sort-list"], function (_exports, _dragSortList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dragSortList.default.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isShowingModal: false,

    confirmPartRemovalAction() {
      let remove = this.editorService.prepareRemove(this.editorService.selection);
      this.editorService.addModifications(remove);
      this.hideModal();
    },

    hideModal() {
      this.set('isShowingModal', false);
    },

    actions: {
      doNothing() {},

      toggleHoverSelection(part) {
        this.editorService.toggleHoverSelection(part);
      },

      removePart() {
        this.set('isShowingModal', true);
      },

      confirmPartRemovalAction() {
        this.confirmPartRemovalAction();
      },

      hideModal() {
        this.hideModal();
      },

      duplicatePart(part) {
        this.editorService.duplicateItem(part);
      }

    }
  });

  _exports.default = _default;
});