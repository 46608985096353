define("lightway-connect/components/quill-editor", ["exports", "quill", "lodash"], function (_exports, _quill, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['quill-editor'],
    defaultTag: null,
    editor: null,

    textChange() {},

    selectionChange() {},

    didInsertElement() {
      this._super(...arguments);

      var options = {
        modules: {
          toolbar: this.toolbarOptions
        },
        placeholder: 'Enter text here...',
        theme: this.theme
      }; // var Block
      // if (this.defaultTag) {
      //   Block = Quill.import('blots/block');
      //   Block.tagName = this.defaultTag;
      //   Quill.register(Block, true);
      // } else {
      //   Block = Quill.import('blots/block');
      //   Block.tagName = "p";
      //   Quill.register(Block, true);
      // }

      var Block = _quill.default.import('blots/block');

      Block.tagName = this.defaultTag || "p";

      _quill.default.register(Block, true);

      const editor = new _quill.default(this.element, options);
      editor.on("text-change", (delta, oldDelta, source) => {
        this.textChange(this.editor, delta, oldDelta, source);
      });
      editor.on("selection-change", (delta, oldDelta, source) => {
        this.selectionChange(this.editor, delta, oldDelta, source);
      });
      editor.setContents(this.value);
      editor.enable(this.enabled);
      this.set("editor", editor);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      if (this.value == null) {
        this.editor.setContents(null);
      } else {
        if (!(0, _lodash.isEqual)(this.value.ops, this.editor.editor.delta.ops)) {
          this.editor.setContents(this.value);
        }
      }
    }

  });

  _exports.default = _default;
});