define("lightway-connect/components/select-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xezRqDud",
    "block": "{\"symbols\":[\"@value\",\"@label\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"optionSelected\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[10,\"selected\",\"\"],[11,\"data-test-option-id\",[23,1,[]]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"data-test-option-id\",[23,1,[]]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/select-option/template.hbs"
    }
  });

  _exports.default = _default;
});