define("lightway-connect/routes/sites/site/mailing-lists/mailing-list/subscribers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteMailingListsMailingListSubscribersRoute extends Ember.Route {}

  _exports.default = SitesSiteMailingListsMailingListSubscribersRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteMailingListsMailingListSubscribersRoute, true);
});