define("lightway-connect/components/ui-form/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.extend({
    click(event) {
      if (this.toggleAction) {
        return this.toggleAction(event, this.checkedValue);
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});