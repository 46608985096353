define("lightway-connect/routes/sites/site/content/pages/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PageRoute = classic(_class = class PageRoute extends Ember.Route {
    beforeModel() {
      // Hide section sidebar
      this.controllerFor('sites.site.content').hideSidebar();
    }

    model(params) {
      // Remove all existing related records from store
      // so that they are redefined by fresh payload
      this.store.peekAll('part').forEach(part => {
        this.store.unloadRecord(part);
      });
      this.store.peekAll('layout').forEach(layout => {
        this.store.unloadRecord(layout);
      });
      this.store.peekAll('layout-version').forEach(layoutVersion => {
        this.store.unloadRecord(layoutVersion);
      });
      this.store.peekAll('prototype').forEach(prototype => {
        this.store.unloadRecord(prototype);
      }); // Load page and associated records

      return this.store.findRecord('page', params.page_id, {
        reload: true
      });
    }

  }) || _class;

  _exports.default = PageRoute;
});