define("lightway-connect/services/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PermissionsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, classic(_class = (_class2 = (_temp = class PermissionsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentAccount", _descriptor, this);

      _initializerDefineProperty(this, "currentSite", _descriptor2, this);
    }

    accountPermissionsInclude(kinds) {
      const self = this;
      let account = this.get('currentAccount.account');

      if (account) {
        if (account.get('lc')) {
          result = true;
        } else {
          let site = this.get('currentSite.site');
          var result = false;

          if (site) {
            if (site.get('selfOwned')) {
              result = true;
            } else {
              // Find site's role that includes current account
              site.get('roles').forEach(function (role) {
                // role.get('contributors').forEach(function(contributor) {
                //   if (contributor == account) {
                //     // Check if permissions match kinds
                //     role.get('permissions').forEach(function(permission) {
                //       if (self.checkIfKindsMatchPermission(kinds, permission)) {
                //         result = true
                //       }
                //     })
                //   }
                // })
                if (account.get('associatedRoles').includes(role)) {
                  // Check if permissions match kinds
                  role.get('permissions').forEach(function (permission) {
                    if (self.checkIfKindsMatchPermission(kinds, permission)) {
                      result = true;
                    }
                  });
                }
              });
            }
          }
        }
      }

      return result;
    }

    checkIfKindsMatchPermission(kinds, permission) {
      var result = false; // Run through each kind provided

      kinds.forEach(function (kind) {
        if (permission.get('kind') == kind) {
          result = true;
        }
      });
      return result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentSite", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = PermissionsService;
});