define("lightway-connect/components/tab-option", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo
    @route={{@route}}
    @query={{this.query}}
    class="cell shrink tab-option {{if (eq @currentValue @value) 'current'}}"
    data-test-tab-option-id={{@value}}
    ...attributes>
  
    <span>
      {{yield}}
    </span>
  
  </LinkTo>
  
  */
  {
    id: "JalLiaib",
    block: "{\"symbols\":[\"@route\",\"@value\",\"@currentValue\",\"&attrs\",\"&default\"],\"statements\":[[5,\"link-to\",[[12,\"class\",[29,[\"cell shrink tab-option \",[28,\"if\",[[28,\"eq\",[[23,3,[]],[23,2,[]]],null],\"current\"],null]]]],[12,\"data-test-tab-option-id\",[23,2,[]]],[13,4]],[[\"@route\",\"@query\"],[[23,1,[]],[23,0,[\"query\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[14,5],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/tab-option.hbs"
    }
  });

  class TabOptionComponent extends _component.default {
    get query() {
      let hsh = {};
      Ember.set(hsh, this.args.property, this.args.value);
      return hsh;
    }

  }

  _exports.default = TabOptionComponent;

  window.__CLASSIC_OWN_CLASSES__.set(TabOptionComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TabOptionComponent);
});