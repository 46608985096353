define("lightway-connect/components/translations-for-target/component", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('translationsLoaded', false);
      this.showTranslations();
    },

    showTranslations() {
      let type = this.model.get('constructor.modelName');
      let parentType = (0, _emberInflector.pluralize)(type);
      let parentId = this.model.id;
      let self = this; // Load translations

      if (parentId != null) {
        this.store.query('translation', {
          filter: {
            site: this.get('currentSite.site.id'),
            parent: parentId,
            parent_type: parentType,
            attrib: this.targetString.underscore()
          },
          page: 1
        }).then(results => {
          results.forEach(function (object) {
            object.set('parent', self.model);
          });
          self.set('translationsLoaded', true);
        });
      } else {
        self.set('translationsLoaded', true);
      }
    },

    actions: {
      addTranslation() {
        this.store.createRecord('translation', {
          parent: this.model,
          targetString: this.targetString.underscore(),
          locale: "fr"
        });
      }

    }
  });

  _exports.default = _default;
});