define("lightway-connect/services/current-resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let CurrentResourceService = classic(_class = class CurrentResourceService extends Ember.Service {
    load(resource) {
      this.unload();

      if (!Ember.isEmpty(resource)) {
        this.set('resource', resource);
      }
    }

    unload() {
      // If current resource exists, clear it
      if (this.resource != null) {
        this.set('resource', null);
      }
    }

  }) || _class;

  _exports.default = CurrentResourceService;
});