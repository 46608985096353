define("lightway-connect/models/mail-template-version", ["exports", "@ember-data/model", "lightway-connect/mixins/style-parentable", "lightway-connect/mixins/part-parentable", "lightway-connect/helpers/dynamic-style-property"], function (_exports, _model, _styleParentable, _partParentable, _dynamicStyleProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MailTemplateVersionModel = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec5 = (0, _model.belongsTo)(), _dec6 = (0, _model.belongsTo)('mail-template', {
    inverse: 'currentVersion'
  }), _dec7 = (0, _model.belongsTo)('style'), _dec8 = (0, _model.belongsTo)('style', {
    inverse: 'mailTemplateVersion',
    async: false
  }), _dec9 = (0, _model.hasMany)(), _dec10 = (0, _model.hasMany)({
    inverse: 'parent',
    async: false
  }), classic(_class = (_class2 = (_temp = class MailTemplateVersionModel extends _model.default.extend(_styleParentable.default, _partParentable.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "editorService", _descriptor, this);

      _initializerDefineProperty(this, "versionName", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "preferences", _descriptor4, this);

      _initializerDefineProperty(this, "mailTemplate", _descriptor5, this);

      _initializerDefineProperty(this, "mailTemplateCurrented", _descriptor6, this);

      _initializerDefineProperty(this, "globalPalette", _descriptor7, this);

      _initializerDefineProperty(this, "globalStyle", _descriptor8, this);

      _initializerDefineProperty(this, "itemRegions", _descriptor9, this);

      _initializerDefineProperty(this, "parts", _descriptor10, this);
    }

    init() {
      super.init(...arguments); // Make sure preferences exist

      if (!this.preferences) {
        this.set('preferences', {
          'small': {},
          'medium': {},
          'large': {}
        });
      }

      if (!this.preferences.small) {
        this.set('preferences.small', {});
      }

      if (!this.preferences.medium) {
        this.set('preferences.medium', {});
      }

      if (!this.preferences.large) {
        this.set('preferences.large', {});
      } // Initialise viewport if does not already exist


      if (!this.editorService.viewport) {
        this.editorService.selectViewport("small");
      } // Set viewport–scoped computed properties dynamically


      let properties = ['background-color-type', 'background-color-value', 'background-image-reference', 'background-position', 'background-repeat', 'background-size', 'background-video-reference', 'background-gradient-direction', 'background-gradient-a-color-type', 'background-gradient-a-color-value', 'background-gradient-b-color-type', 'background-gradient-b-color-value', 'text-font-reference', 'text-size', 'text-align', 'text-variant', 'text-color-type', 'text-color-value', 'text-case', 'p-font-reference', 'p-size', 'p-align', 'p-variant', 'p-color-type', 'p-color-value', 'p-case', 'h1-font-reference', 'h1-size', 'h1-align', 'h1-variant', 'h1-color-type', 'h1-color-value', 'h1-case', 'h2-font-reference', 'h2-size', 'h2-align', 'h2-variant', 'h2-color-type', 'h2-color-value', 'h2-case', 'h3-font-reference', 'h3-size', 'h3-align', 'h3-variant', 'h3-color-type', 'h3-color-value', 'h3-case', 'h4-font-reference', 'h4-size', 'h4-align', 'h4-variant', 'h4-color-type', 'h4-color-value', 'h4-case', 'h5-font-reference', 'h5-size', 'h5-align', 'h5-variant', 'h5-color-type', 'h5-color-value', 'h5-case', 'h6-font-reference', 'h6-size', 'h6-align', 'h6-variant', 'h6-color-type', 'h6-color-value', 'h6-case', 'blockquote-font-reference', 'blockquote-size', 'blockquote-align', 'blockquote-variant', 'blockquote-color-type', 'blockquote-color-value', 'blockquote-case', 'label-font-reference', 'label-size', 'label-align', 'label-variant', 'label-color-type', 'label-color-value', 'label-case', 'button-font-reference', 'button-size', 'button-align', 'button-variant', 'button-color-type', 'button-color-value', 'button-case', // Inputs
      'input-margin', 'input-margin-top', 'input-margin-right', 'input-margin-bottom', 'input-margin-left', 'input-padding', 'input-padding-top', 'input-padding-right', 'input-padding-bottom', 'input-padding-left', 'input-border', 'input-border-top', 'input-border-right', 'input-border-bottom', 'input-border-left', 'input-border-color-type', 'input-border-color-value', 'input-border-radius', 'input-border-radius-top-left', 'input-border-radius-top-right', 'input-border-radius-bottom-right', 'input-border-radius-bottom-left', 'input-background-color-type', 'input-background-color-value', 'input-background-image-reference', 'input-background-position', 'input-background-repeat', 'input-background-size', 'input-background-video-reference', 'input-background-gradient-direction', 'input-background-gradient-a-color-type', 'input-background-gradient-a-color-value', 'input-background-gradient-b-color-type', 'input-background-gradient-b-color-value', 'input-border-color-type', 'input-border-color-value', // Buttons
      'button-margin', 'button-margin-top', 'button-margin-right', 'button-margin-bottom', 'button-margin-left', 'button-padding', 'button-padding-top', 'button-padding-right', 'button-padding-bottom', 'button-padding-left', 'button-border', 'button-border-top', 'button-border-right', 'button-border-bottom', 'button-border-left', 'button-border-color-type', 'button-border-color-value', 'button-border-radius', 'button-border-radius-top-left', 'button-border-radius-top-right', 'button-border-radius-bottom-right', 'button-border-radius-bottom-left', 'button-background-color-type', 'button-background-color-value', 'button-background-image-reference', 'button-background-position', 'button-background-repeat', 'button-background-size', 'button-background-video-reference', 'button-background-gradient-direction', 'button-background-gradient-a-color-type', 'button-background-gradient-a-color-value', 'button-background-gradient-b-color-type', 'button-background-gradient-b-color-value', 'button-border-color-type', 'button-border-color-value'];
      properties.forEach(property => {
        // Property scoped by current viewport
        // e.g. positionX, inheritedPositionX
        Ember.defineProperty(this, // propertyName
        Ember.String.camelize(property), (0, _dynamicStyleProperty.dynamicStyleProperty)('preferences', property)); // Inherited property

        Ember.defineProperty(this, // inheritedPropertyName
        `inherited${Ember.String.capitalize(Ember.String.camelize(property))}`, (0, _dynamicStyleProperty.dynamicStyleProperty)('preferences', property, 'inherited'));
      });
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "editorService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "versionName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplateCurrented", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "globalPalette", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "globalStyle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "itemRegions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "parts", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = MailTemplateVersionModel;
});