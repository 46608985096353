define("lightway-connect/mirage/utils/generate-random-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    const min = Math.ceil(200);
    const max = Math.floor(400);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
});