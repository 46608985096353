define("lightway-connect/controllers/sites/site/media/image-folders/image-folder/image/rename", ["exports", "lightway-connect/validations/rename-media"], function (_exports, _renameMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RenameController = (_dec = Ember._action, classic(_class = (_class2 = (_temp = class RenameController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "RenameMediaValidations", _renameMedia.default);
    }

    save(changesetObj) {
      changesetObj.validate().then(() => {
        if (changesetObj.get("isValid")) {
          changesetObj.save().then(() => {
            this.flashMessages.clearMessages().success('Image updated');
            this.transitionToRoute('sites.site.media.image-folders.image-folder.image', changesetObj.get('id'));
          }).catch(reason => {
            this.formErrors.addErrors(reason, changesetObj);
            this.model.rollbackAttributes();
          });
        }
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  _exports.default = RenameController;
});