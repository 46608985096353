define("lightway-connect/mirage/models/image", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    imageFolder: (0, _emberCliMirage.belongsTo)(),
    dataProperties: (0, _emberCliMirage.hasMany)(),
    // ! part-kontentable mixin
    contentParts: (0, _emberCliMirage.hasMany)('part', {
      inverse: 'content'
    }),
    // ! page-link-resource mixin
    pageLinks: (0, _emberCliMirage.hasMany)({
      inverse: 'resource',
      async: false
    }),
    // ! IMPORTANT - Needed only for mirage
    // These associations do not exist on the ember data models because they only needed for the Parts API to include associated images and documents
    linkedParts: (0, _emberCliMirage.hasMany)('part', {
      reverse: 'linkedImages'
    })
  });

  _exports.default = _default;
});