define("lightway-connect/mirage/serializers/data-category", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    include: ['fields', 'categories', 'translations', 'parent', 'pageLinks']
  });

  _exports.default = _default;
});