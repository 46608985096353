define("lightway-connect/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/account.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/account.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/address.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/contributor.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contributor.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-associate.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-associate.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-category.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-field.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-kind.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-kind.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-property.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-property.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/data-tag.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/data-tag.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/document-folder.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/document-folder.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/document.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/document.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/domain.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/domain.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/form-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/form-field.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/hover-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/hover-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/image-folder.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/image-folder.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/image.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/image.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/invitation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/invitation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/invoice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/invoice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/layout-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/layout-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/layout.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/layout.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/mail-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/mail-message.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/mail-template.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/mail-template.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/order-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/order-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/order.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/order.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/page-link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/page-link.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/page-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/page-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/page.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/page.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/part.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/part.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/permission.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/permission.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/prototype.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/prototype.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/region-page.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/region-page.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/registration.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/registration.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/role.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/screen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/screen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/site.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/site.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/style.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/style.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/translation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/translation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user-kind.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user-kind.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/web-form.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/web-form.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/identity-managers/page.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/identity-managers/page.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/data-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/data-category.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/data-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/data-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/document.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/document.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/image.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/image.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/part.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/part.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/web-form.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/web-form.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/account.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/account.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/data-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/data-category.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/data-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/data-field.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/data-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/data-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/data-kind.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/data-kind.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/data-property.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/data-property.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/invitation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/invitation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/invoice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/invoice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/item-region.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/item-region.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/layout-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/layout-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/layout.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/layout.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/mail-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/mail-message.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/mail-template-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/mail-template-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/mail-template.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/mail-template.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/mailing-list.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/mailing-list.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/order-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/order-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/order.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/order.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/page-link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/page-link.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/page-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/page-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/page.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/page.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/part.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/part.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/prototype.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/prototype.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/role.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/screen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/screen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/site.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/site.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/user-kind.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user-kind.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/web-form.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/web-form.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/formatted-error.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/formatted-error.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/generate-random-id.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/generate-random-id.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/generate-translations-for.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/generate-translations-for.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/remove-translations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/remove-translations.js should pass ESLint\n\n');
  });
});