define("lightway-connect/mirage/models/data-category", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    parent: (0, _emberCliMirage.belongsTo)('dataCategoryParentable', {
      polymorphic: true,
      async: false
    }),
    categories: (0, _emberCliMirage.hasMany)('data-category', {
      inverse: 'parent'
    }),
    fields: (0, _emberCliMirage.hasMany)('data-field'),
    items: (0, _emberCliMirage.hasMany)('data-item', {
      inverse: 'parent'
    }),
    translations: (0, _emberCliMirage.hasMany)({
      inverse: 'parent'
    }),
    removedTranslations: (0, _emberCliMirage.hasMany)('translation', {
      inverse: 'formerParent'
    }),
    pageLinks: (0, _emberCliMirage.hasMany)({
      inverse: 'resource',
      async: false
    }),
    parts: (0, _emberCliMirage.hasMany)({
      inverse: 'kontent',
      async: false,
      cascadeDelete: true
    })
  });

  _exports.default = _default;
});