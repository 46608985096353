define("lightway-connect/routes/sites/site/settings/domains/domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DomainRoute = classic(_class = class DomainRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('domain', params.domain_id);
    }

  }) || _class;

  _exports.default = DomainRoute;
});