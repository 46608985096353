define("lightway-connect/mirage/utils/formatted-error", ["exports", "ember-cli-mirage/response"], function (_exports, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(property, msg) {
    return new _response.default(422, {
      'Content-Type': 'application/json'
    }, {
      "errors": [{
        "source": {
          "pointer": `/data/attributes/${property}`
        },
        "detail": msg
      }]
    });
  }
});