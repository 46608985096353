define("lightway-connect/mirage/factories/address", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: "Lucy Ricardo",
    businessName: "Aunt Sally's Old Fashioned Salad Dressing",
    street_1: "623 East 68th Street",
    street_2: "",
    city: "New York",
    state: "NY",
    country: "United States of America",
    postalCode: "10065"
  });

  _exports.default = _default;
});