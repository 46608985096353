define("lightway-connect/components/form/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button {{on 'click' this.submit}} type={{@type}} data-test-button-id={{@type}} ...attributes>
    {{yield}}
  </button>
  
  */
  {
    id: "ekdme6FF",
    block: "{\"symbols\":[\"@type\",\"&attrs\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"data-test-button-id\",[23,1,[]]],[13,2],[12,\"type\",[23,1,[]]],[3,\"on\",[\"click\",[23,0,[\"submit\"]]]],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/button.hbs"
    }
  });

  let FormButtonComponent = (_dec = Ember._action, (_class = class FormButtonComponent extends _component.default {
    submit(event) {
      event.preventDefault();

      if (this.args.onClick) {
        this.args.onClick(event);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "submit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = FormButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormButtonComponent);
});