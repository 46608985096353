define("lightway-connect/mirage/factories/style", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'name'(i) {
      return `Style ${i}`;
    },

    styleProperty: {},
    globalStyle: (0, _emberCliMirage.trait)({
      name: "Global Styles",
      styleProperties: {
        "color-class-ids": ["color-class-1", "color-class-2", "color-class-3"],
        "color-classes": [{
          id: "color-class-1",
          name: "Color Class 1",
          type: "custom",
          value: "#CCCCCC"
        }, {
          id: "color-class-2",
          name: "Color Class 2",
          type: "custom",
          value: "#000000"
        }, {
          id: "color-class-3",
          name: "Color Class 3",
          type: "global-color",
          value: "4f9d0a5a-ad86-11ec-b909-0242ac120002"
        }, {
          id: "color-class-4",
          name: "Color Class 4",
          type: "global-color",
          value: "5842e846-ad86-11ec-b909-0242ac120002"
        }],
        gf1: {
          value: "Helvetica",
          category: "sans-serif",
          variants: ['100', '400', '400i', '700']
        },
        gf2: {
          value: "Times New Roman",
          category: "serif",
          variants: ['100', '400', '400i', '700']
        }
      }
    })
  });

  _exports.default = _default;
});