define("lightway-connect/routes/sites/site/settings/apple-app-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsAppleAppConfigRoute extends Ember.Route {
    model() {
      return {};
    }

  }

  _exports.default = SitesSiteSettingsAppleAppConfigRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsAppleAppConfigRoute, true);
});