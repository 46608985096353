define("lightway-connect/mirage/factories/mail-message", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Mail Message ${i}`;
    },

    subject: "Message subject",
    preferences: {}
  });

  _exports.default = _default;
});