define("lightway-connect/models/mail-message", ["exports", "@ember-data/model", "lightway-connect/mixins/style-parentable", "lightway-connect/mixins/part-parentable"], function (_exports, _model, _styleParentable, _partParentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MailMessageModel = (_dec = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec2 = (0, _model.belongsTo)({
    async: false
  }), _dec3 = (0, _model.hasMany)('user-kind'), _dec4 = (0, _model.hasMany)('user-kind'), _dec5 = (0, _model.hasMany)('user-kind'), _dec6 = (0, _model.hasMany)('user-kind'), _dec7 = (0, _model.hasMany)('user-kind', {
    async: false,
    inverse: 'paymentConfirmationMessage'
  }), _dec8 = (0, _model.hasMany)('user-kind', {
    async: false,
    inverse: 'paymentFailureMessage'
  }), _dec9 = (0, _model.hasMany)('user-kind', {
    async: false,
    inverse: 'trialEndingMessage'
  }), _dec10 = (0, _model.hasMany)('user-kind', {
    async: false,
    inverse: 'trialEndedMessage'
  }), _dec11 = (0, _model.hasMany)('mailing-list', {
    async: false
  }), _dec12 = (0, _model.hasMany)('logged-mail-message', {
    async: true,
    inverse: 'mailMessage'
  }), _dec13 = Ember.computed, _dec14 = Ember.computed, classic(_class = (_class2 = (_temp = class MailMessageModel extends _model.default.extend(_styleParentable.default, _partParentable.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "subject", _descriptor2, this);

      _initializerDefineProperty(this, "kind", _descriptor3, this);

      _initializerDefineProperty(this, "preferences", _descriptor4, this);

      _initializerDefineProperty(this, "site", _descriptor5, this);

      _initializerDefineProperty(this, "mailTemplate", _descriptor6, this);

      _initializerDefineProperty(this, "registrationConfirmationMessageUserKinds", _descriptor7, this);

      _initializerDefineProperty(this, "reverseRegistrationConfirmationMessageUserKinds", _descriptor8, this);

      _initializerDefineProperty(this, "passwordChangeMessageUserKinds", _descriptor9, this);

      _initializerDefineProperty(this, "passwordResetMessageUserKinds", _descriptor10, this);

      _initializerDefineProperty(this, "paymentConfirmationMessageUserKinds", _descriptor11, this);

      _initializerDefineProperty(this, "paymentFailureMessageUserKinds", _descriptor12, this);

      _initializerDefineProperty(this, "trialEndingMessageUserKinds", _descriptor13, this);

      _initializerDefineProperty(this, "trialEndedMessageUserKinds", _descriptor14, this);

      _initializerDefineProperty(this, "recipients", _descriptor15, this);

      _initializerDefineProperty(this, "loggedMailMessages", _descriptor16, this);
    }

    get currentVersion() {
      return this;
    }

    get globalStyle() {
      return this.mailTemplate.currentVersion.globalStyle;
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "subject", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "kind", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "site", [_model.belongsTo], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "registrationConfirmationMessageUserKinds", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "reverseRegistrationConfirmationMessageUserKinds", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "passwordChangeMessageUserKinds", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "passwordResetMessageUserKinds", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "paymentConfirmationMessageUserKinds", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "paymentFailureMessageUserKinds", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "trialEndingMessageUserKinds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "trialEndedMessageUserKinds", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "recipients", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "loggedMailMessages", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "currentVersion", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "currentVersion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "globalStyle", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "globalStyle"), _class2.prototype)), _class2)) || _class);
  _exports.default = MailMessageModel;
});