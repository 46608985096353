define("lightway-connect/controllers/sites/site/settings/data-kinds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    dataKinds: Ember.computed('model', function () {
      let dataKinds = Ember.A([]);
      this.model.forEach(p => {
        if (!p.parent) {
          dataKinds.pushObject(p);
        }
      });
      return dataKinds;
    }),
    actions: {
      save(dataKind) {
        this.target.send('save', dataKind);
      },

      delete(dataKind) {
        dataKind.destroyRecord().then(() => {
          this.flashMessages.clearMessages().success('Data Kind deleted');
        });
      }

    }
  });

  _exports.default = _default;
});