define("lightway-connect/components/content-editor/check-box-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    tagName: '',
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      toggleCheckbox(event, value) {
        let arr = Ember.get(this.model, this.propertyPath); // Keep original values for later

        let origArray = Ember.A([]);
        arr.forEach(item => {
          origArray.addObject(item);
        });
        let checked = event.target.checked; // event.preventDefault()

        let newArray = Ember.A([]);
        arr.forEach(item => {
          newArray.addObject(item);
        }); // if (checked && !arr.includes(value)) {
        //   newArray.addObject(value)
        // } else if (arr.includes(value)) {
        //   newArray.removeObject(value)
        // }

        let isIncluded = false;
        arr.forEach(itm => {
          if (value === itm) {
            isIncluded = true;
          }
        });

        if (checked) {
          if (!isIncluded) {
            // add to new array if not already included
            newArray.addObject(value);
          }
        } else {
          if (isIncluded) {
            // remove from new array
            newArray.removeObject(value);
          }
        }

        let changes = {};
        changes[this.propertyPath] = newArray;
        let editHash = this.editorService.prepareEdit(this.model, changes);
        editHash.originalValues[this.propertyPath] = origArray;
        this.editorService.addModifications(Ember.A([editHash]));
      }

    }
  });

  _exports.default = _default;
});