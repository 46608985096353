define("lightway-connect/components/form/date-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    Input=(
      component 'form/input'
      type="date"
      fieldId=@fieldId
      property=@property
      value=this.finalValue
      updateValue=(action 'updateValue')
    )
    TimezoneDescription=this.timeZoneDescription
  )}}
  */
  {
    id: "UEkK7+2K",
    block: "{\"symbols\":[\"@property\",\"@fieldId\",\"&default\"],\"statements\":[[14,3,[[28,\"hash\",null,[[\"Input\",\"TimezoneDescription\"],[[28,\"component\",[\"form/input\"],[[\"type\",\"fieldId\",\"property\",\"value\",\"updateValue\"],[\"date\",[23,2,[]],[23,1,[]],[23,0,[\"finalValue\"]],[28,\"action\",[[23,0,[]],\"updateValue\"],null]]]],[23,0,[\"timeZoneDescription\"]]]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/date-input.hbs"
    }
  });

  let FormDateInputComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class FormDateInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "finalValue", _descriptor, this);

      let value = Ember.get(this.args.model, this.args.property);

      if (value) {
        let localDate = new Date(value);
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        this.finalValue = localDate.toISOString().slice(0, 10);
      }
    }

    get timeZoneDescription() {
      const today = new Date();
      const short = today.toLocaleDateString(undefined);
      const full = today.toLocaleDateString(undefined, {
        timeZoneName: 'long'
      }); // Try to remove date from the string in a locale-agnostic way

      const shortIndex = full.indexOf(short);

      if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length); // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides

        return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
      } else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return full;
      }
    }

    updateValue(newValue) {
      let newDate = new Date(newValue); // Update final value

      this.finalValue = newValue;

      if (this.args.updateValue) {
        this.args.updateValue(event, newDate);
      } else {
        // Update model value
        Ember.set(this.args.model, this.args.property, newDate);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finalValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormDateInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormDateInputComponent);
});