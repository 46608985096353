define("lightway-connect/mirage/factories/page-version", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    versionName(i) {
      return `Version ${i + 1}`;
    },

    name: 'Page 1',
    permalink: 'page-1',
    dynamic: false,
    preferences: {}
  });

  _exports.default = _default;
});