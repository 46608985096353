define("lightway-connect/components/data-item-field", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    kind=this.kind
    id=this.id
    identifier=this.identifier
    camelizedIdentifier=this.camelizedIdentifier
    secondaryIdentifier=this.secondaryIdentifier
    property=this.property
    parent=this.parent
    subjectKind=this.subjectKind
    label=this.label
    isReverseField=this.isReverseField
    queryKey=this.queryKey
    isTextField=this.isTextField
    isEmailField=this.isEmailField
    isPhoneField=this.isPhoneField
    isExtendedTextField=this.isExtendedTextField
    isDateField=this.isDateField
    isTimeField=this.isTimeField
    isCurrencyField=this.isCurrencyField
    isLinkField=this.isLinkField
    isImageField=this.isImageField
    isDocumentField=this.isDocumentField
    isAssociationField=this.isAssociationField
    isEditableArrayFieldWithDefaultable=this.isEditableArrayFieldWithDefaultable
    associateProperty=this.associateProperty
    associationLimit=this.associationLimit
    associates=this.associates
  )}}
  */
  {
    id: "huNlCv7v",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"kind\",\"id\",\"identifier\",\"camelizedIdentifier\",\"secondaryIdentifier\",\"property\",\"parent\",\"subjectKind\",\"label\",\"isReverseField\",\"queryKey\",\"isTextField\",\"isEmailField\",\"isPhoneField\",\"isExtendedTextField\",\"isDateField\",\"isTimeField\",\"isCurrencyField\",\"isLinkField\",\"isImageField\",\"isDocumentField\",\"isAssociationField\",\"isEditableArrayFieldWithDefaultable\",\"associateProperty\",\"associationLimit\",\"associates\"],[[23,0,[\"kind\"]],[23,0,[\"id\"]],[23,0,[\"identifier\"]],[23,0,[\"camelizedIdentifier\"]],[23,0,[\"secondaryIdentifier\"]],[23,0,[\"property\"]],[23,0,[\"parent\"]],[23,0,[\"subjectKind\"]],[23,0,[\"label\"]],[23,0,[\"isReverseField\"]],[23,0,[\"queryKey\"]],[23,0,[\"isTextField\"]],[23,0,[\"isEmailField\"]],[23,0,[\"isPhoneField\"]],[23,0,[\"isExtendedTextField\"]],[23,0,[\"isDateField\"]],[23,0,[\"isTimeField\"]],[23,0,[\"isCurrencyField\"]],[23,0,[\"isLinkField\"]],[23,0,[\"isImageField\"]],[23,0,[\"isDocumentField\"]],[23,0,[\"isAssociationField\"]],[23,0,[\"isEditableArrayFieldWithDefaultable\"]],[23,0,[\"associateProperty\"]],[23,0,[\"associationLimit\"]],[23,0,[\"associates\"]]]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/data-item-field.hbs"
    }
  });

  let DataItemFieldComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class DataItemFieldComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentSite", _descriptor2, this);

      _initializerDefineProperty(this, "associates", _descriptor3, this);

      if (this.args.loadAssociates) {
        this.loadAssociates();
      }
    }

    get kind() {
      return this.args.field.kind;
    }

    get id() {
      return this.args.field.id;
    }

    get identifier() {
      return this.args.field.identifier;
    }

    get secondaryIdentifier() {
      return this.args.field.secondaryIdentifier;
    }

    get property() {
      return this.args.property;
    }

    get parent() {
      return this.args.field.parent;
    }

    get subjectKind() {
      return this.args.field.subjectKind;
    }

    get label() {
      if (this.isReverseField) {
        return this.args.field.reverseLabel;
      }

      return this.args.field.label;
    }

    get isReverseField() {
      if (!this.isAssociationField) {
        return false;
      } // If field belongs to a different parent than the item,
      // it is typically a reverse field


      if (this.args.field.parent.id != this.args.item.parent.id) {
        // If field's parent and field's subject kind are the same
        if (this.args.field.parent.id === this.args.field.subjectKind.id) {
          // TODO: should it always be false???
          return false;
        }

        return true;
      }

      return false;
    }

    get camelizedIdentifier() {
      if (this.isReverseField) {
        return (0, _lodash.camelCase)(this.secondaryIdentifier);
      } else {
        return (0, _lodash.camelCase)(this.identifier);
      }
    }

    get queryKey() {
      return this.camelizedIdentifier + "Page";
    } // ! Kind


    get isTextField() {
      return this.kind === "text-field";
    }

    get isBooleanField() {
      return this.kind === "boolean-field";
    }

    get isEmailField() {
      return this.kind === "email-field";
    }

    get isPhoneField() {
      return this.kind === "phone-field";
    }

    get isExtendedTextField() {
      return this.kind === "extended-text-field";
    }

    get isDateField() {
      return this.kind === "date-field";
    }

    get isTimeField() {
      return this.kind === "time-field";
    }

    get isCurrencyField() {
      return this.kind === "currency-field";
    }

    get isLinkField() {
      return this.kind === "link-field";
    }

    get isImageField() {
      return this.kind === "image-field";
    }

    get isDocumentField() {
      return this.kind === "document-field";
    }

    get isAssociationField() {
      return this.kind === "association-field";
    }

    get isEditableArrayFieldWithDefaultable() {
      return this.kind === "editable-array-field-with-defaultable";
    } // ! Associations


    loadAssociates() {
      if (!this.isAssociationField) {
        return null;
      }

      if (this.isReverseField) {
        // When Item's DataKind is NOT owner of association field
        // item should be the item of the association
        if (this.args.item.id) {
          // When is existing record
          this.store.query('data-associate', {
            filter: {
              field: this.args.field.id,
              item: this.args.item.id,
              site: this.currentSite.site.id
            }
          }).then(result => {
            this.associates = result; // If no associate match exists, create a new one

            if (this.associates.length === 0) {
              let newDataAssociate = this.store.createRecord('data-associate', {
                item: this.args.item,
                field: this.args.field
              });
              this.associates = Ember.A([newDataAssociate]);
            }
          });
        } else {
          // When is new record
          // If no associate match exists, create a new one
          if (this.associates.length === 0) {
            let newDataAssociate = this.store.createRecord('data-associate', {
              item: this.args.item,
              field: this.args.field
            });
            this.associates = Ember.A([newDataAssociate]);
          }
        }
      } else {
        // When Item's DataKind is owner of association field,
        // item should be the owner of the association
        if (this.args.item.id) {
          // When is existing record
          this.store.query('data-associate', {
            filter: {
              site: this.currentSite.site.id,
              field: this.args.field.id,
              owner: this.args.item.id
            }
          }).then(result => {
            this.associates = result; // If no associate match exists, create a new one

            if (this.associates.length === 0) {
              let newDataAssociate = this.store.createRecord('data-associate', {
                owner: this.args.item,
                field: this.args.field
              });
              this.associates = Ember.A([newDataAssociate]);
            }
          });
        } else {
          // When is new record
          // If no associate match exists, create a new one
          if (this.associates.length === 0) {
            let newDataAssociate = this.store.createRecord('data-associate', {
              item: this.args.item,
              field: this.args.field
            });
            this.associates = Ember.A([newDataAssociate]);
          }
        }
      }
    }

    get associationLimit() {
      if (!this.associationField) {
        return null;
      }

      if (this.isReverseField) {
        return this.args.field.reverseLimit;
      } else {
        return this.args.field.limit;
      }
    }

    get associateProperty() {
      if (!this.isAssociationField) {
        return null;
      } // IMPORTANT:
      // If reverse field, the property should definitely be 'owner'


      if (this.isReverseField) {
        return 'owner';
      } else {
        return 'item';
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentSite", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "associates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  })), _class));
  _exports.default = DataItemFieldComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DataItemFieldComponent);
});