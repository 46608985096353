define("lightway-connect/mirage/factories/data-item", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Text Property Value',
    // legacy value - change later
    permalink: 'item-1',
    enabled: true,
    preferences: {}
  });

  _exports.default = _default;
});