define("lightway-connect/components/content-editor/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      updateColor(hsva) {
        let changes = {};
        changes[this.propertyPath] = hsva.toHEXA().toString();
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }

    }
  });

  _exports.default = _default;
});