define("lightway-connect/mirage/serializers/role", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    include: ['permissions', 'site', 'invitations', 'contributors']
  });

  _exports.default = _default;
});