define("lightway-connect/components/content-editor/properties/prototype", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-canvas-settings'],
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.loadLayouts();
      this.loadDataKinds();
    },

    loadLayouts() {
      let site = this.get('currentSite.site');
      this.store.query('layout', {
        filter: {
          site: site.id,
          id: !null
        }
      }).then(results => {
        this.set('layouts', results);
      });
    },

    loadDataKinds() {
      let site = this.get('currentSite.site');
      this.store.query('data-kind', {
        filter: {
          site: site.id,
          id: !null,
          "content-enabled": true
        }
      }).then(results => {
        this.set('dataKinds', results.toArray());
      });
    }

  });

  _exports.default = _default;
});