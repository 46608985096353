define("lightway-connect/components/data-associates-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let owner;
      let item;

      if (this.reverseAssociation) {
        item = this.item.id;
      } else {
        owner = this.item.id;
      }

      this.store.query('data-associate', {
        filter: {
          site: this.currentSite.site.id,
          field: this.field.id,
          owner: owner,
          item: item
        }
      }).then(results => {
        this.set('dataAssociates', results);
      });
    },

    reverseAssociation: Ember.computed('field.parent', function () {
      return this.field.subjectKind === this.item.parent;
    }),
    actions: {
      deleteItem() {}

    }
  });

  _exports.default = _default;
});