define("lightway-connect/templates/sites/site/data-kinds/data-kind/categories/category/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uySAYcfO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"Delete Category\"],[9],[0,\"\\n\\nAre you sure you want to delete this category? All associated data will be permanently lost.\\n\\n\"],[7,\"button\",false],[12,\"data-test-delete-button\",\"\"],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"destroyCategory\"]],[8],[0,\"\\n  Delete Category\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/data-kinds/data-kind/categories/category/delete.hbs"
    }
  });

  _exports.default = _default;
});