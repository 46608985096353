define("lightway-connect/components/content-editor/properties/text", ["exports", "lightway-connect/validations/pages/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_text.default, {
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNames: ['current-part-settings'],
    withinDynamicContainer: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer;
    }),
    subject: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer.subject;
    }),
    subjectType: Ember.computed('model.currentDynamicContainer.preferences.subject-type', function () {
      let result;

      if (this.model.currentDynamicContainer.isA('page-version')) {
        let subjectType = this.model.currentDynamicContainer.get('preferences.subject-type');

        if (subjectType && subjectType != {}) {
          this.store.peekAll(subjectType.type).forEach(itm => {
            if (itm.id == subjectType.id) {
              result = itm;
            }
          });
        }
      } else if (this.model.currentDynamicContainer) {
        if (this.model.currentDynamicContainer.isA('data-kind')) {
          return this.model.currentDynamicContainer;
        }

        let subjectTypeId = this.model.currentDynamicContainer.get('preferences.subject-type.id');
        let subjectTypeType = this.model.currentDynamicContainer.get('preferences.subject-type.type');

        if (!subjectTypeId) {
          if (this.model.parent.parent.parent) {
            subjectTypeId = this.model.parent.parent.parent.get('preferences.subject-type.id');
            subjectTypeType = this.model.parent.parent.parent.get('preferences.subject-type.type');
          }
        }

        if (subjectTypeType && subjectTypeId) {
          result = this.store.peekRecord(subjectTypeType, subjectTypeId);
        }
      }

      return result;
    }),
    dynamicProperties: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.editorService.inKindMode || this.editorService.inItemMode || this.editorService.inEssentialContentItemMode) {
        let kindId = this.model.currentDynamicContainer.id;
        let kind = this.store.peekRecord('data-kind', kindId);
        let fields = Ember.A([]);
        kind.fields.forEach(field => {
          if (field.kind != 'association-field') {
            fields.addObject(field);
          }
        });
        return fields;
      }

      if (this.model.currentDynamicContainer.get('preferences.dynamic') === "collection") {
        let type = this.model.currentDynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          let kindId = this.model.currentDynamicContainer.get('preferences.subject-type.id');
          let kind = this.store.peekRecord('data-kind', kindId);
          let fields = Ember.A([]);
          kind.fields.forEach(field => {
            if (field.kind != 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      }

      if (this.model.currentDynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        return Ember.A([{
          id: "month",
          label: "Month",
          type: "property"
        }, {
          id: "year",
          label: "Year",
          type: "property"
        }, {
          id: "month-year",
          label: "Month & Year",
          type: "property"
        }]);
      }

      if (this.subjectType) {
        if (this.subjectType.type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name",
            type: "property"
          }]);
        } else {
          let fields = Ember.A([]);

          if (this.model.withinListItemContainer && this.model.subjectTypeObject) {
            this.model.subjectTypeObject.fields.forEach(field => {
              if (field.kind != 'association-field') {
                fields.addObject(field);
              }
            });
            return fields;
          }

          this.subjectType.fields.forEach(field => {
            if (field.kind != 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      } else if (this.subject) {
        let fields = Ember.A([]);
        this.subject.parent.fields.forEach(field => {
          if (field.kind != 'association-field') {
            fields.addObject(field);
          }
        });
        return fields;
      }

      return null;
    }),
    dynamicAssociations: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.subjectType) {
        let associations = Ember.A([]); //       if (this.subjectType.isA('part')) {
        //         this.subjectType.subjectTypeObject.allFields.forEach((field) => {
        //           if (field.kind === 'association-field') {
        //
        //             // If reverse field
        //             if (field.subjectKind != this.model.parent) {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: true
        //               })
        //             } else {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: false
        //               })
        //             }
        //
        //
        //           }
        //         });
        //       } else {
        //         this.subjectType.allFields.forEach((field) => {
        //           if (field.kind === 'association-field') {
        //
        //             // If reverse field
        //             if (field.subjectKind != this.model.parent) {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: true
        //               })
        //             } else {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: false
        //               })
        //             }
        //
        //
        //           }
        //         });
        //       }

        return associations;
      } else if (this.subject) {
        let associations = Ember.A([]);
        this.subject.parent.fields.forEach(field => {
          if (field.kind == 'association-field') {
            // If reverse field
            if (field.subjectKind != this.model.parent) {
              associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return associations;
      }

      return null;
    }),
    dynamicTextValue: Ember.computed('model.preferences.dynamic-value', function () {
      let result = "";
      let dVHash = this.model.get('preferences.dynamic-value');

      if (dVHash && dVHash.type && dVHash.id) {
        // if (dVHash['association-field-id']) {
        //   let associationField = this.store.peekRecord(
        //     'data-field', dVHash['association-field-id']);
        //   if (associationField) {
        //     result += associationField.label + " > "
        //   }
        // }
        if (dVHash.type == "data-field") {
          let field = this.store.peekRecord(dVHash.type, dVHash.id);

          if (field) {
            result += field.label;
          }
        } else if (dVHash.type == "property") {
          result += dVHash.id;
        }
      }

      return result;
    }),
    dynamicTextValueIsDate: Ember.computed('dynamicTextValue', function () {
      if (this.dynamicTextValue === "" || !this.dynamicTextValue) {
        return false;
      }

      let dVHash = this.model.get('preferences.dynamic-value');

      if (dVHash && dVHash.type && dVHash.id) {
        if (dVHash.type == "data-field") {
          let field = this.store.peekRecord(dVHash.type, dVHash.id);

          if (field && field.kind === "date-field") {
            return true;
          }
        }
      }

      return false;
    }),
    actions: {
      toggleModalForValue() {
        this.toggleProperty('selectingValue');
      },

      cancelValueSelection() {
        this.set('selectingValue', false);
      },

      selectValue(value, associationField) {
        let dynamicValue;

        if (this.model.currentDynamicContainer.get('preferences.dynamic') == "collection" && this.model.currentDynamicContainer.get('preferences.subject-type.type') == "pages") {
          dynamicValue = dynamicValue = {
            type: "property",
            id: value.id
          };
        } else {
          if (value.id === "month-year") {
            dynamicValue = {
              type: "property",
              id: value.id
            };
          } else {
            dynamicValue = {
              type: "data-field",
              id: value.id
            };
          }

          if (associationField) {
            dynamicValue['association-field-id'] = associationField.id;
          }
        }

        let changes = {};
        changes["preferences.dynamic-value"] = dynamicValue;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.set('selectingValue', false);
      },

      removeValue() {
        let changes = {};
        changes["preferences.dynamic-value"] = null;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }

    }
  });

  _exports.default = _default;
});