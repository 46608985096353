define("lightway-connect/services/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let FormErrorsService = classic(_class = class FormErrorsService extends Ember.Service {
    addErrors(formResults, changeset) {
      // Support both types of changesets
      if (formResults.errors) {
        formResults.errors.forEach(({
          source,
          detail
        }) => {
          let paths = source.pointer.split('/'); // let attr = paths[paths.length - 1]

          let attr = paths[paths.length - 1].split('_').join(' ');
          let msg = Ember.String.capitalize(attr) + " " + detail; // let msg = detail

          changeset.pushErrors(attr, msg);
        });
      } else if (formResults.errorSets) {//
      }
    }

  }) || _class; // import Service from '@ember/service';
  // // import { capitalize } from '@ember/string';
  //
  // export default Service.extend({
  //   addErrors(formResults, changeset) {
  //     // Support both types of changesets
  //     if (formResults.errors) {
  //       formResults.errors.forEach(({ source, detail }) => {
  //         let paths = source.pointer.split('/');
  //         let attr = paths[paths.length - 1]
  //         // let attr = paths[paths.length - 1].split('_').join(' ');
  //         // let msg = capitalize(attr) + " " + detail
  //         let msg = detail
  //         changeset.pushErrors(attr, msg);
  //       });
  //     } else if (formResults.errorSets) {
  //       //
  //     }
  //   }
  // });


  _exports.default = FormErrorsService;
});