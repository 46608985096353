define("lightway-connect/components/content-editor/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    tagName: '',
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.overrideHash) {
        return this.shortProperty;
      }

      if (this.propertyPathOverride) {
        return this.propertyPathOverride;
      }

      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      selectAction(value) {
        let changes = {};

        if (this.overrideHash) {
          let newOverrides = Ember.A([]);
          this.model.get(`preferences.${this.editorService.viewport.size}.overrides`).forEach(override => {
            if (Ember.get(override, 'part-id') === `${this.overrideHash['part-id']}`) {
              let newOverride = {};
              Object.keys(override).forEach(key => {
                newOverride[key] = override[key];
              });
              newOverride[this.propertyPath] = value;
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes[`preferences.${this.editorService.viewport.size}.overrides`] = newOverrides;
        } else {
          changes[this.propertyPath] = value;
        }

        let editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));

        if (this.afterSelect) {
          this.afterSelect(value);
        }
      }

    }
  });

  _exports.default = _default;
});