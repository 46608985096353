define("lightway-connect/models/hover-item", ["exports", "@ember-data/model", "lightway-connect/helpers/dynamic-style-property"], function (_exports, _model, _dynamicStyleProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HoverItem = (_dec = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec2 = (0, _model.belongsTo)('hoverItemRel', {
    inverse: 'hoverItems',
    polymorphic: true,
    async: false
  }), _dec3 = (0, _model.belongsTo)('hoverItemRel', {
    inverse: 'ownedHoverItems',
    polymorphic: true,
    async: false
  }), _dec4 = Ember.inject.service, _dec5 = Ember.computed('preferences.small'), _dec6 = Ember.computed('preferences.medium'), _dec7 = Ember.computed('preferences.large'), _dec8 = Ember.computed('preferences', 'smallPrefs', 'mediumPrefs', 'largePrefs'), classic(_class = (_class2 = (_temp = class HoverItem extends _model.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "preferences", _descriptor, this);

      _initializerDefineProperty(this, "item", _descriptor2, this);

      _initializerDefineProperty(this, "owner", _descriptor3, this);

      _initializerDefineProperty(this, "editorService", _descriptor4, this);
    }

    init() {
      super.init(...arguments); // Make sure preferences exist

      if (!this.preferences) {
        this.set('preferences', {});
      } // Initialise viewport if does not already exist


      if (!this.editorService.viewport) {
        this.editorService.selectViewport("small");
      } // Set viewport–scoped computed properties dynamically


      let properties = ['position-x', 'position-y', 'content-direction', 'width', 'width-type', 'height', 'height-type', 'background-color-type', 'background-color-value', 'background-image-reference', 'background-position', 'background-repeat', 'background-size', 'background-video-reference', 'background-gradient-direction', 'background-gradient-a-color-type', 'background-gradient-a-color-value', 'background-gradient-b-color-type', 'background-gradient-b-color-value', 'text-font-reference', 'text-size', 'text-variant', 'text-color-type', 'text-color-value', 'p-font-reference', 'p-size', 'p-variant', 'p-color-type', 'p-color-value', 'h1-font-reference', 'h1-size', 'h1-variant', 'h1-color-type', 'h1-color-value', 'h2-font-reference', 'h2-size', 'h2-variant', 'h2-color-type', 'h2-color-value', 'h3-font-reference', 'h3-size', 'h3-variant', 'h3-color-type', 'h3-color-value', 'h4-font-reference', 'h4-size', 'h4-variant', 'h4-color-type', 'h4-color-value', 'h5-font-reference', 'h5-size', 'h5-variant', 'h5-color-type', 'h5-color-value', 'h6-font-reference', 'h6-size', 'h6-variant', 'h6-color-type', 'h6-color-value', 'blockquote-font-reference', 'blockquote-size', 'blockquote-variant', 'blockquote-color-type', 'blockquote-color-value'];
      properties.forEach(property => {
        // Property scoped by current viewport
        // e.g. positionX, inheritedPositionX
        Ember.defineProperty(this, // propertyName
        Ember.String.camelize(property), (0, _dynamicStyleProperty.dynamicStyleProperty)('preferences', property)); // Inherited property

        Ember.defineProperty(this, // inheritedPropertyName
        `inherited${Ember.String.capitalize(Ember.String.camelize(property))}`, (0, _dynamicStyleProperty.dynamicStyleProperty)('preferences', property, 'inherited'));
      });
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get smallPrefs() {
      if (Object.keys(this.preferences).length < 1) {
        return null;
      }

      if (!this.get('preferences.small')) {
        return null;
      }

      if (Object.keys(this.get('preferences.small')).length < 1) {
        return null;
      }

      return this.get('preferences.small');
    }

    get mediumPrefs() {
      if (Object.keys(this.preferences).length < 1) {
        return null;
      }

      if (!this.get('preferences.medium')) {
        return null;
      }

      if (Object.keys(this.get('preferences.medium')).length < 1) {
        return null;
      }

      return this.get('preferences.medium');
    }

    get largePrefs() {
      if (Object.keys(this.preferences).length < 1) {
        return null;
      }

      if (!this.get('preferences.large')) {
        return null;
      }

      if (Object.keys(this.get('preferences.large')).length < 1) {
        return null;
      }

      return this.get('preferences.large');
    }

    get preferencesPresent() {
      if (this.smallPrefs || this.mediumPrefs || this.largePrefs) {
        return true;
      } else {
        return false;
      }
    }

    removePreference(key) {
      let viewport = this.editorService.viewport;

      if (!viewport) {
        return false;
      }

      let viewportSize = viewport.size;

      if (!this.get(`preferences.${viewportSize}`)) {
        return false;
      }

      this.set(`preferences.${viewportSize}.${key}`, null);
      delete this.preferences[viewportSize][key];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "item", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "owner", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "editorService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "smallPrefs", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "smallPrefs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mediumPrefs", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "mediumPrefs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "largePrefs", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "largePrefs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "preferencesPresent", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "preferencesPresent"), _class2.prototype)), _class2)) || _class);
  _exports.default = HoverItem;
});