define("lightway-connect/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="grid-x">
    <div class="cell small-12">
      <ul class="menu footer-menu">
        <li>
          <a href="https://lightwaydesign.com">
            &copy; 2022 Lightway Design
          </a>
        </li>
  
        <li>
          <LinkTo @route="policies">
            Policies
          </LinkTo>
        </li>
  
        <li>
          <a href="https://lightwaydesign.com">
            Support
          </a>
        </li>
      </ul>
    </div>
  </footer>
  
  {{yield}}
  
  */
  {
    id: "LDp6QZVD",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"footer\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"menu footer-menu\"],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"href\",\"https://lightwaydesign.com\"],[8],[0,\"\\n          \xA9 2022 Lightway Design\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"policies\"]],{\"statements\":[[0,\"\\n          Policies\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"href\",\"https://lightwaydesign.com\"],[8],[0,\"\\n          Support\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/footer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});