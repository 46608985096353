define("lightway-connect/components/editor/title-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell shrink content-editor-header">
    <div class="grid-x">
      <div class="cell auto">
        <div class="grid-x grid-frame align-center">
          <div class="cell auto">
            <div class="grid-x full-height align-middle">
              <div class="cell shrink">
                {{yield}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    id: "jE2BGVDE",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell shrink content-editor-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-frame align-center\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height align-middle\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n              \"],[14,1],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/title-bar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});