define("lightway-connect/routes/accounts/registration-confirmations/registration-confirmation", ["exports", "fetch", "lightway-connect/validations/resend-token-validations"], function (_exports, _fetch, _resendTokenValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RegistrationConfirmationRoute = ( // accept: 'application/json;version=1',
  _dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = (_temp = class RegistrationConfirmationRoute extends Ember.Route.extend(_resendTokenValidations.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "formErrors", _descriptor2, this);
    }

    model(params) {
      new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)(`/api/backend/accounts/confirmation?confirmation_token=${params.confirmation_token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.flashMessages.clearMessages().success('Registration completed. Please sign in.');
              this.transitionTo('sign-in');
              return resolve();
            });
          } else {
            response.json().then(responseJson => {
              var err; // Prepare error message for displaying

              if (responseJson.confirmation_token) {
                err = `Confirmation token ${responseJson.confirmation_token}`;
              } else if (responseJson.email) {
                err = `Email ${responseJson.email}`;
              }

              Ember.run(() => {
                // Render the error
                this.flashMessages.clearMessages().alert("There was an error");
                this.set('controller.errorMessage', err); // If email has already been confirmed, transition to sign in page

                if (responseJson.email) {
                  this.flashMessages.clearMessages().alert(err);
                  this.transitionTo('sign-in');
                } else {
                  // Otherwise show error and resend button
                  this.set('controller.tokenInvalid', true);
                  this.flashMessages.clearMessages().alert(err);
                }

                return reject();
              });
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert("There was an error");
            return reject();
          });
        });
      });
      return params.confirmation_token;
    }

    resendToken(confirmation_token) {
      this.set('controller.errorMessage', null);
      this.set('controller.tokenInvalid', null);
      new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)('/api/backend/confirmation', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            "data": {
              "type": "confirmations",
              "id": confirmation_token
            }
          })
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.set('controller.confirmationMessage', true);
              this.flashMessages.clearMessages().success('Confirmation email resent');
              resolve();
            });
          } else {
            Ember.run(() => {
              this.flashMessages.clearMessages().alert("There was an error");
              this.set('controller.emailIsNeeded', true);
              this.set('controller.errorMessage', 'Confirmation token is invalid');
              reject();
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            return reject();
          });
        });
      });
    }

    resendTokenForEmail() {
      this.set('controller.errorMessage', null);
      this.set('controller.tokenInvalid', null);
      let email = this.get('controller.email');
      new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)('/api/backend/accounts/confirmation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            "data": {
              "type": "confirmations",
              "attributes": {
                "email": email
              }
            }
          })
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.set('controller.confirmationMessage', true);
              this.set('controller.emailIsNeeded', false);
              this.flashMessages.clearMessages().success('Confirmation email resent');
              resolve();
            });
          } else {
            response.json().then(responseJson => {
              var err; // Prepare error message for displaying

              if (responseJson.errors[0].detail) {
                if (responseJson.errors[0].detail == "already confirmed") {
                  this.set('controller.tokenInvalid', false);
                  this.set('controller.emailIsNeeded', false); // this.transitionTo('sign-in');
                } else {
                  this.set('controller.emailIsNeeded', true);
                }

                err = `Email ${responseJson.errors[0].detail}`;
              }

              Ember.run(() => {
                this.flashMessages.clearMessages().alert(err);
                this.set('controller.errorMessage', err);
                reject();
              });
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            return reject();
          });
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "resendToken", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "resendToken"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resendTokenForEmail", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "resendTokenForEmail"), _class2.prototype)), _class2)) || _class);
  _exports.default = RegistrationConfirmationRoute;
});