define("lightway-connect/models/style", ["exports", "@ember-data/model", "lightway-connect/mixins/parent-styleable", "lightway-connect/mixins/hover-item-rel", "lightway-connect/helpers/dynamic-style-property"], function (_exports, _model, _parentStyleable, _hoverItemRel, _dynamicStyleProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Style = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec3 = (0, _model.belongsTo)({
    inverse: 'globalStyle'
  }), _dec4 = (0, _model.belongsTo)({
    inverse: 'globalStyle'
  }), _dec5 = (0, _model.hasMany)(), _dec6 = (0, _model.hasMany)('part', {
    inverse: 'removedStyles',
    async: false
  }), _dec7 = (0, _model.hasMany)(), _dec8 = (0, _model.hasMany)('prototype', {
    inverse: 'removedStyles',
    async: false
  }), _dec9 = (0, _model.hasMany)({
    inverse: 'globalStyle'
  }), _dec10 = (0, _model.belongsTo)('site', {
    inverse: 'globalPalette',
    async: false
  }), _dec11 = Ember.inject.service, _dec12 = Ember.computed, _dec13 = Ember.computed(), _dec14 = Ember.computed('gFontsToWatch.@each'), classic(_class = (_class2 = (_temp = class Style extends _model.default.extend(_parentStyleable.default, _hoverItemRel.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "styleProperties", _descriptor2, this);

      _initializerDefineProperty(this, "layoutVersion", _descriptor3, this);

      _initializerDefineProperty(this, "mailTemplateVersion", _descriptor4, this);

      _initializerDefineProperty(this, "parts", _descriptor5, this);

      _initializerDefineProperty(this, "formerParts", _descriptor6, this);

      _initializerDefineProperty(this, "prototypes", _descriptor7, this);

      _initializerDefineProperty(this, "formerPrototypes", _descriptor8, this);

      _initializerDefineProperty(this, "dataItems", _descriptor9, this);

      _initializerDefineProperty(this, "globalPaletteSite", _descriptor10, this);

      _initializerDefineProperty(this, "editorService", _descriptor11, this);
    }

    get tempId() {
      if (!this.id) {
        return Ember.guidFor(this);
      } else {
        return null;
      }
    }

    get globalColors() {
      let gColors = Ember.A([]);

      for (var key in this.styleProperties) {
        this.setGlobalColor(key, gColors);
      }

      return gColors;
    }

    setGlobalColor(key, gColors) {
      if (key.charAt(0) === 'g' && key.charAt(1) === 'c') {
        let gCObject = this.get(`styleProperties.${key}`);
        this.set(`styleProperties.${key}.id`, key);
        gColors.pushObject(gCObject);
      }
    }

    get globalFonts() {
      let gFonts = Ember.A([]);

      for (var key in this.styleProperties) {
        this.setGlobalFont(key, gFonts);
      }

      return gFonts;
    }

    setGlobalFont(key, gFonts) {
      if (key.charAt(0) === 'g' && key.charAt(1) === 'f') {
        let gFObject = this.get(`styleProperties.${key}`);
        this.set(`styleProperties.${key}.id`, key);
        gFonts.pushObject(gFObject);
      }
    }

    init() {
      super.init(...arguments);
      this.set('gColorsToWatch', Ember.A([]));
      this.set('gFontsToWatch', Ember.A([]));

      for (var key in this.styleProperties) {
        // // Colors
        // if (key.charAt(0) === 'g' && key.charAt(1) === 'c') {
        //   this.gColorsToWatch.pushObject(key)
        // }
        // Fonts
        if (key.charAt(0) === 'g' && key.charAt(1) === 'f') {
          this.gFontsToWatch.pushObject(key);
        }
      } // Make sure style-properties exist


      if (!this.styleProperties) {
        this.set('styleProperties', {});
      } // Initialise viewport if does not already exist


      if (!this.editorService.viewport) {
        this.editorService.selectViewport("small");
      } // Set viewport–scoped computed properties dynamically


      let properties = ['hidden', 'position-x', 'position-y', 'content-direction', 'width', 'width-type', 'max-width', 'height', 'height-type', 'margin', 'margin-top', 'margin-right', 'margin-bottom', 'margin-left', 'padding', 'padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'border', 'border-top', 'border-right', 'border-bottom', 'border-left', 'border-color-type', 'border-color-value', 'border-radius', 'border-radius-top-left', 'border-radius-top-right', 'border-radius-bottom-right', 'border-radius-bottom-left', 'background-color-type', 'background-color-value', 'background-image-reference', 'background-position', 'background-repeat', 'background-size', 'background-video-reference', 'background-gradient-direction', 'background-gradient-a-color-type', 'background-gradient-a-color-value', 'background-gradient-b-color-type', 'background-gradient-b-color-value', 'border-color-type', 'border-color-value', 'text-font-reference', 'text-size', 'text-variant', 'text-color-type', 'text-color-value', 'text-case', 'p-font-reference', 'p-size', 'p-variant', 'p-color-type', 'p-color-value', 'p-case', 'h1-font-reference', 'h1-size', 'h1-variant', 'h1-color-type', 'h1-color-value', 'h1-case', 'h2-font-reference', 'h2-size', 'h2-variant', 'h2-color-type', 'h2-color-value', 'h2-case', 'h3-font-reference', 'h3-size', 'h3-variant', 'h3-color-type', 'h3-color-value', 'h3-case', 'h4-font-reference', 'h4-size', 'h4-variant', 'h4-color-type', 'h4-color-value', 'h4-case', 'h5-font-reference', 'h5-size', 'h5-variant', 'h5-color-type', 'h5-color-value', 'h5-case', 'h6-font-reference', 'h6-size', 'h6-variant', 'h6-color-type', 'h6-color-value', 'h6-case', 'blockquote-font-reference', 'blockquote-size', 'blockquote-variant', 'blockquote-color-type', 'blockquote-color-value', 'blockquote-case'];
      properties.forEach(property => {
        // Property scoped by current viewport
        // e.g. positionX, inheritedPositionX
        Ember.defineProperty(this, // propertyName
        Ember.String.camelize(property), (0, _dynamicStyleProperty.dynamicStyleProperty)('styleProperties', property)); // Inherited property

        Ember.defineProperty(this, // inheritedPropertyName
        `inherited${Ember.String.capitalize(Ember.String.camelize(property))}`, (0, _dynamicStyleProperty.dynamicStyleProperty)('styleProperties', property, 'inherited'));
      });
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "styleProperties", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "layoutVersion", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "mailTemplateVersion", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "parts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "formerParts", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "prototypes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "formerPrototypes", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "dataItems", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "globalPaletteSite", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "editorService", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "tempId", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "tempId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "globalColors", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "globalColors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "globalFonts", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "globalFonts"), _class2.prototype)), _class2)) || _class);
  _exports.default = Style;
});