define("lightway-connect/routes/sites/site/design/layouts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDesignLayoutsRoute extends Ember.Route {}

  _exports.default = SitesSiteDesignLayoutsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDesignLayoutsRoute, true);
});