define("lightway-connect/templates/components/content-editor/position-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXq6ce2x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"if\",[[23,0,[\"currentPosition\"]],[28,\"action\",[[23,0,[]],\"removePosition\"],null],[28,\"action\",[[23,0,[]],\"setPosition\",[23,0,[\"positionX\"]],[23,0,[\"positionY\"]]],null]],null]],[11,\"class\",[29,[\"position-control position-\",[23,0,[\"positionName\"]],\" \",[28,\"if\",[[23,0,[\"currentPosition\"]],\"current-position\"],null],\" \",[28,\"if\",[[23,0,[\"inheritedPosition\"]],\"inherited-position\"],null]]]],[11,\"data-test-id\",[29,[[23,0,[\"positionName\"]],\"-position\"]]],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/position-control.hbs"
    }
  });

  _exports.default = _default;
});