define("lightway-connect/components/content-editor/page-link", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    subjectType: Ember.computed('model.pageLink.page.preferences.subject-type.type', function () {
      if (this.model.pageLink && this.model.pageLink.page) {
        return (0, _emberInflector.pluralize)(this.model.pageLink.page.get('preferences.subject-type.type'));
      }

      return null;
    }),
    dynamicContainer: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer;
    }),
    withinDynamicContainer: Ember.computed('dynamicContainer', function () {
      return this.dynamicContainer;
    }),
    dynamicProperties: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.dynamicContainer.get('preferences.dynamic') == "collection" || this.dynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        let type = this.dynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          let kindId = this.dynamicContainer.get('preferences.subject-type.id');
          let kind = this.store.peekRecord('data-kind', kindId);
          let fields = Ember.A([]);
          kind.fields.forEach(field => {
            if (field.kind != 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      }

      if (this.subjectType) {
        if (this.subjectType.type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name",
            type: "property"
          }]);
        } else {
          // return this.subjectType.fields
          let fields = Ember.A([]);
          this.subjectType.fields.forEach(field => {
            if (field.kind === 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      } else if (this.subject) {
        // return this.subject.parent.fields
        let fields = Ember.A([]);
        this.subject.parent.fields.forEach(field => {
          if (field.kind != 'association-field') {
            fields.addObject(field);
          }
        });
        return fields;
      } else if (this.model.parent.currentDynamicContainer.get('preferences.dynamic') == "collection" || this.model.parent.currentDynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        let type = this.model.parent.currentDynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          let kindId = this.model.parent.currentDynamicContainer.get('preferences.subject-type.id');
          let kind = this.store.peekRecord('data-kind', kindId);
          let fields = Ember.A([]);
          kind.fields.forEach(field => {
            if (field.kind === 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      } else {
        return null;
      }

      return null;
    }),
    dynamicLinkProperty: Ember.computed('model.preferences.dynamic-link-property.id', function () {
      if (this.model.get('preferences.dynamic-link-property')) {
        let propertyId = this.model.get('preferences.dynamic-link-property.id');

        if (propertyId) {
          return this.store.peekRecord('data-field', propertyId);
        }
      }

      return null;
    }),
    dynamicAssociations: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.subjectType) {
        let associations = Ember.A([]);
        this.subjectType.allFields.forEach(field => {
          if (field.kind === 'association-field') {
            // If reverse field
            if (field.subjectKind != this.model.parent) {
              associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return associations;
      } else if (this.subject) {
        let associations = Ember.A([]);
        this.subject.parent.fields.forEach(field => {
          if (field.kind == 'association-field') {
            // If reverse field
            if (field.subjectKind != this.model.parent) {
              associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return associations;
      }

      return null;
    }),
    actions: {
      // For internal page link
      toggleModalForPageLinkPage() {
        this.toggleProperty('selectingPage');
      },

      selectPageForLink(page) {
        let modifications = Ember.A([]);

        if (!this.model.pageLink) {
          let pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          let addHash = this.editorService.prepareAdd(pageLink);
          modifications.addObject(addHash);
        }

        let editHash = this.editorService.prepareEdit(this.model.pageLink, {
          'page': page
        });
        modifications.addObject(editHash);
        this.editorService.addModifications(modifications);
        this.set('selectingPage', false);
      },

      cancelPageSelection() {
        this.set('selectingPage', false);
      },

      removePageLink() {
        this.model.pageLink.destroyRecord().then(() => {
          this.flashMessages.clearMessages().success('Link removed');
        }).catch(() => {
          this.flashMessages.clearMessages().alert('There was an error');
        });
      },

      // For dynamic page link
      toggleModalForPageLinkSubject() {
        this.toggleProperty('selectingSubject');
      },

      selectSubjectForLink(subject) {
        let editHash = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': subject
        });
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingSubject', false);
      },

      removePageLinkSubject() {
        let editHash;

        if (this.model.pageLink) {
          editHash = this.editorService.prepareEdit(this.model.pageLink, {
            'resource': null
          });
        } else {
          editHash = this.editorService.prepareEdit(this.model, {
            'preferences.dynamic-link-property': null
          });
        }

        this.editorService.addModifications(Ember.A([editHash]));
      },

      // For dynamic page link with subject as data item's link-field property
      toggleModalForLinkProperty() {
        this.toggleProperty('selectingLinkProperty');
      },

      cancelLinkPropertySelection() {
        this.set('selectingLinkProperty', false);
      },

      selectLinkProperty(value, associationField) {
        let dynamicValue;

        if ((this.dynamicContainer.get('preferences.dynamic') == "collection" || this.dynamicContainer.get('preferences.dynamic') == "grouped-collection") && this.dynamicContainer.get('preferences.subject-type.type') != "pages") {
          dynamicValue = {
            type: "data-field",
            id: value.id
          };

          if (associationField) {
            dynamicValue['association-field-id'] = associationField.id;
          }
        }

        let editHash = this.editorService.prepareEdit(this.model, {
          'preferences.dynamic-link-property': dynamicValue
        });
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingLinkProperty', false); //
      },

      // For document link
      toggleModalForPageLinkDocument() {
        this.toggleProperty('selectingDocument');
      },

      selectDocumentForLink(document) {
        let modifications = Ember.A([]);

        if (!this.model.pageLink) {
          let pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          let editHash1 = this.editorService.prepareEdit(this.model, {
            'pageLink': pageLink
          });
          modifications.addObject(editHash1);
        }

        let editHash2 = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': document
        });
        modifications.addObject(editHash2);
        this.editorService.addModifications(modifications);
        this.set('selectingDocument', false);
      },

      cancelDocumentSelection() {
        this.set('selectingDocument', false);
      },

      toggleModalForPageLinkPrototype() {
        this.toggleProperty('selectingPrototype');
      },

      selectPrototypeForLink(prototype) {
        let modifications = Ember.A([]);

        if (!this.model.pageLink) {
          let pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          let editHash1 = this.editorService.prepareEdit(this.model, {
            'pageLink': pageLink
          });
          modifications.addObject(editHash1);
        }

        let editHash2 = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': prototype
        });
        modifications.addObject(editHash2);
        this.editorService.addModifications(modifications);
        this.set('selectingPrototype', false);
      },

      cancelPrototypeSelection() {
        this.set('selectingPrototype', false);
      }

    }
  });

  _exports.default = _default;
});