define("lightway-connect/templates/components/delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "flKWuWzu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"modal-dialog\",[],[[\"@overlayClass\",\"@containerClass\",\"@onClose\"],[\"modal-bg-overlay grid-y align-center\",\"confirm-modal cell small-shrink\",[23,0,[\"toggleDeleteModal\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[14,1,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"blank-component\"],[[\"tagName\",\"data-test-id\"],[\"h3\",\"delete-modal-header\"]]]]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center confirm-dialog\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"message\"],[[28,\"component\",[\"blank-component\"],[[\"tagName\",\"class\"],[\"span\",\"cell small-12 confirm-message\"]]]]]]]],[0,\"\\n\\n    \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell shrink confirm-button\"],[12,\"data-test-id\",\"confirm\"],[3,\"action\",[[23,0,[]],[23,0,[\"deleteAction\"]]]],[8],[0,\"\\n      Confirm\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell shrink cancel-button\"],[12,\"data-test-id\",\"cancel\"],[3,\"action\",[[23,0,[]],[23,0,[\"toggleDeleteModal\"]]]],[8],[0,\"\\n      Cancel\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/delete-modal.hbs"
    }
  });

  _exports.default = _default;
});