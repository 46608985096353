define("lightway-connect/controllers/sites/site/content/screens/screen", ["exports", "lightway-connect/validations/update-screen-form"], function (_exports, _updateScreenForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    UpdateScreenFormValidations: _updateScreenForm.default,
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    selectedElement: Ember.inject.service(),
    toolsList: Ember.A(['container', 'text', 'image']),

    clearRemovedParts() {
      const self = this;
      let parts = this.model.get('removedParts');
      parts.forEach(function (object) {
        self.store.unloadRecord(object);
      });
    },

    deselectElement() {
      let obj = this.get('selectedElement.element'); // Unload selected element if pending image part

      if (obj && obj.get('isPendingImage')) {
        this.store.unloadRecord(obj);
      }

      this.selectedElement.deselect();
    },

    actions: {
      deselectSelection() {
        this.deselectElement();
      },

      dragToolEnd({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        let toolKind = sourceList[sourceIndex];

        if (sourceList === this.toolsList) {
          // Set up part based on tool kind
          let part = this.store.createRecord('part', {
            kind: toolKind
          }); // Updating rowOrder of items in target list

          targetList.slice(targetIndex).forEach(item => {
            const rowOrder = item.get('rowOrder');
            item.set('rowOrder', rowOrder + 1);
          }); // Updating rowOrder of new part

          part.set('rowOrder', targetIndex); // Add initial text to text part

          if (part.kind == 'text') {
            part.set('textContent', 'Type here...');
          } // Moving part into the target list
          // We can't do it directly because the list is a derived one,
          // so we need to access the original list using a hack from the model


          targetList.__parent__.get('parts').addObject(part); // const unsortableList = this.get('toolsList')
          // let item = sourceList.objectAt(sourceIndex)
          //
          // if (sourceList === unsortableList) item = {...item} // shallow clone
          // else sourceList.removeAt(sourceIndex)
          //
          // if (targetList !== unsortableList) targetList.insertAt(targetIndex, part)
          //
          // targetList.forEach(function(object, index){
          //   object.set('rowOrder', index)
          // })

        }
      },

      determineForeignPositionOfTools({
        /*draggedItem,*/
        items
      }) {
        // return draggedItem.position
        return items.length;
      },

      save(changesetObj) {
        // Remove any pending image parts
        this.store.peekAll('part').forEach(pt => {
          if (pt.get('kind') == "pending-image") this.store.unloadRecord(pt);
        });
        changesetObj.validate().then(() => {
          if (changesetObj.get("isValid")) {
            changesetObj.save().then(() => {
              // // Clear removed parts from store
              this.clearRemovedParts(); // // Clear selected element
              // this.deselectElement()
              // // Clear flash messages in case saved frequently

              this.flashMessages.clearMessages().success('Screen saved');
              this.transitionToRoute('sites.site.content.screens');
            }).catch(reason => {
              this.formErrors.addErrors(reason, changesetObj);
              this.model.rollbackAttributes();
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});