define("lightway-connect/templates/components/ui-form/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BoRRKeZg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"click\"],null]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"data-test-id\",[23,0,[\"type\"]]],[11,\"type\",[23,0,[\"type\"]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"task\",\"isRunning\"]]],null,{\"statements\":[[0,\"    running...\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/button.hbs"
    }
  });

  _exports.default = _default;
});