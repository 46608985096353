define("lightway-connect/templates/sites/site/media/image-folders/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zY78m2F6",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\",\"@model\"],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"\\n  New Image Folder\\n\"],[9],[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[23,4,[\"folder\"]],[23,0,[\"MediaFolderValidations\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"save\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Folder Name\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"textInput\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\\n    \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Save\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/image-folders/new.hbs"
    }
  });

  _exports.default = _default;
});