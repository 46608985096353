define("lightway-connect/controllers/sites/site/user-kinds/user-kind/index", ["exports", "lightway-connect/validations/roles/invitation-form", "fetch"], function (_exports, _invitationForm, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteUserKindsUserKindIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class SitesSiteUserKindsUserKindIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "userInvitationValidations", _invitationForm.default);

      _defineProperty(this, "queryParams", ['usersPage', 'pendingInvitesPage']);

      _defineProperty(this, "usersPage", 1);

      _defineProperty(this, "pendingInvitesPage", 1);

      _initializerDefineProperty(this, "currentSite", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);

      _initializerDefineProperty(this, "formErrors", _descriptor4, this);
    }

    downloadFile(a) {
      a.click();
    }

    resetInvitationForm() {
      let newInvitation = this.store.createRecord('user-invitation', {
        userKind: this.model.userKind
      });
      this.set('model.userInvitation', newInvitation);
    }

    exportAsCSV() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)(`/api/backend/users.csv?filter[site]=${this.currentSite.site.id}&&filter[user-kind]=${this.model.userKind.id}&&status=[active]`, {
          method: 'GET',
          headers: {
            "Content-Type": 'text/csv;charset=utf-8',
            "Accept": "text/csv;version=1",
            "Authorization": `Bearer ${this.session.data.authenticated.token}`
          }
        }).then(response => response.blob()).then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "users.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          // Download file

          this.downloadFile(a); // Remove from memory

          a.remove(); //afterwards we remove the element again

          resolve();
        }).catch(reason => {
          reject(reason);
        });
      });
    }

    sendInvitation(changesetObj) {
      changesetObj.validate().then(() => {
        if (changesetObj.get("isValid")) {
          changesetObj.save().then(() => {
            this.flashMessages.clearMessages().success('Invitation sent');
            this.resetInvitationForm();
          }).catch(reason => {
            this.flashMessages.clearMessages().alert('There was an error');
            this.formErrors.addErrors(reason, changesetObj);
          });
        } else {
          this.flashMessages.clearMessages().alert('There was an error');
        }
      });
    }

    deleteUser(user) {
      user.destroyRecord().then(() => {
        this.flashMessages.clearMessages().success('User deleted');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSite", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formErrors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "exportAsCSV", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "exportAsCSV"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendInvitation", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sendInvitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteUser", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "deleteUser"), _class.prototype)), _class));
  _exports.default = SitesSiteUserKindsUserKindIndexController;
});