define("lightway-connect/controllers/sites/site/web-forms/web-form/fields/field/index", ["exports", "lightway-connect/validations/web-forms/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    fieldValidations: _field.default,
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    actions: {
      saveField(changeset) {
        this.target.send('saveField', changeset);
      }

    }
  });

  _exports.default = _default;
});