define("lightway-connect/mirage/factories/image", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Image ${i}`;
    },

    imageUrl(i) {
      return `http://image${i}Url`;
    },

    width: 150,
    height: 100
  });

  _exports.default = _default;
});