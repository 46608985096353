define("lightway-connect/components/content-editor/parts/background-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['videoUrl:bgv-container'],
    store: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.backgroundVideoReference) {
        this.store.findRecord('document', this.backgroundVideoReference).then(video => {
          this.set('video', video);
        });
      }
    },

    didRender() {
      this._super(...arguments);

      let video = this.element.querySelector('.bgv-container video');

      if (video) {
        video.muted = true; // Return play as a promise to keep Chrome DevTools happy

        var playPromise = video.play();

        if (playPromise) {
          playPromise.then(() => {// Automatic playback started!
            // Show playing UI.
          }).catch(() => {// Auto-play was prevented
            // Show paused UI.
          });
        }
      }
    },

    backgroundVideoReference: Ember.computed('part.{backgroundVideoReference,inheritedBackgroundVideoReference}', function () {
      return this.part.backgroundVideoReference || this.part.inheritedBackgroundVideoReference;
    }),
    videoUrl: Ember.computed('video', function () {
      if (!this.video) {
        return null;
      }

      return this.video.documentUrl;
    })
  });

  _exports.default = _default;
});