define("lightway-connect/components/content-editor/property-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiForm as |Form|>
    <Form.field as |Field|>
      <Field.propertyInput
        @model={{@model}}
        @property={{this.propertyPath}}
        @propertyKinds={{@propertyKinds}}
        @currentPropertyId={{this.currentPropertyId}}
        @site={{@site}}
        @parent={{@parent}}
        @confirmAction={{action 'updateProperty'}} />
    </Form.field>
  </UiForm>
  */
  {
    id: "ooQwKGv0",
    block: "{\"symbols\":[\"Form\",\"Field\",\"@model\",\"@propertyKinds\",\"@site\",\"@parent\"],\"statements\":[[5,\"ui-form\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"field\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"propertyInput\"]],[],[[\"@model\",\"@property\",\"@propertyKinds\",\"@currentPropertyId\",\"@site\",\"@parent\",\"@confirmAction\"],[[23,3,[]],[23,0,[\"propertyPath\"]],[23,4,[]],[23,0,[\"currentPropertyId\"]],[23,5,[]],[23,6,[]],[28,\"action\",[[23,0,[]],\"updateProperty\"],null]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/property-input.hbs"
    }
  });

  let ContentEditorPropertyInputComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember.computed('editorService.viewport.size'), _dec4 = Ember.computed('args.currentPropertyId'), (_class = (_temp = class ContentEditorPropertyInputComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "editorService", _descriptor, this);
    }

    updateProperty(value) {
      let changes = {};
      changes[this.propertyPath] = value;
      let edit = this.editorService.prepareEdit(this.args.model, changes);
      this.editorService.addModifications(Ember.A([edit]));
    }

    get propertyPath() {
      if (this.args.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.args.shortProperty;
      } else {
        return this.args.property;
      }
    }

    get currentPropertyId() {
      return this.args.currentPropertyId;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editorService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProperty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "propertyPath", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "propertyPath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentPropertyId", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentPropertyId"), _class.prototype)), _class));
  _exports.default = ContentEditorPropertyInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentEditorPropertyInputComponent);
});