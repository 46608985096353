define("lightway-connect/routes/sites/site/data-kinds/data-kind/categories/category/categories/category/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ItemsRoute = classic(_class = class ItemsRoute extends Ember.Route {}) || _class;

  _exports.default = ItemsRoute;
});