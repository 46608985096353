define("lightway-connect/templates/sites/site/media/image-folders/image-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "134+nTcp",
    "block": "{\"symbols\":[\"image\",\"@model\"],\"statements\":[[7,\"h3\",true],[10,\"data-test-id\",\"subject-heading\"],[8],[0,\"\\n  \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"button-group\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"button\"],[12,\"data-test-id\",\"edit-folder\"]],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.edit\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n    Rename Folder\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"link-to\",[[12,\"class\",\"button\"],[12,\"data-test-id\",\"delete-folder\"]],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.delete\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n    Delete Folder\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"sites/media/upload-image-form\",[],[[\"@folder\"],[[23,2,[]]]]],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"menu\"],[10,\"data-test-images-list\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\",\"images\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"data-test-image-link-for\",[23,1,[\"id\"]]]],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.image\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"thumbUrl\"]]],[11,\"alt\",[23,1,[\"name\"]]],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/image-folders/image-folder.hbs"
    }
  });

  _exports.default = _default;
});