define("lightway-connect/templates/components/ui-form/multi-select-check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "plSh3Ia8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"ui-form/check-box\",[[12,\"id\",[23,0,[\"id\"]]],[12,\"onClick\",[23,0,[\"onClick\"]]],[12,\"data-test-check-box-for\",[23,0,[\"value\"]]]],[[\"@checked\"],[[23,0,[\"valueMatches\"]]]]],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/multi-select-check-box.hbs"
    }
  });

  _exports.default = _default;
});