define("lightway-connect/initializers/set-global-promise-for-async-await", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // This forces the async/await polyfill to use RSVP.Promise
    window.Promise = Ember.RSVP.Promise;
  }

  var _default = {
    name: 'set-global-promise-for-async-await',
    initialize
  };
  _exports.default = _default;
});