define("lightway-connect/templates/sites/site/content/screens/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aMzA0zXa",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-container full\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"top-bar section-head\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar-left\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"\\n        App Screens\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar-right\"],[8],[0,\"\\n      \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"screens\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"new-resource\"],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"data-test-link-for\",\"new-screen\"]],[[\"@route\"],[\"sites.site.content.screens.new\"]],{\"statements\":[[0,\"\\n            New Screen\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"screens\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"paginated-list\",[],[[\"@items\",\"@navRoute\"],[[23,1,[]],\"sites.site.content.screens\"]],{\"statements\":[[0,\"\\n\\n      \"],[5,\"sortable-list-menu\",[],[[\"@class\",\"@itemKind\",\"@navRoute\",\"@itemRoute\",\"@items\",\"@saveItemAction\",\"@data-test-list-for\"],[\"grid-x resource-list\",\"screens\",\"sites.site.content.screens\",\"sites.site.content.screens.screen\",[23,0,[\"screens\"]],[28,\"action\",[[23,0,[]],\"saveScreen\"],null],\"screens\"]]],[0,\"\\n\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/content/screens/index.hbs"
    }
  });

  _exports.default = _default;
});