define("lightway-connect/models/data-field", ["exports", "@ember-data/model", "lightway-connect/mixins/translation-parentable", "lodash"], function (_exports, _model, _translationParentable, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DataField = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec12 = (0, _model.belongsTo)('data-field-parentable', {
    polymorphic: true,
    async: false
  }), _dec13 = (0, _model.hasMany)('data-property'), _dec14 = (0, _model.hasMany)('dataKind', {
    inverse: 'defaultField',
    async: false
  }), _dec15 = (0, _model.hasMany)('userKind', {
    inverse: 'defaultField',
    async: false
  }), _dec16 = (0, _model.belongsTo)('data-field-parentable', {
    polymorphic: true,
    inverse: 'reverseAssociationFields',
    async: false
  }), _dec17 = (0, _model.hasMany)({
    inverse: 'field'
  }), _dec18 = (0, _model.hasMany)('data-kind', {
    inverse: 'ownerField',
    async: false
  }), _dec19 = (0, _model.belongsTo)('mail-message', {
    async: false
  }), _dec20 = (0, _model.belongsTo)('mail-message', {
    async: false
  }), _dec21 = (0, _model.belongsTo)('mail-message', {
    async: false
  }), _dec22 = Ember.computed('label'), _dec23 = Ember.computed('identifier'), classic(_class = (_class2 = (_temp = class DataField extends _model.default.extend(_translationParentable.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "label", _descriptor, this);

      _initializerDefineProperty(this, "reverseLabel", _descriptor2, this);

      _initializerDefineProperty(this, "kind", _descriptor3, this);

      _initializerDefineProperty(this, "rowOrder", _descriptor4, this);

      _initializerDefineProperty(this, "identifier", _descriptor5, this);

      _initializerDefineProperty(this, "secondaryIdentifier", _descriptor6, this);

      _initializerDefineProperty(this, "description", _descriptor7, this);

      _initializerDefineProperty(this, "limit", _descriptor8, this);

      _initializerDefineProperty(this, "reverseLimit", _descriptor9, this);

      _initializerDefineProperty(this, "required", _descriptor10, this);

      _initializerDefineProperty(this, "preferences", _descriptor11, this);

      _initializerDefineProperty(this, "parent", _descriptor12, this);

      _initializerDefineProperty(this, "properties", _descriptor13, this);

      _initializerDefineProperty(this, "dataKindsDefaulted", _descriptor14, this);

      _initializerDefineProperty(this, "userKindsDefaulted", _descriptor15, this);

      _initializerDefineProperty(this, "subjectKind", _descriptor16, this);

      _initializerDefineProperty(this, "dataAssociates", _descriptor17, this);

      _initializerDefineProperty(this, "ownedDataKinds", _descriptor18, this);

      _initializerDefineProperty(this, "userInvitationMessage", _descriptor19, this);

      _initializerDefineProperty(this, "reverseUserInvitationMessage", _descriptor20, this);

      _initializerDefineProperty(this, "associationRequestMessage", _descriptor21, this);
    }

    get name() {
      return this.label;
    }

    get camelizedIdentifier() {
      return (0, _lodash.camelCase)(this.identifier);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "reverseLabel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "kind", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "rowOrder", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "secondaryIdentifier", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "description", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "reverseLimit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "required", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "parent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "properties", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "dataKindsDefaulted", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "userKindsDefaulted", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "subjectKind", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "dataAssociates", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "ownedDataKinds", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "userInvitationMessage", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "reverseUserInvitationMessage", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "associationRequestMessage", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "name", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "name"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "camelizedIdentifier", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "camelizedIdentifier"), _class2.prototype)), _class2)) || _class);
  _exports.default = DataField;
});