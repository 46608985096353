define("lightway-connect/routes/sites/site/settings/contributors/roles/role/contributors/contributor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ContributorRoute = classic(_class = class ContributorRoute extends Ember.Route {
    model(params) {
      let role = this.modelFor('sites.site.settings.contributors.roles.role').role;
      let contributor = this.store.findRecord('contributor', params.contributor_id);
      return Ember.RSVP.hash({
        role: role,
        contributor: contributor
      });
    }

  }) || _class;

  _exports.default = ContributorRoute;
});