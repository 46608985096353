define("lightway-connect/routes/sites/site/media/document-folders/document-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DocumentFolderRoute = classic(_class = class DocumentFolderRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('documentFolder', params.folder_id);
    }

  }) || _class;

  _exports.default = DocumentFolderRoute;
});