define("lightway-connect/components/ui-form/check-box-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isChecked: Ember.computed('propertyValues.length', 'value', {
      get() {
        if (this._isChecked) {
          return this._isChecked;
        }

        if (this.propertyValues) {
          return this.propertyValues.includes(this.value);
        }

        return null;
      },

      set(key, value) {
        return this._isChecked = value;
      }

    })
  });

  _exports.default = _default;
});