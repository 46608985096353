define("lightway-connect/components/accounts/reset-password-form", ["exports", "fetch", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/accounts/reset-password-form"], function (_exports, _fetch, _emberChangeset, _emberChangesetValidations, _resetPasswordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resetPasswordForm.default, {
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('mod', {});
      let changeset = new _emberChangeset.default(this.mod, (0, _emberChangesetValidations.default)(_resetPasswordForm.default), _resetPasswordForm.default);
      this.set('changeset', changeset);
      this.lookupToken();
    },

    lookupToken() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return (0, _fetch.default)(`/api/backend/accounts/password/edit?reset_password_token=${this.token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then(response => {
          if (response.ok) {
            Ember.run(() => {
              this.flashMessages.clearMessages();
              this.set('approved', true);
              resolve();
            });
          } else {
            Ember.run(() => {
              this.set('approved', false);
              var err = "";
              response.json().then(responseJSON => {
                // Prepare error message for displaying
                if (responseJSON.errors) {
                  err = `Token ${responseJSON.errors[0].detail}`;
                } else {
                  err = "There was an error";
                }

                this.flashMessages.clearMessages().alert(err);
                this.router.transitionTo('reset-password');
                reject();
              });
            });
          }
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert("There was an error");
            reject();
          });
        });
      });
    },

    actions: {
      resetPassword() {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isValid')) {
            return new Ember.RSVP.Promise((resolve, reject) => {
              return (0, _fetch.default)('/api/backend/accounts/password', {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                  "reset_password_token": this.token,
                  "password": this.changeset.get('password'),
                  "password_confirmation": this.changeset.get('passwordConfirmation')
                })
              }).then(response => {
                if (response.ok) {
                  Ember.run(() => {
                    this.flashMessages.clearMessages().success('Password has been reset');
                    this.router.transitionTo('sign-in');
                    resolve();
                  });
                } else {
                  response.json().then(reason => {
                    this.addErrorsToChangeset(reason);
                    Ember.run(() => {
                      this.flashMessages.clearMessages().alert("There was an error");
                      reject();
                    });
                  });
                }
              }).catch(() => {
                Ember.run(() => {
                  this.flashMessages.clearMessages().alert("There was an error");
                  reject();
                });
              });
            });
          } else {
            this.flashMessages.clearMessages().alert('There was an error');
            return;
          }
        });
      }

    },

    addErrorsToChangeset(response) {
      response.errors.forEach(({
        source,
        detail
      }) => {
        let paths = source.pointer.split('/');
        let attr = paths[paths.length - 1].split('-').join(' ');
        this.changeset.pushErrors(attr, `${String(attr).capitalize()} ${detail}`);
      });
    }

  });

  _exports.default = _default;
});