define("lightway-connect/mirage/factories/account", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'id'(i) {
      return `abc${i}`;
    },

    'firstName'(i) {
      return `FirstName${i}`;
    },

    'lastName'(i) {
      return `LastName${i}`;
    },

    email(i) {
      return `foo${i}@bar.com`;
    },

    password: "mypassword",
    lc: false
  });

  _exports.default = _default;
});