define("lightway-connect/components/sites/data/fields-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rN2DVxcm",
    "block": "{\"symbols\":[\"field\",\"@model\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"permitted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",true],[8],[0,\"\\n    Fields\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"data-test-id\",\"new-field-link\"]],[[\"@route\",\"@model\"],[\"sites.site.data-kinds.data-kind.fields.new\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n        New Field\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"data-test-fields-list\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"fields\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"data-test-show-field-link-for\",[23,1,[\"id\"]]]],[[\"@route\",\"@model\"],[\"sites.site.data-kinds.data-kind.fields.field.edit\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"label\"],[11,\"data-test-field-label-for\",[23,1,[\"id\"]]],[8],[0,\"\\n              \"],[7,\"span\",true],[10,\"data-test-field-label\",\"\"],[8],[0,\"\\n                \"],[1,[23,1,[\"label\"]],false],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\\n          \"],[5,\"link-to\",[[12,\"data-test-delete-field-link-for\",[23,1,[\"id\"]]]],[[\"@route\",\"@model\"],[\"sites.site.data-kinds.data-kind.fields.field.delete\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            Delete\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/data/fields-list/template.hbs"
    }
  });

  _exports.default = _default;
});