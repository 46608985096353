define("lightway-connect/components/form/select-box", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <select
    id={{@fieldId}}
    name={{@property}}
    value={{get @model @property}}
    onchange={{action "setSelection" value="target.value"}}
    data-test-input-id={{@property}} ...attributes>
  
    {{yield (hash
      Option=(
        component 'form/select-option'
        currentSelection=(get @model @property)
      )
    )}}
  
  </select>
  */
  {
    id: "y2q8yOdM",
    block: "{\"symbols\":[\"@fieldId\",\"@property\",\"@model\",\"&attrs\",\"&default\"],\"statements\":[[7,\"select\",false],[12,\"id\",[23,1,[]]],[12,\"name\",[23,2,[]]],[12,\"value\",[28,\"get\",[[23,3,[]],[23,2,[]]],null]],[12,\"onchange\",[28,\"action\",[[23,0,[]],\"setSelection\"],[[\"value\"],[\"target.value\"]]]],[12,\"data-test-input-id\",[23,2,[]]],[13,4],[8],[0,\"\\n\\n  \"],[14,5,[[28,\"hash\",null,[[\"Option\"],[[28,\"component\",[\"form/select-option\"],[[\"currentSelection\"],[[28,\"get\",[[23,3,[]],[23,2,[]]],null]]]]]]]]],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/select-box.hbs"
    }
  });

  let FormSelectBoxComponent = (_dec = Ember._action, (_class = class FormSelectBoxComponent extends _component.default {
    setSelection(value) {
      if (this.args.updateValue) {
        this.args.updateValue(value);
      } else {
        Ember.set(this.args.model, this.args.property, value);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setSelection", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setSelection"), _class.prototype)), _class));
  _exports.default = FormSelectBoxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormSelectBoxComponent);
});