define("lightway-connect/components/content-editor/setting-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['setting-module', 'module-container'],
    classNameBindings: ['isOpen:setting-module-active'],
    editorService: Ember.inject.service(),
    isOpen: false,
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      toggleModule() {
        this.toggleProperty('isOpen');
      },

      selectBoxUpdateAction(changesReceived) {
        let type = changesReceived.type;
        let value = changesReceived.value;
        let typeProperty = changesReceived.typeProperty;
        let valueProperty = changesReceived.valueProperty;
        let currentType = Ember.get(this.model, typeProperty);
        let currentValue = Ember.get(this.model, valueProperty);
        let changes = {};

        if (type != currentType) {
          changes[typeProperty] = type;
        }

        if (value != currentValue) {
          changes[valueProperty] = value;
        }

        if (changes[typeProperty] || changes[valueProperty]) {
          let editHash = this.editorService.prepareEdit(this.model, changes);
          this.editorService.addModifications(Ember.A([editHash]));
        }
      }

    }
  });

  _exports.default = _default;
});