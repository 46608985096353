define("lightway-connect/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateText = truncateText;
  _exports.default = void 0;

  function truncateText(params, hash) {
    const [value] = params;
    const {
      limit
    } = hash;
    let text = '';

    if (value != null && value.length > 0) {
      text = value.substr(0, limit);

      if (value.length > limit) {
        text += '...';
      }
    }

    return text;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});