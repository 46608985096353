define("lightway-connect/components/content-editor/image-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model, store, mode) {
    return Ember.computed(key, `model.${key}`, function () {
      let val = Ember.get(model, `${key}`);

      if (mode === "reference") {
        if (val) {
          let image = store.findRecord('image', val);
          return image;
        }

        return null;
      }

      return val;
    });
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'currentImage', valueFromProperty(this.propertyPath, this.model, this.store, this.mode));
    },

    inputId: Ember.computed({
      get() {
        return Ember.guidFor(this) + '-inpudid';
      }

    }),
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      toggleModalForImage() {
        this.toggleProperty('isShowingModalForImage');
      },

      selectImage(image) {
        let changes = {};
        let val;

        if (this.mode === "reference") {
          val = image.id;
        } else {
          val = image;
        }

        changes[this.propertyPath] = val;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.toggleProperty('isShowingModalForImage');
      },

      removeImage() {
        let changes = {};
        changes[this.propertyPath] = null;
        let edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }

    }
  });

  _exports.default = _default;
});