define("lightway-connect/controllers/sites/site/mailing-lists/mailing-list/index", ["exports", "lightway-connect/validations/subscription"], function (_exports, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteMailingListsMailingListIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SitesSiteMailingListsMailingListIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "subscriptionValidations", _subscription.default);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "formErrors", _descriptor3, this);

      _defineProperty(this, "queryParams", ['page', 'size']);

      _defineProperty(this, "page", 1);
    }

    // size = 5;
    async addSubscriber(changesetObj) {
      try {
        await changesetObj.validate();

        if (changesetObj.isValid) {
          try {
            await changesetObj.save();
            this.flashMessages.clearMessages().success("Subscriber added"); // this.router.transitionTo(
            //   'sites.site.mailing-lists.mailing-list'
            // )
          } catch (reason) {
            this.formErrors.addErrors(reason, changesetObj);
            this.flashMessages.clearMessages().alert("There was an error");
          }
        } else {
          this.flashMessages.clearMessages().alert("There was an error");
        }
      } catch (reason) {
        this.flashMessages.clearMessages().alert("There was an error");
      }
    }

    async removeSubscriber(subscription) {
      try {
        await subscription.destroyRecord();
        this.flashMessages.clearMessages().success("Subscriber removed");
      } catch (reason) {
        this.flashMessages.clearMessages().alert("There was an error");
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "formErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addSubscriber", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addSubscriber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSubscriber", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeSubscriber"), _class.prototype)), _class));
  _exports.default = SitesSiteMailingListsMailingListIndexController;
});