define("lightway-connect/components/sites/data/fields-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    permitted: true,

    didReceiveAttrs() {
      this._super(...arguments);

      this.checkForPermission();
    },

    checkForPermission() {
      let approvedPermissions = ['data_kinds'];
      let permitted = this.permissions.accountPermissionsInclude(approvedPermissions) == true;
      this.set('permitted', permitted);
    }

  });

  _exports.default = _default;
});