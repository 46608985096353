define("lightway-connect/services/parts/text-changeset", ["exports", "lightway-connect/validations/pages/text", "ember-changeset-validations", "ember-changeset"], function (_exports, _text, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let TextChangesetService = classic(_class = (_temp = class TextChangesetService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "TextValidations", _text.default);
    }

    setChangesetFor(part) {
      this.changeset = new _emberChangeset.default(part, (0, _emberChangesetValidations.default)(_text.default), _text.default);
    }

  }, _temp)) || _class;

  _exports.default = TextChangesetService;
});