define("lightway-connect/components/item-picker/list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell auto item-picker-body">
    <div class="grid-x cell-block-y">
  
      <div class="cell auto items-list">
        {{yield (hash
          Item=(
            component 'item-picker/item'
            selectedItem=this.args.selectedItem
            selectAction=(action this.args.selectAction)
            deselectAction=(action this.args.deselectAction)
          )
        )}}
  
      </div>
  
    </div>
  </div>
  */
  {
    id: "Bn/ILX0L",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell auto item-picker-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x cell-block-y\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell auto items-list\"],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"Item\"],[[28,\"component\",[\"item-picker/item\"],[[\"selectedItem\",\"selectAction\",\"deselectAction\"],[[23,0,[\"args\",\"selectedItem\"]],[28,\"action\",[[23,0,[]],[23,0,[\"args\",\"selectAction\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"args\",\"deselectAction\"]]],null]]]]]]]]],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/item-picker/list.hbs"
    }
  });

  class ItemPickerListComponent extends _component.default {}

  _exports.default = ItemPickerListComponent;

  window.__CLASSIC_OWN_CLASSES__.set(ItemPickerListComponent, true);

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ItemPickerListComponent);
});