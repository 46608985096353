define("lightway-connect/components/content-editor/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    actions: {
      add(dataTag) {
        let modifications = Ember.A([]);

        if (!dataTag.id) {
          let addHash = this.editorService.prepareAdd(dataTag);
          modifications.addObject(addHash);
        } // Collect model's existing dataTags


        let dTags = Ember.A([]);
        this.model.dataTags.forEach(dTag => {
          dTags.addObject(dTag);
        }); // Associate new tag with model

        dTags.addObject(dataTag);
        let editHash = this.editorService.prepareEdit(this.model, {
          dataTags: dTags
        });
        modifications.addObject(editHash);
        this.editorService.addModifications(modifications);
      },

      removeAt(index) {
        let tags = Ember.A([]);
        this.model.dataTags.forEach(dataTag => {
          tags.addObject(dataTag);
        });
        tags.removeAt(index);
        let editHash = this.editorService.prepareEdit(this.model, {
          dataTags: tags
        });
        this.editorService.addModifications(Ember.A([editHash]));
      }

    }
  });

  _exports.default = _default;
});