define("lightway-connect/components/form/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label for={{@fieldId}} data-test-label-id={{@property}} ...attributes>
    {{yield}}
  </label>
  */
  {
    id: "BPf6AyNc",
    block: "{\"symbols\":[\"@fieldId\",\"@property\",\"&attrs\",\"&default\"],\"statements\":[[7,\"label\",false],[12,\"for\",[23,1,[]]],[12,\"data-test-label-id\",[23,2,[]]],[13,3],[8],[0,\"\\n  \"],[14,4],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/label.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});