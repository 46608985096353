define("lightway-connect/helpers/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validationErrors = validationErrors;
  _exports.attributeErrors = attributeErrors;

  function validationErrors(errors) {
    let finalErrors = {};
    errors.forEach(errorObj => {
      let property = errorObj.path;
      let messages = Ember.A([]);
      errorObj.errors.forEach(message => {
        messages.addObject(message);
      });
      finalErrors[property] = messages;
    });
    return finalErrors;
  }

  function attributeErrors(errors) {
    let finalErrors = {};

    if (errors) {
      // Populate attribute keys
      errors.forEach(error => {
        let attribute = error.source.pointer.replace('/data/attributes/', '');
        let camelCasedAttribute = attribute.replace(/-([a-z])/g, function (g) {
          return g[1].toUpperCase();
        });
        finalErrors[camelCasedAttribute] = Ember.A([]);
      }); // Populate attribute arrays

      errors.forEach(error => {
        let attribute = error.source.pointer.replace('/data/attributes/', '');
        let camelCasedAttribute = attribute.replace(/-([a-z])/g, function (g) {
          return g[1].toUpperCase();
        });
        let msg = error.detail;
        finalErrors[camelCasedAttribute].pushObject(msg);
      });
    }

    return finalErrors;
  }
});