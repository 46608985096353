define("lightway-connect/routes/sites/site/data-kinds/user-plan-subscriptions/user-plan-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteDataKindsUserPlanSubscriptionsUserPlanSubscriptionRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.reset();
    }

    async model(params) {
      let userPlanSubscription = this.store.findRecord('user-plan-subscription', params.user_plan_subscription_id); // Load trial

      await userPlanSubscription.subscriptionTrial;
      return userPlanSubscription;
    }

  }

  _exports.default = SitesSiteDataKindsUserPlanSubscriptionsUserPlanSubscriptionRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteDataKindsUserPlanSubscriptionsUserPlanSubscriptionRoute, true);
});