define("lightway-connect/templates/components/roles/invitation-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T064dXJF",
    "block": "{\"symbols\":[\"Form\",\"Field\"],\"statements\":[[5,\"form-errors\",[],[[\"@changeset\"],[[23,0,[\"changeset\"]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"invitation-form\"],[10,\"data-test-id\",\"invitation-form\"],[8],[0,\"\\n  \"],[5,\"ui-form\",[],[[\"@model\",\"@onSubmit\"],[[23,0,[\"changeset\"]],[28,\"action\",[[23,0,[]],\"sendInvitation\"],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"field\"]],[],[[\"@property\"],[\"email\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Email address\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-auto\"],[8],[0,\"\\n        \"],[6,[23,2,[\"textInput\"]],[],[[],[]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-shrink\"],[8],[0,\"\\n        \"],[6,[23,1,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n          Invite\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/roles/invitation-form.hbs"
    }
  });

  _exports.default = _default;
});