define("lightway-connect/controllers/sites/site/data-kinds/data-kind/categories/category/categories/category/items/new", ["exports", "lightway-connect/validations/data-item-form"], function (_exports, _dataItemForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, classic(_class = (_class2 = (_temp = class NewController extends Ember.Controller.extend(_dataItemForm.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "formErrors", _descriptor2, this);
    }

    _generatePermalink(changesetObj) {
      let permalink;
      changesetObj.get('properties').forEach(property => {
        if (property.value) {
          if (property.field == changesetObj.get('parent.defaultField') || property.field == changesetObj.get('parent.parent.defaultField')) {
            permalink = property.value.replace("'", "").replace(/[_\W]+/g, "-").dasherize();
          }
        }
      });
      changesetObj.set('permalink', permalink);
    }

    save(changesetObj) {
      this._generatePermalink(changesetObj);

      changesetObj.validate().then(() => {
        if (changesetObj.get('isValid')) {
          changesetObj.save().then(() => {
            this.flashMessages.clearMessages().success('Item created');
            this.transitionToRoute('sites.site.data-kinds.data-kind.categories.category.categories.category');
          }).catch(reason => {
            this.formErrors.addErrors(reason, changesetObj);
          });
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewController;
});