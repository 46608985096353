define("lightway-connect/models/mail-template", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MailTemplateModel = (_dec = (0, _model.belongsTo)(), _dec2 = (0, _model.belongsTo)('style'), _dec3 = (0, _model.belongsTo)('mail-template-version', {
    inverse: 'mailTemplateCurrented',
    async: false
  }), _dec4 = (0, _model.hasMany)(), _dec5 = (0, _model.hasMany)({
    async: false
  }), _dec6 = (0, _model.belongsTo)('site', {
    inverse: 'defaultMailTemplate'
  }), _dec7 = Ember.computed('prototypes'), (_class = (_temp = class MailTemplateModel extends _model.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "site", _descriptor2, this);

      _initializerDefineProperty(this, "globalPalette", _descriptor3, this);

      _initializerDefineProperty(this, "currentVersion", _descriptor4, this);

      _initializerDefineProperty(this, "mailTemplateVersions", _descriptor5, this);

      _initializerDefineProperty(this, "prototypes", _descriptor6, this);

      _initializerDefineProperty(this, "siteDefaulted", _descriptor7, this);
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

    get availablePrototypeKinds() {
      let prototypes = Ember.A([]);
      let addSection = false;
      let addCta = false;
      let addBadge = false;
      let addContentClip = false;
      let addDivider = false;
      let addDataProfile = false;
      let addDataCollection = false;
      let addImageGallery = false;
      this.prototypes.forEach(prototype => {
        switch (prototype.kind) {
          case 'section':
            addSection = true;
            break;

          case 'cta':
            addCta = true;
            break;

          case 'badge':
            addBadge = true;
            break;

          case 'content-clip':
            addContentClip = true;
            break;

          case 'divider':
            addDivider = true;
            break;

          case 'data-profile':
            addDataProfile = true;
            break;

          case 'data-collection':
            addDataCollection = true;
            break;

          case 'image-gallery':
            addImageGallery = true;
            break;
        }
      });

      if (addSection) {
        prototypes.pushObject("section");
      }

      if (addCta) {
        prototypes.pushObject("cta");
      }

      if (addBadge) {
        prototypes.pushObject("badge");
      }

      if (addContentClip) {
        prototypes.pushObject("content-clip");
      }

      if (addDivider) {
        prototypes.pushObject("divider");
      }

      if (addDataProfile) {
        prototypes.pushObject("data-profile");
      }

      if (addDataCollection) {
        prototypes.pushObject("data-collection");
      }

      if (addImageGallery) {
        prototypes.pushObject("image-gallery");
      }

      return prototypes;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "globalPalette", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentVersion", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mailTemplateVersions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "prototypes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "siteDefaulted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "availablePrototypeKinds", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "availablePrototypeKinds"), _class.prototype)), _class));
  _exports.default = MailTemplateModel;
});