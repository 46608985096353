define("lightway-connect/components/paginated-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    flashMessages: Ember.inject.service(),
    totalRecords: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      return this.items.meta.pagination.records;
    }),
    previousNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.prev) {
        return;
      }

      return this.items.meta.pagination.prev.number;
    }),
    nextNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.next) {
        return;
      }

      return this.items.meta.pagination.next.number;
    }),
    lastNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.last) {
        return;
      }

      return this.items.meta.pagination.last.number || this.previousNumber + 1;
    }),
    currentNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.current) {
        return;
      }

      return this.items.meta.pagination.current.number;
    }),
    pageNumbers: Ember.computed('items', 'lastNumber', function () {
      if (!this.nextNumber && !this.previousNumber) {
        return Ember.A([]);
      }

      let total = 1;

      if (this.lastNumber) {
        total = this.lastNumber;
      }

      return new Array(total + 1).join('x').split('').map((e, i) => i + 1);
    }),

    init() {
      this._super(...arguments);

      if (!this.queryKey) {
        this.set('queryKey', "page");
      }

      this.checkForMultiplePages();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.checkForMultiplePages();
    },

    checkForMultiplePages() {
      if (this.items && this.items.meta && this.items.meta.pagination) {
        // let hasSelfTag = this.items.meta.pagination.self && this.items.meta.pagination.self.number > 1
        let hasNextTag = false; // if (this.items.meta.pagination.next) {
        //   hasNextTag = this.items.meta.pagination && Object.keys(this.items.meta.pagination.next).length > 0
        // }

        if (this.items.meta.pagination.next) {
          hasNextTag = true;
        }

        let hasPreviousTag = false;

        if (this.items.meta.pagination.prev) {
          // hasPreviousTag = true
          hasPreviousTag = this.items.meta.pagination && Object.keys(this.items.meta.pagination.prev).length > 0;
        }

        if (this.items.meta) // this.set('moreThanOnePage', (hasSelfTag || hasNextTag))
          // TODO: Fix this
          this.set('moreThanOnePage', true);
        this.set('hasNextLink', hasNextTag);
        this.set('hasPreviousLink', hasPreviousTag);
      } else {
        this.set('moreThanOnePage', false);
      }
    },

    selectionList: Ember.A([]),

    reset() {
      // Deselect all
      this.set('selectionList', Ember.A([])); // Disable moving mode

      this.set('inMovingMode', false); // Disable select model

      this.set('inSelectMode', false);
    },

    actions: {
      enableSelectMode() {
        this.set('inSelectMode', true);
      },

      deselectAll() {
        this.reset();
      },

      toggleItemSelection(item) {
        if (this.selectionList.includes(item)) {
          this.selectionList.removeObject(item);
        } else {
          this.selectionList.pushObject(item);
        }
      },

      moveSelected() {
        this.set('inMovingMode', true);
      },

      moveToCategory(category) {
        let jobs = this.selectionList.map(item => {
          item.set('parent', category);
          item.save();
        });
        Promise.all(jobs).then(() => {
          this.flashMessages.clearMessages().success('Selected items have been moved'); // Reset component state

          this.reset();
        });
      }

    }
  });

  _exports.default = _default;
});