define("lightway-connect/mirage/factories/data-kind", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Data Kind ${i}`;
    },

    identifier(i) {
      return `data-kind-${i}`;
    },

    enableCategories: true,
    preferences: {},
    categoriesEnabled: (0, _emberCliMirage.trait)({
      enableCategories: true
    }),
    categoriesDisabled: (0, _emberCliMirage.trait)({
      enableCategories: false
    })
  });

  _exports.default = _default;
});