define("lightway-connect/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Application = // import DS from 'ember-data';
  classic(_class = class Application extends _jsonApi.default {
    normalizeQueryResponse(store, clazz, payload) {
      const result = super.normalizeQueryResponse(...arguments);
      result.meta = result.meta || {};
      let records;

      if (result && result.meta && result.meta.pagination) {
        records = result.meta.pagination.records;
      }

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
        result.meta.pagination.records = records;
      }

      return result;
    }

    createPageMeta(data) {
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = {};
        let a = document.createElement('a');
        a.href = link;
        a.search.slice(1).split('&').forEach(pairs => {
          const [param, value] = pairs.split('=');

          if (param == 'page[number]') {
            meta[type].number = parseInt(value);
          }

          if (param == 'page[size]') {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });
      return meta;
    }

  }) || _class;

  _exports.default = Application;
});