define("lightway-connect/routes/sites/site/settings/domains/domain/subdomains/subdomain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsDomainsDomainSubdomainsSubdomainRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('domain', params.subdomain_id);
    }

  }

  _exports.default = SitesSiteSettingsDomainsDomainSubdomainsSubdomainRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsDomainsDomainSubdomainsSubdomainRoute, true);
});