define("lightway-connect/models/user-kind", ["exports", "@ember-data/model", "lightway-connect/mixins/data-field-parentable", "lightway-connect/mixins/notification-rule-resource"], function (_exports, _model, _dataFieldParentable, _notificationRuleResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let UserKind = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec4 = (0, _model.belongsTo)(), _dec5 = (0, _model.belongsTo)('data-field', {
    inverse: 'userKindsDefaulted',
    async: false
  }), _dec6 = (0, _model.hasMany)('user-plan', {
    async: true,
    inverse: 'userKind'
  }), _dec7 = (0, _model.hasMany)(), _dec8 = (0, _model.belongsTo)('domain', {
    inverse: 'userKindsPreferred',
    async: false
  }), _dec9 = (0, _model.belongsTo)('mail-message', {
    inverse: 'registrationConfirmationMessageUserKinds',
    async: false
  }), _dec10 = (0, _model.belongsTo)('mail-message', {
    inverse: 'passwordChangeMessageUserKinds',
    async: false
  }), _dec11 = (0, _model.belongsTo)('mail-message', {
    inverse: 'passwordResetMessageUserKinds',
    async: false
  }), _dec12 = (0, _model.belongsTo)('mail-message', {
    async: false,
    inverse: 'paymentConfirmationMessageUserKinds'
  }), _dec13 = (0, _model.belongsTo)('mail-message', {
    async: false,
    inverse: 'paymentFailureMessageUserKinds'
  }), _dec14 = (0, _model.belongsTo)('mail-message', {
    async: false,
    inverse: 'trialEndingMessageUserKinds'
  }), _dec15 = (0, _model.belongsTo)('mail-message', {
    async: false,
    inverse: 'trialEndedMessageUserKinds'
  }), _dec16 = Ember.computed, _dec17 = Ember.computed('fields', 'reverseAssociationFields'), classic(_class = (_class2 = (_temp = class UserKind extends _model.default.extend(_dataFieldParentable.default, _notificationRuleResource.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "identifier", _descriptor2, this);

      _initializerDefineProperty(this, "preferences", _descriptor3, this);

      _initializerDefineProperty(this, "site", _descriptor4, this);

      _initializerDefineProperty(this, "defaultField", _descriptor5, this);

      _initializerDefineProperty(this, "userPlans", _descriptor6, this);

      _initializerDefineProperty(this, "users", _descriptor7, this);

      _initializerDefineProperty(this, "preferredDomain", _descriptor8, this);

      _initializerDefineProperty(this, "registrationConfirmationMessage", _descriptor9, this);

      _initializerDefineProperty(this, "passwordChangeMessage", _descriptor10, this);

      _initializerDefineProperty(this, "passwordResetMessage", _descriptor11, this);

      _initializerDefineProperty(this, "paymentConfirmationMessage", _descriptor12, this);

      _initializerDefineProperty(this, "paymentFailureMessage", _descriptor13, this);

      _initializerDefineProperty(this, "trialEndingMessage", _descriptor14, this);

      _initializerDefineProperty(this, "trialEndedMessage", _descriptor15, this);
    }

    get parent() {
      return this;
    }

    get allFields() {
      var fieldsArray = Ember.A([]);
      this.fields.forEach(object => {
        fieldsArray.pushObject(object);
      });
      this.reverseAssociationFields.forEach(object => {
        fieldsArray.pushObject(object);
      });
      return fieldsArray;
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "site", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "defaultField", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "userPlans", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "users", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "preferredDomain", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "registrationConfirmationMessage", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "passwordChangeMessage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "passwordResetMessage", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "paymentConfirmationMessage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "paymentFailureMessage", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "trialEndingMessage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "trialEndedMessage", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "parent", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "parent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "allFields", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "allFields"), _class2.prototype)), _class2)) || _class);
  _exports.default = UserKind;
});