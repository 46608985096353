define("lightway-connect/mirage/config", ["exports", "ember-cli-mirage/response", "ember-file-upload/mirage", "lightway-connect/mirage/utils/formatted-error", "lightway-connect/mirage/utils/generate-random-id", "lightway-connect/mirage/utils/generate-translations-for", "lightway-connect/mirage/utils/remove-translations"], function (_exports, _response, _mirage, _formattedError, _generateRandomId, _generateTranslationsFor, _removeTranslations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.passthrough('/write-coverage');
    this.namespace = 'api'; // this.timing = 400;  // simulate network delay (default: 400)
    // const successHeaders = {
    //   success: 'true',
    //   'Content-Type': 'application/json',
    // };

    const failureHeaders = {
      success: 'false',
      'Content-Type': 'application/json'
    }; // Registration Confirmation

    this.get('/backend/accounts/confirmation', function (db, request) {
      let token = request.queryParams.confirmation_token;

      if (token == 'invalid-token') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "confirmation_token": ["is invalid"]
        });
      } else if (token == 'already-confirmed') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "email": ["was already confirmed, please try signing in"]
        });
      } else if (token == 'expired-token') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "confirmation_token": ["is invalid"]
        }); // } else {
        // return new Response(200, {success: 'true', 'Content-Type': 'application/json' })
      }
    }), this.post('/backend/accounts/confirmation', function (db, request) {
      let email = JSON.parse(request.requestBody).data.attributes.email;

      if (email == 'invalid-email@foobar.com') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "errors": [{
            "detail": "could not be found",
            "source": {
              "pointer": "/data/attributes/email"
            }
          }]
        });
      } else {
        return new _response.default(204, {
          success: 'true',
          'Content-Type': 'application/json'
        });
      }
    }), // Resend registration confirmation
    this.patch('/backend/confirmation', function (db, request) {
      let token = JSON.parse(request.requestBody).data.id;

      if (token == 'invalid-token') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "confirmation_token": ["is invalid"]
        });
      } else {
        return new _response.default(204, {
          success: 'true',
          'Content-Type': 'application/json'
        });
      }
    }), // Sign Up
    this.post('/backend/accounts', function (db, request) {
      let json = JSON.parse(request.requestBody);
      let params = json.data.attributes;

      if (params.email == "alreadytaken@foobar.com") {
        return (0, _formattedError.default)("email", "is already taken");
      } else {
        db.accounts.create({
          id: 'abc0',
          email: params.email,
          password: params.password
        });

        if (params['invitation-token']) {
          return new _response.default(200, {
            'success': 'true',
            'Content-Type': 'application/json'
          }, {
            'confirmed': true
          });
        } else {
          return new _response.default(204, {
            'success': 'true',
            'Content-Type': 'application/json'
          });
        }
      }
    });
    this.patch('/backend/accounts', function (db, request) {
      let params = JSON.parse(request.requestBody);

      if (params.data.attributes["new-email"]) {
        if (params.data.attributes["new-email"] == "invalid-email@foobar.com") {
          return (0, _formattedError.default)("new-email", "is invalid");
        } else if (params.data.attributes["new-email"] == "problem-email@foobar.com") {
          return (0, _formattedError.default)("base", "Email could not be changed");
        } else {
          return new _response.default(204, {
            success: 'true',
            'Content-Type': 'application/json'
          });
        }
      } else {
        if (params.data.attributes["current-password"] == "wrongpassword") {
          return (0, _formattedError.default)("current-password", "is incorrect");
        } else {
          return new _response.default(204, {
            success: 'true',
            'Content-Type': 'application/json'
          });
        }
      }
    }); // Sign In

    this.post('/backend/accounts/sign_in', (db, request) => {
      var params = JSON.parse(request.requestBody);
      let account = db.accounts.find('abc0');

      if (account) {
        let desiredResult = {
          account: {
            email: account.email,
            id: account.id
          },
          auth_token: "myauthtoken"
        };

        if (params.email === account.email && params.password === account.password) {
          return desiredResult;
        } else {
          return new _response.default(422, {
            errors: ['Invalid email or password']
          });
        }
      } else {
        return new _response.default(422, {
          errors: ['Invalid email or password']
        });
      }
    }); // Reset Password

    this.post('/backend/accounts/password', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let email = params.email;

      if (email == "invalid-email-address@foobar.com") {
        return (0, _formattedError.default)("email", "is invalid");
      } else {
        return new _response.default(204);
      }
    });
    this.get('/backend/accounts/password/edit', function (db, request) {
      let token = request.queryParams.reset_password_token;

      if (token == 'valid-token') {
        return new _response.default(204);
      } else if (token == 'expired-token') {
        return (0, _formattedError.default)("token", "has expired");
      } else {
        return (0, _formattedError.default)("token", "is invalid");
      }
    });
    this.patch('/backend/accounts/password', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let password = params.password;
      let token = params.reset_password_token;

      if (token == "valid-token") {
        if (password == "invalidpassword") {
          return (0, _formattedError.default)("password", "is invalid");
        } else {
          return new _response.default(204);
        }
      } else {
        return (0, _formattedError.default)("token", "can't be blank"); // Forces token to be present
      }
    }); // Account Authorisation

    this.get('/backend/accounts/:id', function (db, request) {
      if (request.requestHeaders.Authorization == 'Bearer expired-token') {
        // Reject expired token
        return new _response.default(404);
      } else {
        let accountId = request.params.id;
        return db.accounts.find(accountId);
      }
    }); // Sites

    this.get('/backend/sites');
    this.get('/backend/sites/:id', ({
      sites
    }, request) => {
      let authorizationToken = request.requestHeaders.Authorization;
      let id = request.params.id;
      var subdomain = "";

      if (request.params.attributes) {
        subdomain = request.params.attributes.subdomain;
      }

      if (id == 'unassociated-site' || subdomain == 'invalid-subdomain') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          errors: ["Access denied"]
        });
      } else if (authorizationToken == 'Bearer unauthorized-account') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          errors: ["Access denied"]
        });
      } else {
        return sites.find(id);
      }
    }); // this.post('/backend/sites')

    this.post('/backend/sites', function (db, request) {
      let attributes = JSON.parse(request.requestBody).data.attributes; // let name = attributes.name

      let subdomain = attributes.subdomain;

      if (subdomain == 'invalid-subdomain') {
        return (0, _formattedError.default)("subdomain", "is invalid");
      } else {
        return db.sites.create(attributes);
      }
    }), this.patch('/backend/sites/:id'); // ! Notification Rules

    this.get('/backend/notification_rules');
    this.post('/backend/notification_rules');
    this.delete('/backend/notification_rules/:id'); // Domains

    this.get('/backend/domains/:id');
    this.get('/backend/domains', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      let parentDomainId = Ember.get(request.queryParams, 'filter[parent-domain]');

      if (parentDomainId === "null") {
        return db.domains.where({
          siteId: siteId,
          parentDomainId: null
        });
      }

      if (parentDomainId === undefined) {
        parentDomainId = null;
      }

      if (parentDomainId === null) {
        return db.domains.where({
          siteId: siteId
        });
      } else {
        return db.domains.where({
          siteId: siteId,
          parentDomainId: parentDomainId
        });
      }
    });
    this.post('/backend/domains', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let name = params.data.attributes.name;

      if (name == 'invalid-domain.com') {
        return (0, _formattedError.default)("name", "is invalid");
      }

      return db.domains.create({
        name: name
      });
    });
    this.delete('/backend/domains/:id'); // Social Accounts

    this.get('/backend/social_accounts', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      return db.socialAccounts.where({
        siteId: siteId
      });
    });
    this.delete('/backend/social_accounts/:id', function (db, request) {
      let domain = db.socialAccounts.find(request.params.id);
      domain.destroy();
    }); // Roles

    this.get('/backend/roles');
    this.get('/backend/roles/:id');
    this.post('/backend/roles', function (db, request) {
      let attributes = JSON.parse(request.requestBody).data.attributes;
      let name = attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        return db.roles.create(attributes);
      }
    }), this.patch('/backend/roles/:id');
    this.del('/backend/roles/:id'); // Permissions

    this.get('/backend/permissions/:id');
    this.post('/backend/permissions', function (db, request) {
      let attributes = JSON.parse(request.requestBody).data.attributes;
      let kind = attributes.kind;

      if (kind == 'invalid-kind') {
        return (0, _formattedError.default)("kind", "is invalid");
      } else {
        return db.permissions.create(attributes);
      }
    }), this.del('/backend/permissions/:id'); // Invitations

    this.get('/backend/invitations');
    this.get('/backend/invitations/:id');
    this.post('/backend/invitations', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let email = params.data.attributes.email;

      if (email == "invalid@foobar.com") {
        return (0, _formattedError.default)("email", "is invalid");
      } else {
        return db.invitations.create(request.params);
      }
    });
    this.patch('/backend/invitations/:id', function (db, request) {
      let invitation = db.invitations.find(request.params.id);

      if (invitation.token == 'as-site-owner') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          errors: ["You cannot accept an invitation to your own site"]
        });
      } else {
        return invitation;
      }
    });
    this.del('/backend/invitations/:id', function (db, request) {
      let id = request.params.id;

      if (id == 'invalid-invitation') {
        return (0, _formattedError.default)("invitation", "is invalid");
      } else if (id == 'invalid-invitation-without-error-message') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          errors: ["Obscure error"]
        });
      } else {
        db.invitations.find(id).destroy();
        return new _response.default(204, {
          success: 'true',
          'Content-Type': 'application/json'
        });
      }
    }); // Contributors

    this.get('/backend/contributors/:id');
    this.get('/backend/contributors', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      let roleId = Ember.get(request.queryParams, 'filter[role]'); // let page = get(request.queryParams, '')

      if (siteId) {
        return db.contributors.where({
          siteId: siteId
        });
      } else {
        return db.contributors.where({
          roleId: roleId
        });
      }
    });
    this.del('/backend/contributors/:id', function (db, request) {
      let id = request.params.id;

      if (id == 'invalid-contributor') {
        return (0, _formattedError.default)("contributor", "is invalid");
      } else if (id == 'invalid-contributor-without-error-message') {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          errors: ["Obscure error"]
        });
      } else {
        db.contributors.find(id).destroy();
        return new _response.default(204, {
          success: 'true',
          'Content-Type': 'application/json'
        });
      }
    }); // Image Folders

    this.get('/backend/image_folders', function (db, request) {
      let parentType = Ember.get(request.queryParams, 'filter[parent_type]');
      let camelizedParentType = Ember.String.camelize(parentType);
      let parentId = Ember.get(request.queryParams, 'filter[parent]');
      let parent = db[camelizedParentType].find(parentId);
      return parent.imageFolders;
    });
    this.get('/backend/image_folders/:id');
    this.post('/backend/image_folders', function (db, request) {
      // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let parent = params.data.relationships.parent.data;
      let folder = db.imageFolders.create({
        name: params.data.attributes.name
      });
      folder.parentId = {
        id: parent.id,
        type: parent.type
      };
      return folder;
    });
    this.patch('/backend/image_folders/:id', function (db, request) {
      // Manually update record with polymorphic association due to mirage bug
      let folder = db.imageFolders.find(request.params.id);
      var params = JSON.parse(request.requestBody);
      folder.name = params.data.attributes.name;
      return folder;
    });
    this.del('/backend/image_folders/:id'); // Images

    this.get('/backend/images/:id');
    this.post('/backend/images', (0, _mirage.upload)(function (db, request) {
      const name = Ember.get(request, 'requestBody.image-file.name');
      const imageFolderId = Ember.get(request, 'requestBody.image-folder-id');
      return db.images.create({
        name: name,
        imageUrl: name,
        thumbUrl: name,
        imageFolderId: imageFolderId
      });
    }));
    this.patch('/backend/images/:id');
    this.del('/backend/images/:id'); // Document Folders

    this.get('/backend/document_folders');
    this.get('/backend/document_folders/:id');
    this.post('/backend/document_folders', function (db, request) {
      // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let parent = params.data.relationships.parent.data;
      let folder = db.documentFolders.create({
        name: params.data.attributes.name
      });
      folder.parentId = {
        id: parent.id,
        type: parent.type
      };
      return folder;
    });
    this.patch('/backend/document_folders/:id', function (db, request) {
      // Manually update record with polymorphic association due to mirage bug
      let folder = db.documentFolders.find(request.params.id);
      var params = JSON.parse(request.requestBody);
      folder.name = params.data.attributes.name;
      return folder;
    });
    this.del('/backend/document_folders/:id'); // Documents

    this.get('/backend/documents');
    this.get('/backend/documents/:id');
    this.post('/backend/documents', (0, _mirage.upload)(function (db, request) {
      const name = Ember.get(request, 'requestBody.document-file.name');
      const documentFolderId = Ember.get(request, 'requestBody.document-folder-id');
      return db.documents.create({
        name: name,
        documentUrl: name,
        documentFolderId: documentFolderId
      });
    }));
    this.patch('/backend/documents/:id');
    this.del('/backend/documents/:id'); // Screens

    this.get('/backend/screens');
    this.get('/backend/screens/:id');
    this.post('/backend/screens');
    this.patch('/backend/screens/:id', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let id = request.params.id;
      let screen = db.screens.find(id); // Removed Parts

      let removedPartsJSON = JSON.parse(request.requestBody).data.relationships['removed-parts'].data;
      removedPartsJSON.forEach(function (part) {
        if (part.id) {
          db.parts.find(part.id).destroy();
        }
      }); // Parts

      let partsJSON = JSON.parse(request.requestBody).data.relationships.parts.data;
      let savedParts = [];
      partsJSON.forEach(function (part) {
        let pt;

        if (part.id) {
          pt = db.parts.find(part.id);
          pt.rowOrder = part.attributes['row-order'];
          pt.kind = part.attributes['kind'];
          pt.textContent = part.attributes['text-content'];
        } else {
          let id = (0, _generateRandomId.default)();
          pt = db.parts.create({
            id: id,
            rowOrder: part.attributes['row-order'],
            kind: part.attributes['kind'],
            textContent: part.attributes['text-content']
          });
        }

        pt.parentId = {
          id: screen.id,
          type: 'screen'
        }; // Add Image to part

        if (part.relationships && part.relationships.content && part.relationships.content.data) {
          let content;

          if (part.relationships.content.data.type == 'images') {
            content = db.images.find(part.relationships.content.data.id);
          }

          pt.kontent = content;
        } // Add region to part


        if (part.relationships && part.relationships.region && part.relationships.region.data) {
          let region;

          if (part.relationships.region.data.id) {
            region = db.regions.find(part.relationships.region.data.id);
          } else {
            let id = (0, _generateRandomId.default)();
            let name;

            if (part.relationships.region.data.attributes) {
              name = part.relationships.region.data.attributes.name;
            }

            region = db.regions.create({
              id: id,
              name: name
            });
            pt.region = region;
          }
        } // Removed Part Translations


        part.relationships["removed-translations"].data.forEach(function (translationData) {
          let existingTranslation = translationData.id;

          if (existingTranslation) {
            let translation = db.translations.find(translationData.id);
            translation.destroy();
          }
        });
        part.relationships["removed-translations"].data = []; // Part Translations

        let translationsData = part.relationships.translations.data;
        (0, _generateTranslationsFor.default)(pt, db, translationsData);
        savedParts.addObject(pt);
      }); // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(screen, db, translationsData); // Update screen attributes

      screen.name = params.data.attributes.name;
      screen.identifier = params.data.attributes.identifier;
      screen.description = params.data.attributes.description;
      screen.save();
      screen.parts = savedParts;
      return screen;
    });
    this.del('/backend/screens/:id'); // Layouts

    this.get('/backend/layouts');
    this.get('/backend/layouts/:id');
    this.post('/backend/layouts', function (db, request) {
      let requestBody = JSON.parse(request.requestBody);
      let attributes = JSON.parse(request.requestBody).data.attributes;
      let name = attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        let newLayout = db.layouts.create(attributes);
        let relationships = requestBody.data.relationships;

        if (relationships && relationships['parent-layout']) {
          let parentLayoutId = relationships['parent-layout'].data.id;
          let parentLayout = db.layouts.find(parentLayoutId);

          if (parentLayout) {
            newLayout.parentLayout = parentLayout;
          }
        }

        let layoutVersion = db.layoutVersions.create(attributes);
        newLayout.currentVersion = layoutVersion;
        newLayout.save();
        let style = db.styles.create({
          parent: newLayout,
          layoutVersion: layoutVersion,
          name: "Global Styles",
          styleProperties: {
            gc1: {
              value: "#CCCCCC",
              description: "Global Colour 1"
            },
            gc2: {
              value: "#333333",
              description: "Global Colour 2"
            },
            gf1: {
              value: "Helvetica",
              category: 'sans-serif',
              variants: ['100', '400', '400i', '700']
            },
            gf2: {
              value: "Times New Roman",
              category: 'serif',
              variants: ['100', '400', '400i', '700']
            }
          }
        });
        layoutVersion.globalStyle = style;
        layoutVersion.save();
        return newLayout;
      }
    }), this.patch('/backend/layouts/:id', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let newName = params.data.attributes.name;
      let layout = db.layouts.find(params.data.id);

      if (newName == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        layout.name = newName;
        layout.save();
        return layout;
      }
    }), this.del('/backend/layouts/:id'); // Layout Versions

    this.get('/backend/layout_versions', function (db, request) {
      let layoutId = Ember.get(request.queryParams, 'filter[layout]');
      return db.layoutVersions.where({
        layoutId: layoutId
      });
    });
    this.get('/backend/layout_versions/:id');
    this.post('/backend/layout_versions', function (db, request) {
      let jsonResponse = JSON.parse(request.requestBody).data;
      let versionToCopy = db.layoutVersions.find(jsonResponse.relationships["version-to-copy"].data.id);
      let layout = db.layouts.find(jsonResponse.relationships.layout.data.id);
      return db.layoutVersions.create({
        versionName: versionToCopy.versionName,
        name: layout.name,
        layout: layout
      });
    });
    this.patch('/backend/layout_versions/:id', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let version = db.layoutVersions.find(params.data.id);
      version.versionName = params.versionName;
      version.name = params.name;
      version.save();
      return version;
    }); // Parts

    this.post('/backend/parts');
    this.patch('/backend/parts/:id');
    this.put('/backend/parts/:id/duplicate', function (db, request) {
      let originalPart = db.parts.find(request.params.id);
      let newPart = db.parts.create({
        parent: originalPart.parent,
        version: originalPart.version,
        kind: originalPart.kind,
        preferences: originalPart.preferences,
        rowOrder: originalPart.rowOrder + 1
      });
      originalPart.parts.models.forEach(nestedPart => {
        let newNested = db.parts.create({
          parent: newPart,
          version: nestedPart.version,
          kind: nestedPart.kind,
          preferences: nestedPart.preferences,
          rowOrder: nestedPart.rowOrder
        });
        nestedPart.parts.models.forEach(np => {
          db.parts.create({
            parent: newNested,
            version: np.version,
            kind: np.kind,
            preferences: np.preferences,
            rowOrder: np.rowOrder
          });
        });
      });
      return newPart;
    });
    this.delete('/backend/parts/:id'); // HoverItems

    this.post('/backend/hover_items');
    this.patch('/backend/hover_items/:id');
    this.delete('/backend/hover_items/:id'); // Styles

    this.post('/backend/styles', function (db, request) {
      let jsonResponse = JSON.parse(request.requestBody).data;
      let attributes = jsonResponse.attributes;
      let name = attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        return db.styles.create(attributes);
      }
    });
    this.patch('/backend/styles/:id'); // Prototypes

    this.get('backend/prototypes');
    this.post('backend/prototypes');
    this.delete('/backend/prototypes/:id'); // Pages

    this.get('/backend/pages', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      return db.pages.where({
        siteId: siteId
      });
    });
    this.get('/backend/pages/:id');
    this.post('/backend/pages', function (db, request) {
      let jsonData = JSON.parse(request.requestBody).data;
      let name = jsonData.attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        let page = db.pages.create(jsonData.attributes);

        if (jsonData.relationships) {
          let layoutId = jsonData.relationships.layout.data.id;

          if (layoutId) {
            let layout = db.layouts.find(layoutId);
            page.layout = layout;
            let pageVersion = db.pageVersions.create({
              layoutVersion: page.layout.currentVersion
            });
            page.currentVersion = pageVersion;
            page.save();
          }
        }

        return page;
      }
    }), this.patch('/backend/pages/:id', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let newName = params.data.attributes.name;
      let page = db.pages.find(params.data.id);

      if (newName == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      } else {
        page.name = newName;
        page.rowOrder = params.data.attributes.rowOrder;
        page.status = params.data.attributes.status;
        page.save();
        return page;
      }
    }), this.del('/backend/pages/:id'); // Page Versions

    this.get('/backend/page_versions', function (db, request) {
      let pageId = Ember.get(request.queryParams, 'filter[page]');
      return db.pageVersions.where({
        pageId: pageId
      });
    });
    this.get('/backend/page_versions/:id');
    this.post('/backend/page_versions', function (db, request) {
      let jsonResponse = JSON.parse(request.requestBody).data;
      let versionToCopy = db.pageVersions.find(jsonResponse.relationships["version-to-copy"].data.id);
      let page = db.pages.find(jsonResponse.relationships.page.data.id);
      return db.pageVersions.create({
        versionName: versionToCopy.versionName,
        name: versionToCopy.name,
        permalink: versionToCopy.permalink,
        dynamic: versionToCopy.dynamic,
        preferences: versionToCopy.preferences,
        page: page
      });
    });
    this.patch('/backend/page_versions/:id', function (db, request) {
      let params = JSON.parse(request.requestBody);
      let version = db.pageVersions.find(params.data.id);
      version.versionName = params.versionName;
      version.name = params.name;
      version.permalink = params.permalink;
      version.dynamic = params.dynamic;
      version.preferences = params.preferences;
      version.save();
      return version;
    }); // Page Links

    this.del('/backend/page_links/:id'); // Data Kinds

    this.get('/backend/data_kinds', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      return db.dataKinds.where({
        siteId: siteId
      });
    });
    this.get('/backend/data_kinds/:id');
    this.post('/backend/data_kinds', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let name = params.data.attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      }

      let site = db.sites.find(params.data.relationships.site.data);
      let kind = db.dataKinds.create({
        name: name,
        enableCategories: params.data.attributes["enable-categories"],
        site: site
      }); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(kind, db, translationsData);
      return kind;
    });
    this.patch('/backend/data_kinds/:id', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let item = db.dataKinds.find(params.data.id);
      item.name = params.data.attributes.name;
      item.save(); // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(item, db, translationsData);
      return item;
    });
    this.del('/backend/data_kinds/:id'); // Data Categories

    this.get('/backend/data_categories', function (db, request) {
      let parentType = Ember.get(request.queryParams, 'filter[parent_type]');
      let camelizedParentType = Ember.String.camelize(parentType);
      let parentId = Ember.get(request.queryParams, 'filter[parent]');
      let parent = db[camelizedParentType].find(parentId);
      return parent.categories;
    });
    this.get('/backend/data_categories/:id');
    this.post('/backend/data_categories', function (db, request) {
      // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let parent = params.data.relationships.parent.data;
      let category = db.dataCategories.create({
        name: params.data.attributes.name
      });
      category.parentId = {
        id: parent.id,
        type: parent.type
      }; // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(category, db, translationsData);
      return category;
    });
    this.patch('/backend/data_categories/:id', function (db, request) {
      // Manually update record with polymorphic association due to mirage bug
      let category = db.dataCategories.find(request.params.id);
      var params = JSON.parse(request.requestBody);
      category.name = params.data.attributes.name; // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(category, db, translationsData);
      return category;
    });
    this.del('/backend/data_categories/:id'); // Data Fields

    this.get('/backend/data_fields', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      let parentId = Ember.get(request.queryParams, 'filter[parent]');

      if (parentId === "null") {
        return db.dataFields.where({
          siteId: siteId,
          parentId: null
        });
      }

      if (parentId === undefined) {
        parentId = null;
      }

      if (parentId === null) {
        return db.dataFields.where({
          siteId: siteId
        });
      } else {
        let parent = db.dataKinds.find(parentId);
        return parent.fields;
      }
    });
    this.get('/backend/data_fields/:id');
    this.post('/backend/data_fields', function (db, request) {
      // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let parent = params.data.relationships.parent.data;
      let field = db.dataFields.create({
        name: params.data.attributes.name
      });
      field.parentId = {
        id: parent.id,
        type: parent.type
      }; // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(field, db, translationsData);
      return field;
    });
    this.patch('/backend/data_fields/:id', function (db, request) {
      // Manually update record with polymorphic association due to mirage bug
      let field = db.dataFields.find(request.params.id);
      var params = JSON.parse(request.requestBody);
      field.label = params.data.attributes.label; // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(field, db, translationsData);
      return field;
    });
    this.del('/backend/data_fields/:id'); // Data Associates

    this.get('/backend/data_associates', function (db, request) {
      let queryParams = request.queryParams;
      let size = Ember.get(queryParams, 'filter[size]');
      let fieldId = Ember.get(queryParams, 'filter[field]');
      let itemId = Ember.get(queryParams, 'filter[item]');
      let ownerId = Ember.get(queryParams, 'filter[owner]');
      let associates;

      if (itemId) {
        // Is a reverse association field
        let itemIdHash = {
          id: itemId,
          type: 'data-item'
        };
        let item = db.dataItems.find(itemId);

        if (!item) {
          item = db.users.find(itemId);
          itemIdHash.type = "user";
        }

        associates = db.dataAssociates.where({
          fieldId: fieldId,
          itemId: itemIdHash
        });
      } else if (ownerId) {
        // Is not a reverse association field
        let ownerIdHash = {
          id: ownerId,
          type: 'data-item'
        };
        let owner = db.dataItems.find(ownerId);

        if (!owner) {
          owner = db.users.find(ownerId);
          ownerIdHash.type = "user";
        }

        associates = db.dataAssociates.where({
          fieldId: fieldId,
          ownerId: ownerIdHash
        });
      } else {
        associates = db.dataAssociates.where({
          fieldId: fieldId
        });
      }

      if (size) {
        let associatesCount = associates.models.length;

        if (associatesCount > 0 && associatesCount > size) {
          associates.models.length = size;
        }
      }

      return associates;
    }); // Data Items

    this.get('/backend/data_items');
    this.get('/backend/data_items/:id');
    this.post('/backend/data_items');
    this.patch('/backend/data_items/:id');
    this.del('/backend/data_items/:id');
    this.patch('/backend/data_properties/:id'); // DataTags

    this.post('/backend/data_tags'); // Orders

    this.get('/backend/orders');
    this.get('/backend/orders.csv', () => {
      return new _response.default(200, {
        'Content-Type': 'text/csv'
      }, new Blob(['some data']));
    });
    this.get('/backend/orders/:id'); // Invoices

    this.get('/backend/invoices');
    this.get('/backend/invoices/:id');
    this.get('/backend/line_items/:id'); // Payments

    this.get('/backend/payments');
    this.get('/backend/payments/:id'); // Registrations

    this.get('/backend/registrations');
    this.get('/backend/registrations/:id');
    this.patch('/backend/registrations/:id');
    this.get('/backend/registrations.csv', () => {
      return new _response.default(200, {
        'Content-Type': 'text/csv'
      }, new Blob(['some data']));
    }); // User Kinds

    this.get('/backend/user_kinds');
    this.get('/backend/user_kinds/:id');
    this.post('/backend/user_kinds');
    this.patch('/backend/user_kinds/:id');
    this.del('/backend/user_kinds/:id'); // UserPlans

    this.get('/backend/user_plans');
    this.post('/backend/user_plans', function (db, request) {
      const attributes = JSON.parse(request.requestBody).data.attributes;

      if (attributes.name === 'Problem Name') {
        let errors = {
          errors: [{
            detail: 'has a problem',
            source: {
              pointer: '/data/attributes/name'
            }
          }]
        };
        return new _response.default(422, failureHeaders, errors);
      }

      const userPlan = db.userPlans.create(attributes);
      return userPlan;
    });
    this.get('/backend/user_plans/:id');
    this.patch('/backend/user_plans/:id'); // UserPlanSubscriptions

    this.get('/backend/user_plan_subscriptions');
    this.get('/backend/user_plan_subscriptions/:id'); // SubscriptionTrials

    this.get('/backend/subscription_trials/:id');
    this.patch('/backend/subscription_trials/:id'); // ProductOffers

    this.get('/backend/product_offers');
    this.get('/backend/product_offers/:id');
    this.post('/backend/product_offers', function (db, request) {
      const attributes = JSON.parse(request.requestBody).data.attributes;

      if (attributes.name === 'Problem Name') {
        let errors = {
          errors: [{
            detail: 'has a problem',
            source: {
              pointer: '/data/attributes/name'
            }
          }]
        };
        return new _response.default(422, failureHeaders, errors);
      }

      const productOffer = db.productOffers.create(attributes);
      return productOffer;
    });
    this.patch('/backend/product_offers/:id', function (db, request) {
      var params = JSON.parse(request.requestBody);
      const attributes = params.data.attributes;

      if (attributes.name === 'Problem Name') {
        let errors = {
          errors: [{
            detail: 'has a problem',
            source: {
              pointer: '/data/attributes/name'
            }
          }]
        };
        return new _response.default(422, failureHeaders, errors);
      }

      const productOffer = db.productOffers.find(params.data.id);
      productOffer.name = attributes.name;
      productOffer.redemptionCode = attributes.redemptionCode;
      return productOffer;
    });
    this.get('/backend/user_product_offers');
    this.post('/backend/user_product_offers');
    this.delete('/backend/user_product_offers/:id'); // UserInvitations

    this.get('/backend/user_invitations');
    this.post('/backend/user_invitations'); // Users

    this.get('/backend/users', function (db, request) {
      let queryParams = request.queryParams;
      let status = Ember.get(queryParams, 'filter[status]');

      if (status) {
        return db.users.where({
          status: status
        });
      } else {
        return db.users.all();
      }
    });
    this.get('/backend/users.csv', () => {
      return new _response.default(200, {
        'Content-Type': 'text/csv'
      }, new Blob(['some user data']));
    });
    this.get('/backend/users/:id');
    this.patch('/backend/users/:id', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let email = params.data.attributes.email;

      if (email === "") {
        return new _response.default(422, {
          'Content-Type': 'application/json'
        }, {
          "errors": [{
            "detail": "can't be blank",
            "source": {
              "pointer": "/data/attributes/email"
            }
          }]
        });
      } else {
        return new _response.default(204, {
          success: 'true',
          'Content-Type': 'application/json'
        });
      }
    }); // Web Forms

    this.get('/backend/web_forms', function (db, request) {
      let siteId = Ember.get(request.queryParams, 'filter[site]');
      return db.webForms.where({
        siteId: siteId
      });
    });
    this.get('/backend/web_forms/:id');
    this.post('/backend/web_forms', function (db, request) {
      // // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let name = params.data.attributes.name;

      if (name == 'invalid-name') {
        return (0, _formattedError.default)("name", "is invalid");
      }

      let webForm = db.webForms.create({
        name: name,
        identifier: params.data.attributes.identifier,
        recipientEmail: params.data.attributes['recipient-email'],
        introductionMessage: params.data.attributes["introduction-message"],
        confirmationMessage: params.data.attributes["confirmation-message"]
      }); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(webForm, db, translationsData);
      return webForm;
    });
    this.patch('/backend/web_forms/:id', function (db, request) {
      var params = JSON.parse(request.requestBody);
      let webForm = db.webForms.find(params.data.id);
      webForm.name = params.data.attributes.name;
      webForm.introductionMessage = params.data.attributes["introduction-message"];
      webForm.confirmationMessage = params.data.attributes["confirmation-message"];
      webForm.preferences = params.data.attributes.preferences;
      webForm.save(); // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(webForm, db, translationsData);
      return webForm;
    });
    this.del('/backend/web_forms/:id'); // FormFields

    this.get('/backend/form_fields/:id');
    this.post('/backend/form_fields', function (db, request) {
      // Manually create record with polymorphic association due to mirage bug
      var params = JSON.parse(request.requestBody);
      let label = params.data.attributes.label;

      if (label == 'invalid-label') {
        return (0, _formattedError.default)("label", "is invalid");
      }

      let webFormId = params.data.relationships["web-form"].data.id;
      let field = db.formFields.create({
        label: label,
        kind: params.data.attributes.kind
      });
      field.webForm = db.webForms.find(webFormId); // field.parentId = { id: parent.id, type: parent.type }
      // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(field, db, translationsData);
      return field;
    });
    this.patch('/backend/form_fields/:id', function (db, request) {
      // Manually update record with polymorphic association due to mirage bug
      let field = db.formFields.find(request.params.id);
      var params = JSON.parse(request.requestBody);
      field.label = params.data.attributes.label; // Removed Translations

      let removedTranslations = params.data.relationships["removed-translations"].data;
      (0, _removeTranslations.default)(removedTranslations, db); // Translations

      let translationsData = params.data.relationships.translations.data;
      (0, _generateTranslationsFor.default)(field, db, translationsData);
      return field;
    });
    this.del('/backend/form_fields/:id'); // Mail Templates

    this.post('/backend/mail_templates', function (db, request) {
      let requestBody = JSON.parse(request.requestBody);
      let attributes = requestBody.data.attributes;
      let mailTemplate = db.mailTemplates.create(attributes);
      let mailTemplateVersion = db.mailTemplateVersions.create(attributes);
      mailTemplate.currentVersion = mailTemplateVersion;
      mailTemplate.save();
      return mailTemplate;
    });
    this.get('/backend/mail_templates');
    this.get('/backend/mail_templates/:id');
    this.patch('/backend/mail_templates/:id');
    this.del('/backend/mail_templates/:id'); // Mail Template Versions

    this.patch('/backend/mail_template_versions/:id'); // Mail Messages

    this.get('/backend/mail_messages');
    this.post('/backend/mail_messages');
    this.get('/backend/mail_messages/:id');
    this.patch('/backend/mail_messages/:id'); // LoggedMailMessages

    this.get('backend/logged_mail_messages');
    this.get('backend/logged_mail_messages/:id'); // Mailing Lists

    this.get('/backend/mailing_lists');
    this.post('/backend/mailing_lists');
    this.get('/backend/mailing_lists/:id');
    this.patch('/backend/mailing_lists/:id');
    this.del('/backend/mailing_lists/:id'); // Subscriptions

    this.get('/backend/subscriptions');
    this.post('/backend/subscriptions');
    this.del('/backend/subscriptions/:id'); // Payment Gateways

    this.get('/backend/payment_gateways');
    this.post('/backend/payment_gateways');
    this.get('/backend/payment_gateways/:id');
    this.patch('/backend/payment_gateways/:id');
    this.del('/backend/payment_gateways/:id'); // Apple App Config

    this.post('/backend/apple_app_config', function (db, request) {
      const attributes = JSON.parse(request.requestBody).data.attributes;

      if (attributes['apn-team'] === 'problem-apn-team') {
        let errors = {
          errors: [{
            detail: 'has a problem',
            source: {
              pointer: '/data/attributes/apn-team'
            }
          }]
        };
        return new _response.default(422, failureHeaders, errors);
      }

      return new _response.default(204, {
        success: 'true',
        'Content-Type': 'application/json'
      });
    }); // Translations

    this.get('/backend/translations', function (db, request) {
      let parentType = Ember.get(request.queryParams, 'filter[parent_type]');
      let camelizedParentType = Ember.String.camelize(parentType);
      let parentId = Ember.get(request.queryParams, 'filter[parent]');
      let parent = db[camelizedParentType].find(parentId);
      return parent.translations;
    });
  }
});