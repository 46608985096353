define("lightway-connect/services/sorted-list-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SortedListManagerService = (_dec = Ember.inject.service, classic(_class = (_class2 = (_temp = class SortedListManagerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    // This API takes lists, items and positions to calculate the
    // changes that are needed to be made. It does NOT change the models.
    // It is the responsibility of the API's consumer to take the returned
    // values and apply those values to the models involved.
    insertItemAt(positionToInsert, list, newItem) {
      let changes = Ember.A([]); // Insert new item into list

      list.insertAt(positionToInsert, newItem); // Correct row order for each list item

      list.forEach((item, i) => {
        this.prepareChange(changes, item, i + 1);
      });
      return changes;
    }

    reorderItems(sourceList, sourceIndex, targetList, targetIndex) {
      let changes = Ember.A([]); // Correct existing list order first

      sourceList.forEach((item, i) => {
        this.prepareChange(changes, item, i + 1);
      });
      targetList.forEach((item, i) => {
        this.prepareChange(changes, item, i + 1);
      });
      const draggedItem = sourceList.objectAt(sourceIndex); // If moving to different listing

      if (sourceList != targetList) {
        // Set new parent
        let newParent = targetList.__parent__; // Set new row order of item being moved

        this.prepareChange(changes, draggedItem, targetIndex + 1, newParent); // First make sure source list items all have a valid rowOrder
        // but exclude item being moved

        sourceList.forEach((item, index) => {
          if (index != sourceIndex) {
            this.prepareChange(changes, item, index + 1);
          }
        }); // Decrease position of items in source list that occur after dragged item

        sourceList.slice(sourceIndex + 1).forEach(item => {
          this.prepareChange(changes, item, item.rowOrder - 1);
        }); // First make sure target list items all have a valid rowOrder

        targetList.forEach((item, index) => {
          this.prepareChange(changes, item, index + 1);
        }); // Increase position of items in target list that occur from the point where dragged item has been dropped

        targetList.slice(targetIndex).forEach(item => {
          this.prepareChange(changes, item, item.rowOrder + 1);
        });
      } else {
        if (targetIndex < sourceIndex) {
          // If moving up the same list
          // Reorder items that are between targetIndex and sourceIndex
          sourceList.slice(targetIndex, sourceIndex + 1).forEach(item => {
            this.prepareChange(changes, item, item.rowOrder + 1);
          });
        } else {
          // If moving down the same list
          // Reorder items that are after sourceIndex
          sourceList.slice(sourceIndex + 1, targetIndex + 1).forEach(item => {
            this.prepareChange(changes, item, item.rowOrder - 1);
          });
        } // Set new row order of item being moved


        this.prepareChange(changes, draggedItem, targetIndex + 1);
      }

      return changes;
    }

    prepareChange(changes, item, value, newParent) {
      let approved = true;

      if (item.rowOrder != value || newParent && item.parent != newParent) {
        changes.forEach(change => {
          if (change.item == item) {
            approved = false;
            Ember.set(change, 'value', value);
          }
        });

        if (approved) {
          let change = {
            item: item,
            value: value,
            parent: newParent
          };
          changes.addObject(change);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = SortedListManagerService;
});