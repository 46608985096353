define("lightway-connect/routes/sites/site/media/document-folders/document-folder/document/rename", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let RenameRoute = classic(_class = class RenameRoute extends Ember.Route {}) || _class;

  _exports.default = RenameRoute;
});