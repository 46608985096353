define("lightway-connect/models/page-version", ["exports", "@ember-data/model", "lightway-connect/mixins/part-parentable"], function (_exports, _model, _partParentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageVersion = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('raw', {
    defaultValue: () => ({})
  }), _dec6 = (0, _model.belongsTo)(), _dec7 = (0, _model.belongsTo)({
    async: false
  }), _dec8 = (0, _model.belongsTo)('page', {
    inverse: 'currentVersion'
  }), _dec9 = (0, _model.belongsTo)('page-version', {
    inverse: 'copiedVersion'
  }), _dec10 = (0, _model.belongsTo)('page-version', {
    inverse: 'versionToCopy'
  }), _dec11 = Ember.computed, _dec12 = Ember.computed, _dec13 = Ember.computed, _dec14 = (0, _model.belongsTo)('data-kind', {
    reverse: 'dynamicPagesAsSubject'
  }), _dec15 = Ember.computed('preferences.subject-type.id'), classic(_class = (_class2 = (_temp = class PageVersion extends _model.default.extend(_partParentable.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _initializerDefineProperty(this, "versionName", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "permalink", _descriptor3, this);

      _initializerDefineProperty(this, "dynamic", _descriptor4, this);

      _initializerDefineProperty(this, "preferences", _descriptor5, this);

      _initializerDefineProperty(this, "page", _descriptor6, this);

      _initializerDefineProperty(this, "layoutVersion", _descriptor7, this);

      _initializerDefineProperty(this, "pageCurrented", _descriptor8, this);

      _initializerDefineProperty(this, "versionToCopy", _descriptor9, this);

      _initializerDefineProperty(this, "copiedVersion", _descriptor10, this);

      _initializerDefineProperty(this, "subjectType", _descriptor11, this);
    }

    get baseParent() {
      return this;
    }

    get globalStyle() {
      return this.layoutVersion.globalStyle;
    }

    get version() {
      return this;
    }

    isA(kind) {
      return this.get('constructor.modelName') == kind;
    } // Mock subject-type, inclusions and exclusions from API


    get colorClasses() {
      return this.get('page.layout.currentVersion.colorClasses');
    }

    get colorClassIDs() {
      return this.get('page.layout.currentVersion.colorClassIDs');
    }

    get subjectTypeObject() {
      let subjectType = this.get('preferences.subject-type');

      if (subjectType) {
        let objectToReturn;
        this.store.peekAll('data-kind').forEach(pr => {
          if (pr.id === subjectType.id) {
            objectToReturn = pr;
          }
        });

        if (objectToReturn) {
          return objectToReturn;
        }
      }

      if (this.dynamic) {
        return this;
      }

      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "versionName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "permalink", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "dynamic", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "preferences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "page", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "layoutVersion", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "pageCurrented", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "versionToCopy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "copiedVersion", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "baseParent", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "baseParent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "globalStyle", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "globalStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "version", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "version"), _class2.prototype), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "subjectType", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "subjectTypeObject", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "subjectTypeObject"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageVersion;
});