define("lightway-connect/controllers/sites/site/settings/mailing-lists/mailing-list/index", ["exports", "lightway-connect/validations/mailing-list"], function (_exports, _mailingList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_mailingList.default, {
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    actions: {
      save(changeset) {
        this.target.send('saveUserKind', changeset);
      },

      saveField(changeset) {
        this.target.send('saveField', changeset);
      },

      setAsDefault(field) {
        this.model.mailingList.set('defaultField', field);
        this.model.mailingList.save();
      },

      deleteField(field) {
        let fieldParent = field.parent;
        field.destroyRecord().then(() => {
          let siblingsList;

          if (fieldParent) {
            siblingsList = fieldParent.fields;
          } // Decrease position of remaining fields only if others remain


          if (siblingsList) {
            siblingsList.forEach(field => {
              field.set('rowOrder', field.rowOrder - 1);

              if (field.hasDirtyAttributes) {
                field.save();
              }
            });
          }

          this.flashMessages.clearMessages().success('Field removed');
        });
      }

    }
  });

  _exports.default = _default;
});