define("lightway-connect/components/changeset-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      if (this.error.key.dasherize() == this.propertyName.dasherize()) {
        this.set('errorIsForProperty', true);
      }
    }

  });

  _exports.default = _default;
});