define("lightway-connect/components/form/description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test-description-id={{@property}} ...attributes>
    {{yield}}
  </div>
  */
  {
    id: "LKFa8sR5",
    block: "{\"symbols\":[\"@property\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"data-test-description-id\",[23,1,[]]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form/description.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});