define("lightway-connect/routes/sites/site/settings/domains/domain/subdomains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SitesSiteSettingsDomainsDomainSubdomainsRoute extends Ember.Route {}

  _exports.default = SitesSiteSettingsDomainsDomainSubdomainsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SitesSiteSettingsDomainsDomainSubdomainsRoute, true);
});