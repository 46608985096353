define("lightway-connect/controllers/sites/site/content/screens/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    screens: Ember.computed('model', function () {
      return this.model;
    }),
    actions: {
      saveScreen(screen) {
        this.target.send('saveScreen', screen);
      }

    }
  });

  _exports.default = _default;
});