define("lightway-connect/components/field-association", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selecting: false,
    actions: {
      toggleModalForItem() {
        this.toggleProperty('selecting');
      },

      cancelAssociateSelection() {
        this.toggleProperty('selecting');
      },

      selectAssociate(selection) {
        this.property.set('resource', selection);
        this.toggleProperty('selecting');
      }

    }
  });

  _exports.default = _default;
});