define("lightway-connect/routes/sites/site/media/image-folders/image-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ImageFolderRoute = classic(_class = class ImageFolderRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('imageFolder', params.folder_id);
    }

  }) || _class;

  _exports.default = ImageFolderRoute;
});