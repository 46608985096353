define("lightway-connect/components/accounts/sign-in-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    authenticator: 'authenticator:custom',
    invitationToken: null,
    actions: {
      authenticate() {
        let credentials = this.getProperties('identification', 'password');
        this.session.authenticate(this.authenticator, credentials).then(() => {
          Ember.run(() => {
            this.currentAccount.load().then(() => {
              this.flashMessages.clearMessages().success('Signed in');
              this.router.transitionTo('index');
            }).catch(() => {//
            });
          });
        }).catch(() => {
          Ember.run(() => {
            this.flashMessages.clearMessages().alert('There was an error');
            this.set('errorMessage', "Invalid email or password");
          });
        });
      }

    }
  });

  _exports.default = _default;
});