define("lightway-connect/components/editor", ["exports", "@glimmer/component", "@editorjs/editorjs", "@editorjs/header", "@editorjs/nested-list", "lightway-connect/classes/editor-js/button", "lightway-connect/classes/editor-js/image-button", "lightway-connect/classes/editor-js/collection", "lightway-connect/classes/editor-js/item", "lightway-connect/classes/editor-js/form", "editorjs-drag-drop", "ember-uuid"], function (_exports, _component, _editorjs, _header, _nestedList, _button, _imageButton, _collection, _item, _form, _editorjsDragDrop, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell auto cell-block-container editor-container">
    <div class="grid-y grid-frame editor-grid">
      <div class="cell small-12">
  
        <div class="grid-x full-height">
          {{yield (hash
            Body=(component "editor/body")
            TitleBar=(component "editor/title-bar")
            Canvas=(
              component "editor/canvas"
              editorJsHolder=this.editorJsHolder
              deselectAction=(action "deselect")
            )
            SecondaryPanel=(
              component "editor/secondary-panel"
              Model=@model
              CurrentSelection=(get @value this.currentSelection)
            )
            SaveBar=(
              component "editor/save-bar"
              model=@model
            )
            PublishBar=(
              component "editor/publish-bar"
              model=@model
            )
            CurrentSelection=this.currentSelection
            Value=@value
            CurrentBlock=this.currentBlock
          )}}
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    id: "zXESDa/o",
    block: "{\"symbols\":[\"@value\",\"@model\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell auto cell-block-container editor-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-y grid-frame editor-grid\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height\"],[8],[0,\"\\n        \"],[14,3,[[28,\"hash\",null,[[\"Body\",\"TitleBar\",\"Canvas\",\"SecondaryPanel\",\"SaveBar\",\"PublishBar\",\"CurrentSelection\",\"Value\",\"CurrentBlock\"],[[28,\"component\",[\"editor/body\"],null],[28,\"component\",[\"editor/title-bar\"],null],[28,\"component\",[\"editor/canvas\"],[[\"editorJsHolder\",\"deselectAction\"],[[23,0,[\"editorJsHolder\"]],[28,\"action\",[[23,0,[]],\"deselect\"],null]]]],[28,\"component\",[\"editor/secondary-panel\"],[[\"Model\",\"CurrentSelection\"],[[23,2,[]],[28,\"get\",[[23,1,[]],[23,0,[\"currentSelection\"]]],null]]]],[28,\"component\",[\"editor/save-bar\"],[[\"model\"],[[23,2,[]]]]],[28,\"component\",[\"editor/publish-bar\"],[[\"model\"],[[23,2,[]]]]],[23,0,[\"currentSelection\"]],[23,1,[]],[23,0,[\"currentBlock\"]]]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor.hbs"
    }
  });

  let EditorComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('ready', 'currentSelection'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class EditorComponent extends _component.default {
    constructor() {
      super(...arguments); // Generate editorJsHolder for div id

      _initializerDefineProperty(this, "editor", _descriptor, this);

      _initializerDefineProperty(this, "editorJsHolder", _descriptor2, this);

      _initializerDefineProperty(this, "ready", _descriptor3, this);

      _initializerDefineProperty(this, "tempValue", _descriptor4, this);

      _initializerDefineProperty(this, "currentSelection", _descriptor5, this);

      _defineProperty(this, "select", object => {
        this.currentSelection = object;
      });

      this.editorJsHolder = "editor-" + (0, _emberUuid.v4)();

      let editorChange = data => {
        this.updateContent(data);
      };

      this.editor = new _editorjs.default({
        holder: this.editorJsHolder,
        data: this.editorData,
        placeholder: "Add content here",
        onReady: () => {
          this.ready = true;
          new _editorjsDragDrop.default(this.editor);
        },
        onChange: editorChange,
        logLevel: 'ERROR',
        tools: {
          header: {
            class: _header.default,
            inlineToolbar: true,
            config: {
              placeholder: 'Enter a header',
              levels: [2, 3, 4, 5, 6],
              defaultLevel: 3
            }
          },
          list: {
            class: _nestedList.default,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          },
          button: {
            class: _button.default,
            config: {
              selectAction: this.select
            }
          },
          "image-button": {
            class: _imageButton.default,
            config: {
              selectAction: this.select
            }
          },
          collection: {
            class: _collection.default,
            config: {
              selectAction: this.select
            }
          },
          item: {
            class: _item.default,
            config: {
              selectAction: this.select
            }
          },
          form: {
            class: _form.default,
            config: {
              selectAction: this.select
            }
          }
        }
      });
    }

    get editorData() {
      if (this.tempValue) {
        return this.tempValue;
      }

      return {
        blocks: []
      };
    }

    get currentBlock() {
      if (this.currentSelection === null) {
        return null;
      }

      let selectionIndex = this.currentSelection;
      return this.editorData.blocks[selectionIndex];
    }

    deselect() {
      this.currentSelection = null;
    }

    updateContent(data) {
      if (data) {
        this.tempValue = data;
        this.editor.save().then(outputData => {
          this.tempValue = outputData;
          this.args.model.contentJson = outputData;
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "editorJsHolder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ready", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tempValue", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.value;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSelection", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "currentBlock", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "currentBlock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselect", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "deselect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateContent", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateContent"), _class.prototype)), _class));
  _exports.default = EditorComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EditorComponent);
});