define("lightway-connect/controllers/sites/site/settings/user-kinds/new", ["exports", "lightway-connect/validations/user-kind"], function (_exports, _userKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userKind.default, {
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),

    generateIdentifier(changeset) {
      if (changeset.get('name')) {
        let identifier = changeset.get('name').replace("'", "").replace(/[_\W]+/g, "-").dasherize();
        changeset.set('identifier', identifier);
      }
    },

    actions: {
      save(changeset) {
        this.generateIdentifier(changeset);
        this.target.send('save', changeset);
      }

    }
  });

  _exports.default = _default;
});