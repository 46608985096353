define("lightway-connect/templates/sites/site/design/layouts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MhZXKY07",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\",\"@model\"],\"statements\":[[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container full\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"New Layout\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-card\"],[8],[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[23,4,[]],[23,0,[\"layoutValidations\"]]],null]],null,{\"statements\":[[0,\"\\n        \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"saveLayout\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n          \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n            \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n              Layout Name\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[6,[23,3,[\"textInput\"]],[],[[],[]]],[0,\"\\n          \"]],\"parameters\":[3]}],[0,\"\\n\\n          \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n            Create Layout\\n          \"]],\"parameters\":[]}],[0,\"\\n\\n        \"]],\"parameters\":[2]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/design/layouts/new.hbs"
    }
  });

  _exports.default = _default;
});