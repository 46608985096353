define("lightway-connect/templates/components/ui-form/select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZhIknKf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"selectBoxIsOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"grid-x select-option \",[28,\"if\",[[28,\"eq\",[[23,0,[\"stringifiedValue\"]],\"nullz\"],null],\"select-option-empty\"],null]]]],[12,\"role\",\"button\"],[12,\"data-test-option-for\",[23,0,[\"valueId\"]]],[3,\"action\",[[23,0,[]],[23,0,[\"selectAction\"]],[23,0,[\"valueToSend\"]]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"currentValueNotPresentAndThisOptionIsBlank\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[\"grid-x select-option \",[28,\"if\",[[28,\"eq\",[[23,0,[\"stringifiedValue\"]],\"nullz\"],null],\"select-option-empty\"],null]]]],[12,\"role\",\"button\"],[12,\"data-test-option-for\",[23,0,[\"valueId\"]]],[3,\"action\",[[23,0,[]],[23,0,[\"selectAction\"]],null]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"matchesCurrent\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",[29,[\"grid-x select-option \",[28,\"if\",[[28,\"eq\",[[23,0,[\"stringifiedValue\"]],\"nullz\"],null],\"select-option-empty\"],null]]]],[12,\"role\",\"button\"],[12,\"data-test-option-for\",[23,0,[\"valueId\"]]],[3,\"action\",[[23,0,[]],[23,0,[\"selectAction\"]],[23,0,[\"valueToSend\"]]]],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/select-option.hbs"
    }
  });

  _exports.default = _default;
});