define("lightway-connect/routes/auth/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let InstagramRoute = classic(_class = class InstagramRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.prepareCodeForSending();
    }

  }) || _class;

  _exports.default = InstagramRoute;
});