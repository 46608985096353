define("lightway-connect/components/localized-datetime", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    dateTimeValue=this.finalValue
    timeZoneDescription=this.timeZoneDescription
  )}}
  */
  {
    id: "0iZRT34S",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"dateTimeValue\",\"timeZoneDescription\"],[[23,0,[\"finalValue\"]],[23,0,[\"timeZoneDescription\"]]]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/localized-datetime.hbs"
    }
  });

  let LocalizedDatetimeComponent = (_dec = Ember._tracked, (_class = (_temp = class LocalizedDatetimeComponent extends _component.default {
    get timeZoneDescription() {
      const today = new Date();
      const short = today.toLocaleDateString(undefined);
      const full = today.toLocaleDateString(undefined, {
        timeZoneName: 'long'
      }); // Try to remove date from the string in a locale-agnostic way

      const shortIndex = full.indexOf(short);

      if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length); // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides

        return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
      } else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return full;
      }
    }

    constructor() {
      super(...arguments); // Reference:
      // https://stackoverflow.com/questions/30166338/setting-value-of-datetime-local-from-date

      _initializerDefineProperty(this, "finalValue", _descriptor, this);

      if (this.args.value) {
        let localDate = new Date(this.args.value);
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        this.finalValue = localDate.toISOString().slice(0, 16);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finalValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = LocalizedDatetimeComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LocalizedDatetimeComponent);
});