define("lightway-connect/mirage/models/web-form", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    site: (0, _emberCliMirage.belongsTo)(),
    translations: (0, _emberCliMirage.hasMany)({
      inverse: 'parent'
    }),
    removedTranslations: (0, _emberCliMirage.hasMany)('translation', {
      inverse: 'formerParent'
    }),
    pageLinks: (0, _emberCliMirage.hasMany)({
      inverse: 'resource',
      async: false
    }),
    contentParts: (0, _emberCliMirage.hasMany)('parts', {
      inverse: 'content',
      cascadeDelete: true
    }),
    fields: (0, _emberCliMirage.hasMany)('form-field'),
    registrations: (0, _emberCliMirage.hasMany)()
  });

  _exports.default = _default;
});