define("lightway-connect/components/ui-form/check-box-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      if (!Ember.get(this.model, this.property)) {
        Ember.set(this.model, this.property, Ember.A([]));
      }
    },

    actions: {
      toggleCheckbox(event, value) {
        if (this.toggleAction) {
          return this.toggleAction(event, value);
        } else {
          let checked;

          if (event.target.value === 'on') {
            checked = true;
          } else {
            checked = false;
          }

          let arr = Ember.get(this.model, this.property);

          if (checked && !arr.includes(value)) {
            arr.addObject(value);
          } else if (arr.includes(value)) {
            arr.removeObject(value);
          }
        }
      }

    }
  });

  _exports.default = _default;
});