define("lightway-connect/components/ui-form/advanced-select-box", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="grid-x select-box {{if this.isOpen 'select-box-open'}}" data-test-select-box-id={{@property}}>
  
    <div class="cell auto">
      <div class="grid-y grid-frame align-center">
  
        {{yield (hash
          isOpen=this.isOpen
          option=(component "ui-form/advanced-select-option"
            value=null
            selectBoxIsOpen=this.isOpen
            currentValue=this.currentValue
            selectOptionAction=(action this.selectOption)
          )
        )}}
  
      </div>
    </div>
  
    {{#unless this.isOpen}}
  
      <div class="cell shrink">
        <div class="grid-y grid-frame align-center">
          <div class="selector-arrow">
          </div>
        </div>
      </div>
  
    {{/unless}}
  </div>
  */
  {
    id: "ftKX+Tze",
    block: "{\"symbols\":[\"@property\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"grid-x select-box \",[28,\"if\",[[23,0,[\"isOpen\"]],\"select-box-open\"],null]]]],[11,\"data-test-select-box-id\",[23,1,[]]],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-y grid-frame align-center\"],[8],[0,\"\\n\\n      \"],[14,2,[[28,\"hash\",null,[[\"isOpen\",\"option\"],[[23,0,[\"isOpen\"]],[28,\"component\",[\"ui-form/advanced-select-option\"],[[\"value\",\"selectBoxIsOpen\",\"currentValue\",\"selectOptionAction\"],[null,[23,0,[\"isOpen\"]],[23,0,[\"currentValue\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectOption\"]]],null]]]]]]]]],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[23,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-y grid-frame align-center\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"selector-arrow\"],[8],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/ui-form/advanced-select-box.hbs"
    }
  });

  // For selecting reference object (e.g. global color),
  // or custom value (e.g. HEX color string).
  // If property is "background-color-" it will look up "background-color-type"
  // and "background-color-value."
  // If property is "" it will look up "type" and "value".
  let UiFormAdvancedSelectBoxComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class UiFormAdvancedSelectBoxComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);
    }

    get currentValue() {
      if (this.type === null) {
        return null;
      } // color-class # layout level
      // global-color # site level
      // custom


      if (this.type === "color-class") {
        return Ember.get(this.args.model, this.valueProperty);
      } else if (this.type === "global-color") {
        return Ember.get(this.args.model, this.valueProperty);
      } else {
        return Ember.get(this.args.model, this.typeProperty);
      }
    }

    get typeProperty() {
      return this.args.property + "type";
    }

    get valueProperty() {
      return this.args.property + "value";
    }

    get type() {
      return Ember.get(this.args.model, this.typeProperty);
    }

    selectOption(option, valueType) {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }

      let type = null;
      let value = null;

      if (option != null) {
        if (option === "custom") {
          type = "custom";
          value = null;
        } else {
          type = valueType;
          value = option;
        }
      }

      if (this.args.updateModelAction) {
        // Send data to external action
        let changes = {
          type: type,
          value: value,
          typeProperty: this.typeProperty,
          valueProperty: this.valueProperty
        };
        this.args.updateModelAction(changes);
      } else {
        // Update here
        Ember.set(this.args.model, this.typeProperty, type);
        Ember.set(this.args.model, this.valueProperty, value);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectOption", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectOption"), _class.prototype)), _class));
  _exports.default = UiFormAdvancedSelectBoxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiFormAdvancedSelectBoxComponent);
});