define("lightway-connect/components/ui-form/color-picker", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ColorPicker
    @value={{mut (get this.args.model this.args.property)}}
    @default="#CCCCCC"
    @format="hexa"
    @onSave={{action "updateColor"}} />
  */
  {
    id: "3lS8qlXW",
    block: "{\"symbols\":[],\"statements\":[[5,\"color-picker\",[],[[\"@value\",\"@default\",\"@format\",\"@onSave\"],[[28,\"mut\",[[28,\"get\",[[23,0,[\"args\",\"model\"]],[23,0,[\"args\",\"property\"]]],null]],null],\"#CCCCCC\",\"hexa\",[28,\"action\",[[23,0,[]],\"updateColor\"],null]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/ui-form/color-picker.hbs"
    }
  });

  let UiFormColorPickerComponent = (_dec = Ember._action, (_class = class UiFormColorPickerComponent extends _component.default {
    updateColor(hsva) {
      Ember.set(this.args.model, this.args.property, hsva.toHEXA().toString());
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateColor", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateColor"), _class.prototype)), _class));
  _exports.default = UiFormColorPickerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiFormColorPickerComponent);
});