define("lightway-connect/components/content-editor/parts/list-part", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="part-content fill-height p{{@part.identifier}}">
  
    {{#if @prototypeBasedPart}}
  
      {{#if (eq @part.preferences.dynamic "gallery-item")}}
  
        {{#each @prototypeBasedPart.preferences.images as |item|}}
          <ContentEditor::DraggablePartsList
            @part={{@part}}
            @items={{@part.partsSortedBound}}
            @orientationOverride={{@orientationOverride}}
            @group={{this.listGroup}}
            @prototypeBasedPart={{@prototypeBasedPart}}
            @loopDynamicContainers={{@loopDynamicContainers}}
            @loopItem={{item}}
            @draggingEnabled={{true}}
            @selectPartAction={{@selectPartAction}}
            @dropPartAction={{@dropPartAction}} />
        {{/each}}
  
      {{else}}
  
        <ContentEditor::DraggablePartsList
          @part={{@part}}
          @items={{@part.partsSortedBound}}
          @orientationOverride={{@orientationOverride}}
          @group={{this.listGroup}}
          @prototypeBasedPart={{@prototypeBasedPart}}
          @loopDynamicContainers={{@loopDynamicContainers}}
          @loopItem={{@loopItem}}
          @draggingEnabled={{true}}
          @selectPartAction={{@selectPartAction}}
          @dropPartAction={{@dropPartAction}} />
  
      {{/if}}
  
    {{else}}
  
      <ContentEditor::DraggablePartsList
        @part={{@part}}
        @items={{@part.partsSortedBound}}
        @orientationOverride={{@orientationOverride}}
        @group={{this.listGroup}}
        @prototypeBasedPart={{@prototypeBasedPart}}
        @loopDynamicContainers={{@loopDynamicContainers}}
        @loopItem={{@loopItem}}
        @draggingEnabled={{true}}
        @selectPartAction={{@selectPartAction}}
        @dropPartAction={{@dropPartAction}} />
  
    {{/if}}
  
  </div>
  
  {{yield}}
  
  */
  {
    id: "ZjDAwdgq",
    block: "{\"symbols\":[\"item\",\"@part\",\"@orientationOverride\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@selectPartAction\",\"@dropPartAction\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content fill-height p\",[23,2,[\"identifier\"]]]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[\"preferences\",\"dynamic\"]],\"gallery-item\"],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,4,[\"preferences\",\"images\"]]],null,{\"statements\":[[0,\"        \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,2,[]],[23,2,[\"partsSortedBound\"]],[23,3,[]],[23,0,[\"listGroup\"]],[23,4,[]],[23,5,[]],[23,1,[]],true,[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,2,[]],[23,2,[\"partsSortedBound\"]],[23,3,[]],[23,0,[\"listGroup\"]],[23,4,[]],[23,5,[]],[23,6,[]],true,[23,7,[]],[23,8,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,2,[]],[23,2,[\"partsSortedBound\"]],[23,3,[]],[23,0,[\"listGroup\"]],[23,4,[]],[23,5,[]],[23,6,[]],true,[23,7,[]],[23,8,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/list-part.hbs"
    }
  });

  let ContentEditorPartsListPartComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('editorService.inPageMode', 'part.baseParent'), (_class = (_temp = class ContentEditorPartsListPartComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "editorService", _descriptor2, this);

      _initializerDefineProperty(this, "dragSort", _descriptor3, this);
    }

    get listGroup() {
      if (this.editorService.inPageMode) {
        if (!this.args.part.baseParent) {
          return "";
        }

        if (this.args.part.baseParent.isA('item-region')) {
          return "nested-group";
        } else {
          return "restricted";
        }
      }

      return "nested-group";
    }

    get itemContainer() {
      this.args.part.parts.forEach(p => {
        if (p.kind === "item-container") {
          return p;
        }
      });
      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "editorService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dragSort", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "listGroup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "listGroup"), _class.prototype)), _class));
  _exports.default = ContentEditorPartsListPartComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentEditorPartsListPartComponent);
});