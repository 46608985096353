define("lightway-connect/components/sites/parts/sidebars/pending-image-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    browsingImages: false,
    imageFolders: null,
    selectedFolder: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let imageFolders = this.store.query('image-folder', {
        filter: {
          site: this.get('currentSite.site.id'),
          parent: this.get('currentSite.site.id'),
          parent_type: 'sites',
          id: !null
        }
      });
      this.set('imageFolders', imageFolders);
    },

    actions: {
      selectFolder(folder) {
        this.set('selectedFolder', folder);
      },

      selectImage(image) {
        this.part.set('kontent', image);
        this.part.set('kind', 'image');
      }

    }
  });

  _exports.default = _default;
});