define("lightway-connect/templates/sites/site/data-kinds/data-kind/edit/fields/field/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EFDS6k1z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"data-test-action-heading\",\"\"],[8],[0,\"Remove Field\"],[9],[0,\"\\n\\nAre you sure you want to remove this field? All associated data will be permanently lost.\\n\\n\"],[7,\"button\",false],[12,\"data-test-delete-button\",\"\"],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"destroyField\"]],[8],[0,\"\\n  Remove Field\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/data-kinds/data-kind/edit/fields/field/delete.hbs"
    }
  });

  _exports.default = _default;
});