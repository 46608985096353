define("lightway-connect/controllers/sites/site/data-kinds/data-kind/items/item/registrations/index", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    deletingPage: false,

    downloadFile(a) {
      a.click();
    },

    exportAsCSV() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let item = this.get('model.item');
        return (0, _fetch.default)(`/api/backend/registrations.csv?filter[site]=${this.currentSite.site.id}&filter[item]=${item.id}`, {
          method: 'GET',
          headers: {
            "Content-Type": 'text/csv;charset=utf-8',
            "Accept": "text/csv;version=1",
            "Authorization": `Bearer ${this.session.data.authenticated.token}`
          }
        }).then(response => response.blob()).then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "registrations.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

          this.downloadFile(a);
          a.remove(); //afterwards we remove the element again

          resolve();
        }).catch(reason => {
          reject(reason);
        });
      });
    },

    actions: {
      doNothing() {},

      exportAsCSV() {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this.exportAsCSV().then(response => {
            return resolve(response);
          }).catch(reason => {
            return reject(reason);
          });
        });
      }

    }
  });

  _exports.default = _default;
});