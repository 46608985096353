define("lightway-connect/components/sites/parts/nested-part/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['nestedPart'],
    selectedElement: Ember.inject.service(),
    item: undefined,
    dragEndAction: undefined,
    group: 'nested group',
    elementSelected: Ember.computed('selectedElement.element', function () {
      return this.selectedElement.element == this.part;
    }),
    actions: {
      dragEnd({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const draggedItem = sourceList.objectAt(sourceIndex); // If moving around the same list

        if (sourceList === targetList) {
          // If moving up the same list
          if (targetIndex < sourceIndex) {
            // Reorder items that are between targetIndex sourceIndex
            sourceList.slice(targetIndex, sourceIndex + 1).forEach(item => {
              const rowOrder = item.get('rowOrder');
              item.set('rowOrder', rowOrder + 1);
            });
          } else {
            // If moving down the same list
            // Reorder items that are after sourceIndex
            sourceList.slice(sourceIndex + 1, targetIndex + 1).forEach(item => {
              const rowOrder = item.get('rowOrder');
              item.set('rowOrder', rowOrder - 1);
            });
          }
        } else {
          // Moving to different list
          sourceList.slice(sourceIndex + 1).forEach(item => {
            const rowOrder = item.get('rowOrder');
            item.set('rowOrder', rowOrder - 1);
          });
          targetList.slice(targetIndex).forEach(item => {
            const rowOrder = item.get('rowOrder');
            item.set('rowOrder', rowOrder + 1);
          });
        } // Updating rowOrder of dragged item


        draggedItem.set('rowOrder', targetIndex); // Moving dragged item into the target list
        // We can't do it directly because the list is a derived one,
        // so we need to access the original list using a hack from the model

        targetList.__parent__.get('parts').addObject(draggedItem); // Note, that we don't need to remove the item from the source list.
        // Ember Data does this automatically because our relationship is
        // two-way.

      },

      editContainer() {
        this.selectedElement.select(this.part);
      }

    }
  });

  _exports.default = _default;
});