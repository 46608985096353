define("lightway-connect/components/content-editor/resource-reference", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.resource}}
  */
  {
    id: "/KuoVxzq",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[\"resource\"]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/resource-reference.hbs"
    }
  });

  let ContentEditorResourceReferenceComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class ContentEditorResourceReferenceComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "editorService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    get propertyPath() {
      if (this.args.propertyPathOverride) {
        return this.args.propertyPathOverride;
      }

      if (this.args.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.args.shortProperty;
      } else {
        return this.args.property;
      }
    }

    get resource() {
      // this.args.model
      // this.args.type
      // this.propertyPath
      let id = this.args.model.get(this.propertyPath);

      if (id) {
        if (id === "email") {
          return {
            name: "Email"
          };
        } else {
          return this.store.peekRecord(this.args.type, id);
        }
      } else {
        return null;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editorService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContentEditorResourceReferenceComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContentEditorResourceReferenceComponent);
});