define("lightway-connect/controllers/sites/site/web-forms/web-form/index", ["exports", "lightway-connect/validations/web-form"], function (_exports, _webForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteWebFormsWebFormIndexController = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class SitesSiteWebFormsWebFormIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "WebFormValidations", _webForm.default);

      _defineProperty(this, "showNewButtonField", false);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);
    }

    resetNewButtonField() {
      this.set('showNewButtonField', false);
    }

    save(changesetObj) {
      this.target.send('saveWebForm', changesetObj);
    }

    saveField(field) {
      this.target.send('saveField', field);
    }

    addButton() {
      this.set('showNewButtonField', true);
    }

    removeNewButtonField() {
      this.resetNewButtonField();
    }

    createButton(changesetObj, button) {
      Ember.set(button, 'actions', Ember.A([]));
      changesetObj.get('preferences.buttons').pushObject(button);
      this.resetNewButtonField();
    }

    removeButton(changesetObj, button) {
      changesetObj.get('preferences.buttons').removeObject(button);
    }

    deleteField(field) {
      field.destroyRecord().then(() => {
        this.flashMessages.clearMessages().success('Field removed');
      }).catch(() => {
        this.flashMessages.clearMessages().alert('There was an error');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveField", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "saveField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addButton", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeNewButtonField", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeNewButtonField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createButton", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "createButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeButton", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteField", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "deleteField"), _class.prototype)), _class));
  _exports.default = SitesSiteWebFormsWebFormIndexController;
});