define("lightway-connect/controllers/sites/site/settings/user-kinds/user-kind/plans/plan/index", ["exports", "lightway-connect/helpers/form-errors", "yup"], function (_exports, _formErrors, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SitesSiteSettingsUserKindsUserKindPlansPlanIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class SitesSiteSettingsUserKindsUserKindPlansPlanIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "formErrors", _descriptor3, this);

      _initializerDefineProperty(this, "originalModel", _descriptor4, this);

      _defineProperty(this, "entitySchema", null);

      this.reset();
    }

    reset() {
      this.formErrors = {};

      if (this.model) {
        this.originalModel = {
          id: this.model.plan.id,
          name: this.model.plan.name,
          identifier: this.model.plan.identifier,
          data: this.model.plan.data,
          site: this.model.plan.site,
          userKind: this.model.plan.userKind
        };
      }

      (0, _yup.setLocale)({
        mixed: {
          required: "can't be blank"
        }
      });
      this.entitySchema = (0, _yup.object)({
        name: (0, _yup.string)().required(),
        identifier: (0, _yup.string)().trim().matches(/((?:\w+-)+\w+)/g, 'Is not in correct format').required()
      });
    }

    async update(model) {
      let validatedModel;

      try {
        validatedModel = await this.entitySchema.validate(model, {
          abortEarly: false
        });
      } catch (reason) {
        this.formErrors = (0, _formErrors.validationErrors)(reason.inner);
        this.flashMessages.clearMessages().alert('There was an error');
        return;
      }

      try {
        let savedModel = await validatedModel.save();
        this.flashMessages.clearMessages().success('User Plan updated');
        this.originalModel = {
          id: savedModel.id,
          name: savedModel.name,
          identifier: savedModel.identifier,
          data: savedModel.data,
          site: savedModel.site,
          userKind: savedModel.userKind
        };
      } catch (reason) {
        this.flashMessages.clearMessages().alert('There was an error');

        try {
          this.formErrors = (0, _formErrors.attributeErrors)(reason.errors);
        } catch (r) {//
        }
      }
    }

    async deleteOffer(model) {
      model.destroyRecord().then(() => {
        this.flashMessages.clearMessages().success('Subdomain removed');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "formErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "originalModel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteOffer", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteOffer"), _class.prototype)), _class));
  _exports.default = SitesSiteSettingsUserKindsUserKindPlansPlanIndexController;
});